import { useEffect, useState } from "react";

export const LOCALE = "en-CA";

export const STUDY_CASE_PROCID = "studyCase.procId";
export const STUDY_CASE_STATUS = "studyCase.status";
export const STUDY_CASE_ASSIGNEE = "studyCase.assignee";

export const STUDY_IMAGE_NAME = "studyImage.name";
export const STUDY_IMAGE_HAS_QUERIES = "studyImage.hasQueries";
export const STUDY_IMAGE_IS_UNASSIGNED = "studyImage.isUnassigned";

function getLocalStorageIntValue(key: string, defaultValue: number): number {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = saved ? parseInt(saved) : defaultValue;
  return initial || defaultValue;
}

export const useLocalIntStorage = (key: string, defaultValue: number) => {
  const [value, setValueStr] = useState(() => {
    return getLocalStorageIntValue(key, defaultValue).toString();
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, value.toString());
  }, [key, value]);

  function setValue(n: number) {
    setValueStr(n.toString());
  }

  return [parseInt(value), setValue];
};

function getLocalStorageValue(key: string, defaultValue: string) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : defaultValue;
  return initial || defaultValue;
}

export const useLocalStorage = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

function getSessionStorageValue(key: string, defaultValue: string) {
  // getting stored value
  const saved = sessionStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : defaultValue;
  return initial || defaultValue;
}

export const clearSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

export const useSessionStorage = (key: string, defaultValue: string) => {
  const [value, setValue] = useState(() => {
    return getSessionStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
