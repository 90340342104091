import { Box, Button, Callout, Checkbox, Intent, Label, Select, Text } from "@blasterjs/core";
import React, { useEffect } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Ok } from "ts.data.json";
import { caseStatusDecoder } from "../decoders";
import { CaseStatus, formatCaseStatus, StudyView, userLabel } from "../models";
import { RolePermissions } from "../permissions";

import CaseDialog from "../components/CaseDialog";
import CasesTable from "../components/CasesTable";
import DebouncedTextInput from "../components/DebouncedTextInput";
import Page, {
  PageActions,
  PageHeader,
  PageHeading,
  PageTabs,
  PageBanner,
  PageBannerHeading
} from "../components/Page";
import { TableContainer, TableFilters } from "../components/Table";
import Content from "../components/Content";
import ImagesTable from "../components/ImagesTable";
import UploadDialog from "../components/UploadDialog";
import { clearSessionStorage, useSessionStorage } from "../storage";
import {
  STUDY_CASE_ASSIGNEE,
  STUDY_CASE_PROCID,
  STUDY_CASE_STATUS,
  STUDY_IMAGE_HAS_QUERIES,
  STUDY_IMAGE_IS_UNASSIGNED,
  STUDY_IMAGE_NAME
} from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { studyFetchRequest } from "../slices/studyConfiguration";
import { studyFetch } from "../slices/studies";
import { clearFilters, setCaseFilters } from "../slices/cases";
import { setImageFiltersAndFetch } from "../slices/images";
import { openUploadDialog } from "../slices/uploadDialog";
import { openCreateCaseDialog } from "../slices/caseDialog";
import { openUploadSampleDataDialog } from "../slices/uploadSampleDataDialog";
import UploadSampleDataDialog from "../components/UploadSampleDataDialog";

enum Routes {
  Cases = "cases",
  Images = "images"
}

const Study = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const studyView = useAppSelector(state => state.studies.study);
  const images = useAppSelector(state => state.images);
  const cases = useAppSelector(state => state.cases);
  const userisReader: boolean =
    "resource" in loggedInUser && loggedInUser.resource && loggedInUser.resource.isCR();

  const params = useParams();
  const id: string = params.id || "no-id";
  const tab: string = params.tab || Routes.Cases;

  const [studyCaseProcId, setStudyCaseProcId] = useSessionStorage(STUDY_CASE_PROCID, "");
  const [studyCaseStatus, setStudyCaseStatus] = useSessionStorage(STUDY_CASE_STATUS, "");
  const [studyCaseAssignee, setStudyCaseAssignee] = useSessionStorage(STUDY_CASE_ASSIGNEE, "");

  const [studyImageName, setStudyImageName] = useSessionStorage(STUDY_IMAGE_NAME, "");
  const [studyImageHasQueries, setStudyImageHasQueries] = useSessionStorage(
    STUDY_IMAGE_HAS_QUERIES,
    ""
  );
  const [studyImageIsUnassigned, setStudyImageIsUnassigned] = useSessionStorage(
    STUDY_IMAGE_IS_UNASSIGNED,
    ""
  );

  useEffect(() => {
    dispatch(studyFetch(id));
    dispatch(studyFetchRequest(id));
    if (studyCaseProcId !== "" || studyCaseStatus !== "" || studyCaseAssignee !== "") {
      dispatch(
        setCaseFilters({
          studyId: id,
          caseFilters: {
            procId: studyCaseProcId === "" ? undefined : studyCaseProcId,
            status: studyCaseStatus === "" ? undefined : studyCaseStatus,
            assignee: studyCaseAssignee === "" ? undefined : studyCaseAssignee
          }
        })
      );
    }
    if (studyImageName !== "" || studyImageHasQueries !== "" || studyImageIsUnassigned !== "") {
      dispatch(
        setImageFiltersAndFetch({
          studyId: id,
          filters: {
            ...images.filters,
            name: studyImageName !== "" ? studyImageName : undefined,
            hasQueries: studyImageHasQueries !== "" ? studyImageHasQueries : undefined,
            isUnassigned: studyImageIsUnassigned !== "" ? studyImageIsUnassigned : undefined
          }
        })
      );
    }
    return function cleanup(): void {
      // Single action that both the images and cases reducers respond to,
      // clearing all filters when the component is unmounted.
      // It's tempting to store these filters in component state, but it needs
      // to be in Redux state so that the cases/images can be refreshed without
      // losing the current filters.
      dispatch(clearFilters());
    };
  }, [id]);

  const createCase = (studyView: StudyView) => () =>
    dispatch(openCreateCaseDialog(studyView.study.id));

  const createCaseButton =
    tab === Routes.Cases &&
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.S_CaseListTab_CreateCase]) &&
    "resource" in studyView ? (
      <Box>
        <Button
          iconBefore="plus"
          onClick={createCase(studyView.resource)}
          appearance="prominent"
          intent="primary"
        >
          Create case
        </Button>
        <CaseDialog />
      </Box>
    ) : null;

  const uploadImages = (studyView: StudyView) => () =>
    dispatch(openUploadDialog({ studyId: studyView.study.id }));

  const uploadSampleData = () => dispatch(openUploadSampleDataDialog());

  const uploadSampleDataButton = tab === Routes.Images &&
    "resource" in loggedInUser &&
    loggedInUser.resource.can([
      RolePermissions.IM_ImagesListTab_UploadImagestoAssignedStudiesOnly
    ]) &&
    "resource" in studyView && (
      <Box>
        <Button
          style={{ marginRight: "12px" }}
          iconBefore="upload"
          onClick={uploadSampleData}
          appearance="prominent"
          intent="primary"
        >
          Upload Sample Data
        </Button>
        <UploadSampleDataDialog />
        &nbsp;
      </Box>
    );

  const uploadImageButton =
    tab === Routes.Images &&
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.S_ImageListTab_UploadImage]) &&
    "resource" in studyView ? (
      <Box>
        <Button
          iconBefore="upload"
          onClick={uploadImages(studyView.resource)}
          appearance="prominent"
          intent="primary"
        >
          Upload image
        </Button>
        <UploadDialog />
      </Box>
    ) : null;

  const setImageSearchTerms = (searchTerms: string) => {
    if (searchTerms) {
      setStudyImageName(searchTerms);
    } else {
      clearSessionStorage(STUDY_IMAGE_NAME);
    }
    dispatch(
      setImageFiltersAndFetch({
        studyId: id,
        filters: {
          ...images.filters,
          name: searchTerms
        }
      })
    );
  };

  const setHasQueriesFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setStudyImageHasQueries(e.target.checked);
    } else {
      clearSessionStorage(STUDY_IMAGE_HAS_QUERIES);
    }
    dispatch(
      setImageFiltersAndFetch({
        studyId: id,
        filters: {
          ...images.filters,
          hasQueries: e.target.checked || undefined
        }
      })
    );
  };

  const setIsUnassignedFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setStudyImageIsUnassigned(e.target.checked);
    } else {
      clearSessionStorage(STUDY_IMAGE_IS_UNASSIGNED);
    }
    dispatch(
      setImageFiltersAndFetch({
        studyId: id,
        filters: {
          ...images.filters,
          isUnassigned: e.target.checked || undefined
        }
      })
    );
  };

  const setCasesSearchTerms = (searchTerms: string) => {
    if (searchTerms) {
      setStudyCaseProcId(searchTerms);
    } else {
      clearSessionStorage(STUDY_CASE_PROCID);
    }
    dispatch(
      setCaseFilters({
        studyId: id,
        caseFilters: {
          procId: searchTerms || undefined,
          status: cases.filters.status,
          assignee: cases.filters.assignee
        }
      })
    );
  };

  const onSelectStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseStatusResult = caseStatusDecoder.decode(e.target.value);
    if (caseStatusResult instanceof Ok) {
      setStudyCaseStatus(caseStatusResult.value);
    } else {
      clearSessionStorage(STUDY_CASE_STATUS);
    }
    dispatch(
      setCaseFilters({
        studyId: id,
        caseFilters: {
          procId: cases.filters.procId,
          status: caseStatusResult instanceof Ok ? caseStatusResult.value : undefined,
          assignee: cases.filters.assignee
        }
      })
    );
  };

  const onSelectAssignee = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setStudyCaseAssignee(e.target.value);
    } else {
      clearSessionStorage(STUDY_CASE_ASSIGNEE);
    }
    dispatch(
      setCaseFilters({
        studyId: id,
        caseFilters: {
          procId: cases.filters.procId,
          status: cases.filters.status,
          assignee: e.target.value || undefined
        }
      })
    );
  };

  const qcStatusSelect =
    "resource" in loggedInUser && !loggedInUser.resource.isCR() ? (
      <Select onChange={onSelectStatus} defaultValue={studyCaseStatus}>
        <option key="" value="">
          All statuses
        </option>
        {Object.values(CaseStatus)
          .filter(status => status !== CaseStatus.Invalid)
          .map(status => {
            return (
              <option key={status} value={status}>
                {formatCaseStatus(status)}
              </option>
            );
          })}
      </Select>
    ) : null;

  const assigneeSelect =
    "resource" in studyView && "readers" in studyView.resource ? (
      <Select onChange={onSelectAssignee} defaultValue={studyCaseAssignee}>
        <option key="" value="">
          Any assignee
        </option>
        {studyView.resource.readers.map(reader => {
          return (
            <option key={reader.id} value={reader.id}>
              Assigned to{" "}
              {userLabel({
                ...reader,
                workAsRole: null,
                actions: null,
                isSystemAdmin: null
              })}
            </option>
          );
        })}
        <option key="UNASSIGNED" value="UNASSIGNED">
          Unassigned
        </option>
      </Select>
    ) : null;

  const userCanSeeCases =
    "resource" in loggedInUser
      ? loggedInUser.resource.can([
          RolePermissions.S_CaseListTab_ViewallCasesUnderAssignedStudies,
          RolePermissions.S_CaseListTab_ViewAssignedCasesonly
        ])
      : false;

  const userCanSeeImages =
    "resource" in loggedInUser
      ? loggedInUser.resource.can([RolePermissions.S_ImageListTab_ViewImageTab])
      : false;

  const permissionDenied = !userCanSeeCases && !userCanSeeImages;

  const navigateTo =
    "resource" in loggedInUser && (userCanSeeCases || userCanSeeImages) ? (
      (tab === undefined && userCanSeeCases) || (tab === Routes.Images && !userCanSeeImages) ? (
        <Navigate to={`/studies/${id}/cases`} />
      ) : (tab === undefined || tab === Routes.Cases) && !userCanSeeCases ? (
        <Navigate to={`/studies/${id}/images`} />
      ) : tab !== Routes.Cases && tab !== Routes.Images ? (
        <Navigate to="/" />
      ) : null
    ) : permissionDenied ? (
      <Navigate to="/accessDenied" />
    ) : null;

  const table =
    "resource" in loggedInUser ? (
      tab === Routes.Cases && userCanSeeCases ? (
        <CasesTable studyId={id} loggedInUser={loggedInUser.resource} userIsReader={userisReader} />
      ) : (
        <ImagesTable studyId={id} loggedInUser={loggedInUser.resource} />
      )
    ) : null;

  return navigateTo ? (
    navigateTo
  ) : (
    <Page>
      <Content isLoading={"isPending" in studyView || "isPending" in loggedInUser || !!navigateTo}>
        {"resource" in studyView && "resource" in loggedInUser ? (
          <Box style={{ padding: "0 2rem 4rem" }}>
            {studyView.resource.study.onHold ? (
              <PageBanner>
                <PageBannerHeading>
                  On hold {studyView.resource.study.onHoldReason}
                </PageBannerHeading>
              </PageBanner>
            ) : null}
            <PageHeader>
              <PageHeading>{studyView.resource.study.name}</PageHeading>
              <PageActions>
                {"resource" in loggedInUser &&
                  loggedInUser.resource.can([RolePermissions.S_Main_EditStudy]) && (
                    <Link
                      to={`/studies/${id}/configure/settings`}
                      style={{ textDecoration: "none" }}
                    >
                      <Button iconBefore="edit">Edit Study</Button>
                    </Link>
                  )}
              </PageActions>
            </PageHeader>
            <PageTabs
              links={[
                ...(userCanSeeCases
                  ? [
                      {
                        to: `/studies/${id}/${Routes.Cases}`,
                        label: "Cases"
                      }
                    ]
                  : []),
                ...(userCanSeeImages
                  ? [
                      {
                        to: `/studies/${id}/${Routes.Images}`,
                        label: "Images"
                      }
                    ]
                  : [])
              ]}
            />
            <TableContainer>
              <TableFilters>
                <Box display="flex" width="100%">
                  {tab === Routes.Images ? (
                    <>
                      <DebouncedTextInput
                        key="images-search"
                        width="auto"
                        defaultValue={images.filters.name || ""}
                        placeholder={"Search by image file name"}
                        onValueChange={text => setImageSearchTerms(text.trim())}
                      />
                      {userCanSeeCases ? (
                        <Box paddingLeft="10px">
                          <Label>
                            <Checkbox
                              checked={images.filters.isUnassigned || false}
                              onChange={setIsUnassignedFilter}
                              ml={1}
                              mr={1}
                            />
                            <Text
                              color="gray600"
                              style={{ top: "2px", position: "relative", fontWeight: 400 }}
                            >
                              Only unassigned
                            </Text>
                          </Label>
                        </Box>
                      ) : null}
                      <Box paddingLeft="10px">
                        <Label>
                          <Checkbox
                            checked={images.filters.hasQueries || false}
                            onChange={setHasQueriesFilter}
                            ml={1}
                            mr={1}
                          />
                          <Text
                            color="gray600"
                            style={{ top: "2px", position: "relative", fontWeight: 400 }}
                          >
                            Only images with queries
                          </Text>
                        </Label>
                      </Box>
                      <Box ml="auto">{uploadSampleDataButton}</Box>
                      <Box>{uploadImageButton}</Box>
                    </>
                  ) : (
                    <>
                      <DebouncedTextInput
                        key="cases-search"
                        width="auto"
                        defaultValue={cases.filters.procId || studyCaseProcId || ""}
                        placeholder={"Search by Procedure ID"}
                        onValueChange={text => setCasesSearchTerms(text.trim())}
                      />
                      <Box paddingLeft="10px">{qcStatusSelect}</Box>
                      <Box paddingLeft="10px">{assigneeSelect}</Box>
                      <Box ml="auto">{createCaseButton}</Box>
                    </>
                  )}
                </Box>
              </TableFilters>
              {table}
            </TableContainer>
          </Box>
        ) : (
          <Box>
            <Callout intent={Intent.WARNING}>Study not found</Callout>
          </Box>
        )}
      </Content>
    </Page>
  );
};

export default Study;
