import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import TooltipText from "../components/TooltipText";
import Content from "../components/Content";
import {
  AdminStudyListView,
  Indication,
  ReaderStudyListView,
  StudyListView,
  StudyListViews,
  UploaderStudyListView
} from "../models";

import DebouncedTextInput from "../components/DebouncedTextInput";
import IndicationText from "../components/IndicationText";
import Modalities from "../components/Modalities";
import Page, { PageHeader, PageHeading } from "../components/Page";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import Timestamp from "../components/Timestamp";
import { dataTableTheme } from "../theme";
import { clearSessionStorage, STUDY_IMAGE_NAME, useLocalIntStorage } from "../storage";
import {
  STUDY_CASE_ASSIGNEE,
  STUDY_CASE_PROCID,
  STUDY_CASE_STATUS,
  STUDY_IMAGE_HAS_QUERIES,
  STUDY_IMAGE_IS_UNASSIGNED
} from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setStudiesFilter, studiesFetch } from "../slices/studies";
import { RolePermissions, userIsAdminish } from "../permissions";

function sortMostRecentUpload(rowA: AdminStudyListView, rowB: AdminStudyListView): number {
  if (rowA.mostRecentUploadAt === rowB.mostRecentUploadAt) {
    return 0;
  }
  if (rowA.mostRecentUploadAt == null) {
    return 1;
  }
  if (rowB.mostRecentUploadAt == null) {
    return -1;
  }
  return rowB.mostRecentUploadAt.getTime() - rowA.mostRecentUploadAt.getTime();
}

const defineColumns = (isAdminIsc: boolean, isReader: boolean): ReadonlyArray<object> => {
  const nameCol = {
    name: "Name",
    selector: (row: StudyListView) => row.studyView.study.name,
    sortable: true,
    grow: 2
  };
  const statusCol = {
    name: "Status",
    selector: (row: StudyListView) => (row.studyView.study.onHold ? "On hold" : "Active"),
    sortable: true,
    grow: 1,
    cell: (row: StudyListView) =>
      row.studyView.study.onHold ? (
        <TooltipText
          text={row.studyView.study.onHoldReason ? row.studyView.study.onHoldReason : ""}
        >
          On Hold
        </TooltipText>
      ) : (
        <TooltipText text="Active">Active</TooltipText>
      )
  };
  const dateColForAdminIsc = {
    name: "Most Recent Upload",
    selector: (row: AdminStudyListView) => row.mostRecentUploadAt,
    sortable: true,
    sortFunction: sortMostRecentUpload,
    grow: 1,
    format: (s: AdminStudyListView) => <Timestamp date={s.mostRecentUploadAt} />
  };
  const dateColForNonAdminIsc = {
    name: "Created",
    selector: (row: StudyListView) => row.studyView.study.createdAt,
    sortable: true,
    grow: 1,
    format: (s: StudyListView) => <Timestamp date={s.studyView.study.createdAt} />
  };

  const commonCols = [
    {
      name: "Indications",
      selector: (row: StudyListView) => row.studyView.study.indications,
      sortable: true,
      grow: 2,
      format: (s: StudyListView) => (
        <IndicationText indication={s.studyView.study.indications[0] || Indication.CeliacDisease} />
      )
    },
    {
      name: "Locations",
      selector: (row: StudyListView) => row.studyView.study.segments,
      sortable: true,
      grow: 0
    },
    {
      name: "Modality",
      selector: (row: StudyListView) => row.studyView.study.modality,
      grow: 2,
      sortable: true,
      format: (s: StudyListView) => <Modalities modalities={s.studyView.study.modality} />
    }
  ];

  const adminIscQcCol = [
    {
      name: "Unassigned Images",
      selector: (row: AdminStudyListView) => row.numberOfUnassignedImages,
      sortable: true
    },
    {
      name: "Cases to QC",
      selector: (row: AdminStudyListView) => row.numberOfCasesNeedingQC,
      sortable: true
    },
    {
      name: "Open Queries",
      selector: (row: AdminStudyListView) => row.numberOfQueriesOpen,
      sortable: true
    },
    {
      name: "Answered Queries",
      selector: (row: AdminStudyListView) => row.numberOfQueriesAnswered,
      sortable: true
    }
  ];

  const readerQcCol = [
    {
      name: "Read Cases",
      selector: (row: ReaderStudyListView) => row.numberOfAssignedReadCases,
      sortable: true
    },
    {
      name: "Unread Cases",
      selector: (row: ReaderStudyListView) => row.numberOfAssignedUnreadCases,
      sortable: true
    }
  ];

  const baseQcCol = [
    {
      name: "Open Queries",
      selector: (row: UploaderStudyListView) => row.numberOfQueriesOpen,
      sortable: true
    }
  ];

  return [
    nameCol,
    statusCol,
    isAdminIsc ? dateColForAdminIsc : dateColForNonAdminIsc,
    commonCols,
    ...(isAdminIsc ? adminIscQcCol : isReader ? readerQcCol : baseQcCol)
  ];
};

const Studies = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const studies = useAppSelector(state => state.studies.studies);
  const studiesFilter = useAppSelector(state => state.studies.studiesFilter);
  const user = useAppSelector(state => state.auth.user);

  const navigate = useNavigate();
  const [isUserAdminIsc, isUserReader] = [
    userIsAdminish(("resource" in loggedInUser && loggedInUser.resource) || null),
    "resource" in loggedInUser && loggedInUser.resource && loggedInUser.resource.isCR()
  ];

  useEffect(() => {
    dispatch(studiesFetch());
  }, [studiesFilter]);

  const [studyRowsPerPage, setStudyRowsPerPage] = useLocalIntStorage("studyRowsPerPage", 20);

  const data: StudyListViews =
    "resource" in studies
      ? "resource" in loggedInUser &&
        loggedInUser.resource.can([RolePermissions.S_Main_ViewAllStudies])
        ? // eslint-disable-next-line functional/immutable-data
          ([...studies.resource] as AdminStudyListView[]).sort(sortMostRecentUpload)
        : studies.resource
      : [];

  const sortProps = { defaultSortField: "studyView.study.createdAt", defaultSortAsc: false };

  const onRowClick = (s: StudyListView) => {
    // clear session variables when transitioning to study for the first time
    clearSessionStorage(STUDY_CASE_PROCID);
    clearSessionStorage(STUDY_CASE_STATUS);
    clearSessionStorage(STUDY_CASE_ASSIGNEE);

    clearSessionStorage(STUDY_IMAGE_NAME);
    clearSessionStorage(STUDY_IMAGE_HAS_QUERIES);
    clearSessionStorage(STUDY_IMAGE_IS_UNASSIGNED);

    navigate(`/studies/${s.studyView.study.id}/cases`);
  };

  const createStudyButton =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.S_Main_CreateStudy]) ? (
      <Link to={`/studies/new/configure/`} style={{ textDecoration: "none" }}>
        <Button iconBefore="plus" appearance="prominent" intent="primary">
          Create Study
        </Button>
      </Link>
    ) : null;

  const content =
    "errorMessage" in studies ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{studies.errorMessage}</Text>
        </Callout>
      </Box>
    ) : (
      <TableContainer>
        <TableFilters>
          <Box display="flex" width="100%">
            <DebouncedTextInput
              key="studies-search"
              width="auto"
              defaultValue={studiesFilter || ""}
              placeholder={"Search by name"}
              onValueChange={name => dispatch(setStudiesFilter(name.trim()))}
            />
            <Box ml="auto">{createStudyButton}</Box>
          </Box>
        </TableFilters>
        {"resource" in studies ? (
          <StyledDataTable
            columns={defineColumns(isUserAdminIsc, isUserReader)}
            data={data}
            highlightOnHover={true}
            pointerOnHover={true}
            onRowClicked={onRowClick}
            sortIcon={<Icon name="caretUp" />}
            className="data-table"
            noHeader={true}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            paginationPerPage={studyRowsPerPage}
            onChangeRowsPerPage={setStudyRowsPerPage}
            customTheme={dataTableTheme}
            {...sortProps}
          />
        ) : null}
      </TableContainer>
    );

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Studies</PageHeading>
        </PageHeader>
        <Content isLoading={"isPending" in user}>{content}</Content>
      </Box>
    </Page>
  );
};

export default Studies;
