import React from "react";
import { useNavigate } from "react-router-dom";

import { Appearance, Button, Icon } from "@blasterjs/core";
import { Image } from "../models";
import TopBar from "./TopBar";

interface Props {
  readonly image: Image | null;
}

const TopBarImage = ({ image }: Props) => {
  const navigate = useNavigate();
  const close = (image: Image) => () => {
    if (image.studyId) navigate(`/studies/${image.studyId}/images`);
    else navigate(`/image-management/images`);
  };
  const imageName = image === null ? null : image.name;
  const closeButton =
    image === null ? null : (
      <Button color="#fff" appearance={Appearance.MINIMAL} onClick={close(image)}>
        <Icon name="close" />
      </Button>
    );
  return (
    <TopBar>
      {closeButton}
      {imageName}
    </TopBar>
  );
};

export default TopBarImage;
