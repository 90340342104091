import React from "react";
import { Callout, Intent } from "@blasterjs/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import ImageViewer from "../components/ImageViewer";
import InfoSidebarImage from "../components/InfoSidebarImage";
import TopBarImage from "../components/TopBarImage";
import Content from "../components/Content";
import { useAppDispatch, useAppSelector } from "../hooks";
import { imageFetch } from "../slices/caseImageViewer";
import { RolePermissions } from "../permissions";

const Image = () => {
  const params = useParams();
  const imageId = params.id || "no-id";

  const dispatch = useAppDispatch();

  const imageWithAnnotations = useAppSelector(state => state.caseImageViewer.imageWithAnnotations);
  const config = useAppSelector(state => state.config);
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  useEffect(() => {
    dispatch(imageFetch(imageId));
  }, [imageId]);

  const errorText =
    "errorMessage" in config.config
      ? config.config.errorMessage
      : imageWithAnnotations && "errorMessage" in imageWithAnnotations
      ? imageWithAnnotations.errorMessage
      : null;
  const content =
    "resource" in config.config &&
    (imageWithAnnotations === null || "resource" in imageWithAnnotations) &&
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewimageViewer]) ? (
      <ImageViewer
        imageWithAnnotations={imageWithAnnotations === null ? null : imageWithAnnotations.resource}
        tileServerLocation={config.config.resource.tileServerLocation}
        topBar={
          <TopBarImage
            image={
              imageWithAnnotations === null
                ? null
                : imageWithAnnotations.resource.imageAndQuery.image
            }
          />
        }
        sidebar={
          imageWithAnnotations === null ? null : (
            <InfoSidebarImage
              imageWithAnnotations={imageWithAnnotations.resource}
              loggedInUser={loggedInUser.resource}
              dispatch={dispatch}
            />
          )
        }
      />
    ) : errorText ? (
      <Callout intent={Intent.DANGER}>{errorText}</Callout>
    ) : null;
  return (
    <Content
      isLoading={
        imageWithAnnotations === null ||
        "isPending" in imageWithAnnotations ||
        "isPending" in config
      }
    >
      {content}
    </Content>
  );
};

export default Image;
