import React from "react";

import { Box, Button, Callout, Dialog, FileInput, Intent, Text } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  changeFiles,
  closeUploadSampleDataDialog,
  signedSampleDataURLsCreate,
  uploadSampleDataProcess
} from "../slices/uploadSampleDataDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

library.add(faCheck);
library.add(faExclamationTriangle);

const UploadSampleDataDialog = () => {
  const dispatch = useAppDispatch();
  const uploadDialog = useAppSelector(state => state.uploadSampleDataDialog);

  const closeDialog = () => {
    dispatch(closeUploadSampleDataDialog());
  };
  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFiles(e.target.files));
  };
  const uploadFiles = () => {
    dispatch(signedSampleDataURLsCreate()).then(() => dispatch(uploadSampleDataProcess()));
  };
  const errorText =
    "errorMessage" in uploadDialog.sampleDataSignedURL ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{uploadDialog.sampleDataSignedURL.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const resultsFileUrl =
    "resource" in uploadDialog.importResults
      ? uploadDialog.importResults.resource.s3SampleDataResponse
      : null;

  const numUpdated =
    "resource" in uploadDialog.importResults
      ? uploadDialog.importResults.resource.numRowsUpdated
      : null;
  const numFieldsUpdated =
    "resource" in uploadDialog.importResults
      ? uploadDialog.importResults.resource.numFieldsUpdated
      : null;
  const numRowsFailed =
    "resource" in uploadDialog.importResults
      ? uploadDialog.importResults.resource.numRowsFailed
      : null;

  const isUploadInitiated =
    "isPending" in uploadDialog.sampleDataSignedURL && uploadDialog.sampleDataSignedURL.isPending;
  const isUploadDone = uploadDialog.uploadComplete;
  const otherFileInputArgs = {};

  return isUploadDone ? (
    <Dialog isOpen={uploadDialog.isOpen} appElementId="root">
      <DialogHeader title={"Sample Data Import Complete"} />
      <DialogBody>
        <Box>
          <p>
            See export summary below.{" "}
            <a
              download={"sample-data-results.csv"}
              target={"_blank"}
              rel={"noreferrer"}
              href={resultsFileUrl || ""}
            >
              Click to download import result details.
            </a>
          </p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faCheck} /> {numUpdated} Samples updated, {numFieldsUpdated}{" "}
              fields imported
            </li>
            <li>
              <FontAwesomeIcon icon={faExclamationTriangle} /> {numRowsFailed} Rows with errors
            </li>
          </ul>
        </Box>
      </DialogBody>
      <DialogFooter>
        <Button onClick={closeDialog} appearance="prominent" intent="primary">
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  ) : isUploadInitiated && uploadDialog.currentUpload !== null ? (
    <Dialog isOpen={uploadDialog.isOpen} appElementId="root">
      <DialogHeader title={"Uploading Sample Data..."} />
      <DialogBody>
        {Object.values(uploadDialog.currentUpload.uploadProgress).map((upload, idx) => {
          return (
            <Box key={idx}>
              <h3>{upload.file.name}</h3>
            </Box>
          );
        })}
      </DialogBody>
      <DialogFooter>
        <p></p>
      </DialogFooter>
    </Dialog>
  ) : (
    <Dialog isOpen={uploadDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader title={"Select Sample Data File"} closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box>
          <FileInput onChange={onChangeFiles} accept=".csv,.txt" {...otherFileInputArgs} />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            disabled={uploadDialog.acceptButtonDisabled}
            onClick={uploadFiles}
            isLoading={isUploadInitiated}
            appearance="prominent"
            intent="primary"
          >
            Upload
          </Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default UploadSampleDataDialog;
