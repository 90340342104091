import { LatLngExpression } from "leaflet";
import { Polygon, Popup } from "react-leaflet";
import React from "react";
import { Text as BlasterText } from "@blasterjs/core";
import { UUID } from "../models";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";

interface Props {
  readonly positions: ReadonlyArray<ReadonlyArray<LatLngExpression>>;
  readonly date: string;
  readonly annotationId: UUID;
  readonly color: string | null;
  readonly canDelete: boolean;
  readonly isClickable: boolean;
  dispatch: AppDispatch;
}

export const defaultColor = "#FFFF00";

const FreehandAnnotation = ({
  positions,
  date,
  annotationId,
  color,
  canDelete,
  dispatch
}: Props) => {
  const colorWithFallback = color || defaultColor;
  return (
    <Polygon
      positions={positions as LatLngExpression[][]}
      color={colorWithFallback}
      fillColor={colorWithFallback}
      fillOpacity={0.1}
    >
      <Popup closeButton={false} className="annotation-popup">
        <AnnotationTooltip annotationId={annotationId} canDelete={canDelete} dispatch={dispatch}>
          <div style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem", lineHeight: "1" }}>
            <BlasterText fontSize="8pt">{date}</BlasterText>
          </div>
        </AnnotationTooltip>
      </Popup>
    </Polygon>
  );
};

export default FreehandAnnotation;
