import { Button } from "@blasterjs/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface NavStyledProps {
  readonly type?: "tabs";
}

const NavStyled = styled.div<NavStyledProps>`
  display: flex;
  height: ${props => (props.type === "tabs" ? "4.5rem" : "5.6rem")};
  border-bottom: 0 solid ${props => props.theme.colors.gray300};
  border-bottom-width: ${props => (props.type === "tabs" ? "1px" : 0)};
  margin-bottom: ${props => (props.type === "tabs" ? "-2px" : 0)};
  margin-top: ${props => (props.type === "tabs" ? "2rem" : 0)};
  width: ${props => (props.type === "tabs" ? "114rem" : "auto")};
  max-width: 100%;
`;

const NavContainer = styled.div<NavStyledProps>`
  display: flex;
`;

const ButtonNav = styled(Button)`
  border: none;
  background: ${props => (props.active ? "#fff" : "transparent")};
  color: ${props => (props.active ? props.theme.colors.textBase : props.theme.colors.textLight)};
  padding: 0 1rem;
  border-radius: 0;
  box-shadow: none;
  margin-right: 4px;
  border: 1px solid #dde0e6;
  border-width: ${props => (props.active && props.type === "tabs" ? "0 1px" : 0)};
  &:hover {
    background-color: ${props => (props.logo ? "transparent" : "rgba(179, 189, 204, 0.15)")};
  }
  &:after {
    position: absolute;
    top: ${props => (props.active && props.type === "tabs" ? 0 : "auto")};
    bottom: ${props => (props.active && props.type === "tabs" ? "auto" : 0)};
    left: 0;
    border-radius: 20px;
    right: 0;
    height: 2px;
    content: "";
    background-color: ${props => (props.active ? props.theme.colors.brand.color : "transparent")};
  }
`;

interface NavLinkProps {
  readonly to: string;
  readonly children: React.ReactNode;
  readonly path: string;
  readonly active?: boolean;
  readonly logo?: boolean;
}

export const NavLink = ({
  to,
  children,
  path,
  active,
  type,
  logo
}: NavLinkProps & NavStyledProps) => (
  <Link to={to} style={{ textDecoration: "none", display: "flex" }}>
    <ButtonNav
      logo={logo}
      type={type}
      active={typeof active === "boolean" ? active : path.includes(to)}
    >
      {children}
    </ButtonNav>
  </Link>
);

interface NavProps extends NavStyledProps {
  readonly children: React.ReactNode;
}

const Nav = ({ type, children }: NavProps) => (
  <NavStyled type={type}>
    <NavContainer>{children}</NavContainer>
  </NavStyled>
);

export { Nav, ButtonNav };
