import React from "react";

interface Props {
  readonly text: string;
  readonly children: React.ReactNode;
  readonly style?: object;
}

const TooltipText = ({ text, children, style }: Props) => (
  <span style={{ ...style, cursor: "default" }} title={text}>
    {children}
  </span>
);

export default TooltipText;
