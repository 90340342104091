import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SignedURLs, StudyId, Image, UUID } from "../models";
import { WriteResource } from "../types";
import { CancelTokenSource } from "axios";
import {
  createSignedURLs,
  generateCancelTokenSource,
  sendNewFilesNotification,
  uploadFiles
} from "../api";
import { AppDispatch, RootState } from "../store";
import { castDraft } from "immer";
import { refreshImage } from "./caseImageViewer";
import { imagesFetch } from "./images";

interface FileUploadProgress {
  readonly file: File;
  readonly progress: number;
}

interface AllFileUploadProgress {
  // NOTE: key is the filename
  readonly [key: string]: FileUploadProgress;
}

interface InProgressUpload {
  readonly uploadProgress: AllFileUploadProgress;
  readonly cancelTokenSource: CancelTokenSource;
}

export interface UploadDialogState {
  readonly isOpen: boolean;
  readonly acceptButtonDisabled: boolean;
  readonly fileSignedURLs: WriteResource<FileList | null, SignedURLs>;
  readonly studyId: StudyId | null;
  readonly imageToReplace: Image | null;
  readonly refreshImageOnSuccessfulReplace: boolean;
  readonly refreshCaseOnSuccessfulReplace: boolean;
  readonly currentUpload: InProgressUpload | null;
  readonly errorMessage: string | null;
}

export const initialState: UploadDialogState = {
  isOpen: false,
  acceptButtonDisabled: false,
  fileSignedURLs: {
    data: null,
    resource: []
  },
  studyId: null,
  imageToReplace: null,
  refreshImageOnSuccessfulReplace: false,
  refreshCaseOnSuccessfulReplace: false,
  currentUpload: null,
  errorMessage: null
};

export interface OpenUploadDialogParams {
  readonly studyId: UUID;
  readonly imageToReplace?: Image;
  readonly refreshImageOnSuccessfulReplace?: boolean;
  readonly refreshCaseOnSuccessfulReplace?: boolean;
}

export interface UploadImageProgressParams {
  readonly file: File;
  readonly progress: number;
}

export const uploadImagesProgressDispatchFile: (
  dispatch: AppDispatch,
  file: File
) => (progressEvent: ProgressEvent) => void = (dispatch: AppDispatch, file: File) => {
  return (progressEvent: ProgressEvent) =>
    dispatchUploadImagesProgress(dispatch, file, progressEvent);
};

export const dispatchUploadImagesProgress = (
  dispatch: AppDispatch,
  file: File,
  progressEvent: ProgressEvent
) => {
  dispatch(uploadImagesProgress({ file: file, progress: progressEvent.loaded }));
};

// thunks
export const signedURLsCreate = createAsyncThunk(
  "uploadDialog/signedURLsCreate",
  async (_: void, thunkApi) => {
    const { dispatch } = thunkApi;

    const signedURLsCreateInnerResponse = await dispatch(signedURLsCreateInner());
    if (signedURLsCreateInnerResponse) {
      await dispatch(uploadImages(generateCancelTokenSource()));
    }
  }
);
export const signedURLsCreateInner = createAsyncThunk(
  "uploadDialog/signedURLsCreate",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (
      "data" in state.uploadDialog.fileSignedURLs &&
      state.uploadDialog.fileSignedURLs.data !== null &&
      state.uploadDialog.studyId !== null
    ) {
      const createSignedUrlsResponse = await createSignedURLs(
        state.uploadDialog.studyId,
        state.uploadDialog.fileSignedURLs.data
      );

      return createSignedUrlsResponse;
    }
  }
);

export const uploadImages = createAsyncThunk(
  "uploadDialog/uploadImages",
  async (cancelTokenSource: CancelTokenSource, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.uploadDialog;

    if (
      "data" in state.fileSignedURLs &&
      state.fileSignedURLs.data !== null &&
      "resource" in state.fileSignedURLs &&
      state.studyId !== null
    ) {
      dispatch(uploadImagesPending(cancelTokenSource));

      const uploadFilesResponse = await uploadFiles(
        state.studyId,
        state.fileSignedURLs.data,
        state.fileSignedURLs.resource,
        uploadImagesProgressDispatchFile,
        state.imageToReplace,
        cancelTokenSource
      );

      await dispatch(uploadImagesNotify());

      if (
        state.studyId &&
        state.fileSignedURLs.data &&
        !("errorMessage" in state.fileSignedURLs.resource)
      ) {
        if (state.imageToReplace && state.refreshImageOnSuccessfulReplace) {
          await dispatch(
            refreshImage({
              imageId: state.imageToReplace.id,
              refreshCaseOnSuccess: state.refreshCaseOnSuccessfulReplace
            })
          );
        } else {
          await dispatch(imagesFetch(state.studyId));
        }
      }

      return uploadFilesResponse;
    }
  }
);

export const uploadImagesNotify = createAsyncThunk(
  "uploadDialog/uploadImages",
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.uploadDialog;
    if (state.studyId && state.fileSignedURLs.data) {
      await sendNewFilesNotification(
        state.studyId,
        state.fileSignedURLs.data.length,
        "errorMessage" in state.fileSignedURLs
      );

      if (state.imageToReplace && state.refreshImageOnSuccessfulReplace) {
        await dispatch(
          refreshImage({
            imageId: state.imageToReplace.id,
            refreshCaseOnSuccess: state.refreshCaseOnSuccessfulReplace
          })
        );
      } else {
        await dispatch(imagesFetch(state.studyId));
      }
    }
  }
);

export const uploadDialogSlice = createSlice({
  name: "uploadDialog",
  initialState: initialState,
  reducers: {
    openUploadDialog: (state, action: PayloadAction<OpenUploadDialogParams>) => {
      state.isOpen = true;
      state.errorMessage = null;
      state.studyId = action.payload.studyId;
      state.imageToReplace = action.payload.imageToReplace || null;
      state.refreshImageOnSuccessfulReplace =
        action.payload.refreshImageOnSuccessfulReplace || false;
      state.refreshCaseOnSuccessfulReplace = action.payload.refreshCaseOnSuccessfulReplace || false;
    },
    closeUploadDialog: state => {
      state.isOpen = false;
    },
    changeFiles: (state, action: PayloadAction<FileList | null>) => {
      state.fileSignedURLs.data = action.payload;
    },
    uploadImagesProgress: (state, action: PayloadAction<UploadImageProgressParams>) => {
      if (state.currentUpload !== null) {
        state.currentUpload = {
          uploadProgress: {
            ...state.currentUpload.uploadProgress,
            [action.payload.file.name]: {
              file: action.payload.file,
              progress: action.payload.progress
            }
          },
          cancelTokenSource: state.currentUpload.cancelTokenSource
        };
      }
    },
    // eslint-disable-next-line
    uploadImagesPending: (state, action) => {
      if (
        "data" in state.fileSignedURLs &&
        state.fileSignedURLs.data !== null &&
        "resource" in state.fileSignedURLs &&
        state.studyId !== null
      ) {
        state.fileSignedURLs = {
          data: state.fileSignedURLs.data,
          resource: state.fileSignedURLs.resource,
          isPending: true
        };
        state.currentUpload = {
          uploadProgress: Array.from(state.fileSignedURLs.data as FileList).reduce(
            (acc: AllFileUploadProgress, file: File) => ({
              ...acc,
              [file.name]: {
                file,
                progress: 0
              }
            }),
            {}
          ),
          cancelTokenSource: action.payload.cancelTokenSource
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(signedURLsCreateInner.pending, state => {
      state.fileSignedURLs = {
        data: state.fileSignedURLs.data,
        isPending: true
      };
    });
    builder.addCase(signedURLsCreateInner.fulfilled, (state, action) => {
      if (action.payload && action.payload !== undefined) {
        state.fileSignedURLs = {
          data: state.fileSignedURLs.data,
          resource: castDraft(action.payload)
        };
      }
    });
    builder.addCase(signedURLsCreateInner.rejected, (state, action) => {
      state.fileSignedURLs = {
        data: state.fileSignedURLs.data,
        errorMessage: action.error.message
      };
      if (action.error.message) {
        state.errorMessage = action.error.message.toString();
      }
    });

    // uploadImagesNotify
    // eslint-disable-next-line
    builder.addCase(uploadImagesNotify.fulfilled, (state, action) => {
      if (state.studyId) {
        state.isOpen = "errorMessage" in state.fileSignedURLs;
        state.currentUpload = null;
        state.fileSignedURLs = { data: null };
      }
    });
    // eslint-disable-next-line
    builder.addCase(uploadImagesNotify.rejected, (state, action) => {
      if (state.studyId) {
        state.fileSignedURLs = {
          data: state.fileSignedURLs.data,
          errorMessage:
            "errorMessage" in state.fileSignedURLs
              ? state.fileSignedURLs.errorMessage
              : "Warning: failed to send email notification of upload(s)"
        };
      }
    });
  }
});

export const {
  openUploadDialog,
  closeUploadDialog,
  changeFiles,
  uploadImagesProgress,
  uploadImagesPending
} = uploadDialogSlice.actions;

export default uploadDialogSlice.reducer;
