import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CaseSummary,
  CaseSummaries,
  Image,
  StudyId,
  UUID,
  CaseStatus,
  CaseWithImages
} from "../models";
import { Resource, WriteResource } from "../types";
import { RootState } from "../store";
import { fetchCase, fetchCaseSummaries, moveImageToCaseApi, moveImageToStudyApi } from "../api";
import { castDraft } from "immer";

export interface MoveImageForm {
  readonly studyId: StudyId | null;
  readonly reasonForChange: string | null;
}

export type MoveImageDialogState =
  | {
      readonly isOpen: true;
      readonly moveType: string;
      readonly savedImage: Image;
      readonly imagesCase: Resource<CaseWithImages>;
      readonly image: WriteResource<MoveImageForm, Image>;
      readonly casesSearchText: string;
      readonly casesSearchResults: Resource<CaseSummaries>;
      readonly selectedCases: Array<CaseSummary>;
    }
  | {
      readonly isOpen: false;
    };

export const initialState: MoveImageDialogState = {
  isOpen: false
};

export const moveImage = createAsyncThunk(
  "moveImageDialog/moveImage",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (state.moveImageDialog.isOpen && state.moveImageDialog.image.data.studyId) {
      if (state.moveImageDialog.moveType == "study") {
        if (state.moveImageDialog.image.data.studyId) {
          const response = await moveImageToStudyApi(state.moveImageDialog.savedImage.id, {
            value: state.moveImageDialog.image.data.studyId,
            reasonForChange: state.moveImageDialog.image.data.reasonForChange || undefined
          });
          return response;
        }
      } else {
        const caseId = state.moveImageDialog.selectedCases[0]?.caseId;
        if (caseId) {
          return await moveImageToCaseApi(state.moveImageDialog.savedImage.id, {
            value: caseId,
            reasonForChange: state.moveImageDialog.image.data.reasonForChange || undefined
          });
        }
      }
    }
  }
);

export interface SearchCasesRequestParams {
  studyId: UUID;
  searchText: string;
}
export const searchCasesRequest = createAsyncThunk(
  "moveImageDialog/searchCasesRequest",
  async (params: SearchCasesRequestParams) => {
    const response = await fetchCaseSummaries(params.studyId, params.searchText);
    return response;
  }
);

export const loadCaseRequest = createAsyncThunk(
  "moveImageDialog/loadCaseRequest",
  async (caseId: UUID) => {
    return await fetchCase(caseId);
  }
);

export const moveImageDialogSlice = createSlice({
  name: "moveImageDialog",
  initialState: { isOpen: false } as MoveImageDialogState,
  reducers: {
    openMoveImageDialog: (state, action: PayloadAction<Image>) => {
      return {
        isOpen: true,
        moveType: "study",
        savedImage: action.payload,
        image: {
          data: {
            studyId: null,
            reasonForChange: null
          }
        },
        imagesCase: {
          isPending: false
        },
        casesSearchText: "",
        casesSearchResults: {
          isPending: false
        },
        selectedCases: []
      };
    },
    closeMoveImageDialog: state => {
      state.isOpen = false;
    },
    addSelectedCase: (state, action: PayloadAction<CaseSummary>) => {
      if (state.isOpen) {
        state.selectedCases = castDraft([action.payload]);
      }
    },
    clearSelectedCase: state => {
      if (state.isOpen) {
        state.selectedCases = [];
      }
    },
    setMoveImageForm: (state, action: PayloadAction<MoveImageForm>) => {
      if (state.isOpen) {
        state.image.data = action.payload;
      }
    },
    setMoveType: (state, action: PayloadAction<string>) => {
      if (state.isOpen) {
        state.moveType = action.payload;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(searchCasesRequest.pending, (state, action) => {
      if (state.isOpen) {
        state.casesSearchText = action.meta.arg.searchText;
        state.casesSearchResults = { isPending: true };
      }
    });
    builder.addCase(searchCasesRequest.fulfilled, (state, action) => {
      if (state.isOpen) {
        const cases = action.payload.filter(
          c => c.caseStatus !== CaseStatus.Completed && c.caseStatus !== CaseStatus.Processed
        );
        state.casesSearchResults = castDraft({ resource: cases });
      }
    });
    builder.addCase(searchCasesRequest.rejected, (state, action) => {
      if (state.isOpen) {
        state.casesSearchResults = { errorMessage: action.error.message || "" };
      }
    });
    builder.addCase(loadCaseRequest.fulfilled, (state, action) => {
      if (state.isOpen) {
        state.imagesCase = castDraft({ resource: action.payload });
      }
    });
    builder.addCase(moveImage.fulfilled, state => {
      state.isOpen = false;
    });
    builder.addCase(moveImage.rejected, (state, action) => {
      if (state.isOpen) {
        state.image = {
          data: state.image.data,
          errorMessage: action.error.message
        };
      }
    });
  }
});

export const {
  openMoveImageDialog,
  closeMoveImageDialog,
  addSelectedCase,
  clearSelectedCase,
  setMoveImageForm,
  setMoveType
} = moveImageDialogSlice.actions;
export default moveImageDialogSlice.reducer;
