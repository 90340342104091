import React, { useState } from "react";
import { Button as BlasterButton, Icon } from "@blasterjs/core";
import styled from "styled-components";
import { UUID } from "../models";
import {
  deleteAnnotation,
  setSelectedMultilineCategory,
  setSelectedMultilineClass
} from "../slices/caseImageViewer";
import { AppDispatch } from "../store";
import ConfirmationDialog from "./ConfirmationDialog";

const AnnotationTooltipStyled = styled.div`
  margin-top: auto;
`;

const AnnotationButton = styled(BlasterButton)`
  border-radius: 0;
  border-bottom: 0;
  height: 100%;
  width: ${props => props.width};
  padding-top: 0;
  padding-bottom: 0;
  &.active {
    color: lightblue;
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 4px 4px rgba(16, 22, 26, 0.2);
    background-image: none;
    background-color: ${props => props.theme.colors.gray200};
    z-index: 1;
  }
  font-size: ${props => props.theme.fontSizes[1]};
`;

export const processDeleteAnnotation = (annotationId: UUID, dispatch: AppDispatch) => () => {
  dispatch(deleteAnnotation({ annotationId: annotationId, deleteNested: false }));
  dispatch(setSelectedMultilineClass(null));
  dispatch(setSelectedMultilineCategory(null));
};

const AnnotationTooltip = ({
  children,
  annotationId,
  canDelete,
  dispatch
}: {
  readonly children: JSX.Element | ReadonlyArray<JSX.Element>;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  dispatch: AppDispatch;
}) => {
  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

  const deleteClick = () => {
    setDeleteConfirmationDialogOpen(true);
  };

  const onCancelConfirmationDialog = () => {
    setDeleteConfirmationDialogOpen(false);
  };

  const buttons = Array.isArray(children)
    ? children.map((child, index) => {
        return (
          <AnnotationButton key={`tt_${annotationId}_${index}`} className="readonly-button">
            {child}
          </AnnotationButton>
        );
      })
    : [
        <AnnotationButton key={`tt_${annotationId}_0`} className="readonly-button">
          {children}
        </AnnotationButton>
      ];

  return (
    <AnnotationTooltipStyled style={{ height: "4.6rem", display: "flex" }}>
      {buttons}
      {canDelete ? (
        <AnnotationButton>
          <Icon name="trash" onClick={deleteClick} style={{ cursor: "pointer" }} />
        </AnnotationButton>
      ) : null}
      <ConfirmationDialog
        title="Are you sure you would like to delete this annotation?"
        message="This will permanently remove the annotation from your image"
        isOpen={isDeleteConfirmationDialogOpen}
        onConfirm={processDeleteAnnotation(annotationId, dispatch)}
        onCancel={onCancelConfirmationDialog}
      />
    </AnnotationTooltipStyled>
  );
};

export default AnnotationTooltip;
