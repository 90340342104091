import { Icon } from "@blasterjs/core";
import { sortBy } from "lodash";
import { range, zipWith } from "ramda";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

// NOTE: Must tell the linter to not attempt to fix the order of these imports since order matters here (boo leaflet!)
/* eslint:disable */
import L, { LatLngBounds, LatLngLiteral } from "leaflet";
import { MapContainer, useMapEvent } from "react-leaflet";
import "leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "@jjwtay/leaflet.ellipse";
import "@jjwtay/leaflet.draw-ellipse";
import "leaflet-freehandshapes";
/* eslint:enable */

import MetadataImages from "../components/MetadataImages";
import Expandable from "./Expandable";
import { RolePermissions } from "../permissions";

import {
  Annotation,
  Image,
  ImageWithAnnotations,
  Indication,
  ProcessedImageWithAnnotations,
  ProcessingStatusType,
  User,
  ZoomDefinition
} from "../models";
import {
  SelectedAnnotationType,
  initialState,
  toggleSidebarExpanded
} from "../slices/caseImageViewer";
import { Resource } from "../types";
import { getHpfRadiusInMeters, getMaxZoom, getMinZoom, toLatLngBounds } from "../utils";
import ImageMap from "./ImageMap";
import AnnotationSidebar from "./AnnotationSidebar";
import MapControls from "./MapControls";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setZoom } from "../slices/map";

const Container = styled.div`
  position: absolute;
  top: ${props => props.theme.heights.appHeader.height};
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: #fff;
`;

const StyledMapContainer = styled.div`
  flex: 1;
  height: 100%;
  position: relative;
`;

const BlankMapContainer = styled.div`
  height: 100%;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MetadataImagesPanel = styled.div`
  display: flex;
  margin: 15px 15px 0 0;
  z-index: 30000;
  padding: 0;
  right: 15px;
  top: 15px;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  margin: 15px 15px 0 0;
`;

const ExpandablesContainer = styled.div`
  z-index: 10000;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
`;

const CollapsedSidebarContainer = styled.div`
  display: flex;
  margin: 15px 15px 0 0;
`;

const BrightnessFilter = styled.div`
  .leaflet-tile-pane {
    // NOTE: creating a new interface which only has the brightness adjust key pair
    filter: ${(props: Pick<StateProps, "brightnessAdjust">) =>
      `brightness(${100 + props.brightnessAdjust}%)`};
  }
`;

interface Props {
  readonly imageWithAnnotations: ImageWithAnnotations | null;
  readonly tileServerLocation: string;
  readonly topBar: React.ReactNode;
  readonly sidebar: React.ReactNode;
}

interface StateProps {
  readonly allHighlightedAnnotations: ReadonlyArray<Annotation>;
  readonly highlightedAnnotations: ReadonlyArray<string>;
  readonly brightnessAdjust: number;
  readonly hideAnnotations: boolean;
  readonly isAnnotationInProgress: boolean;
  readonly isSidebarExpanded: boolean;
  readonly isMicroscopeActive: boolean;
  readonly selectedAnnotation: SelectedAnnotationType | null;
  readonly tempHpf: LatLngLiteral | null;
  readonly hpfCursorColor: string | null;
  readonly user: Resource<User>;
  readonly zoom: number;
  readonly indications: ReadonlyArray<Indication> | null;
}

const makeZoomDefinitions = (
  magnifications: ReadonlyArray<number>,
  maxZoom: number
): ReadonlyArray<ZoomDefinition> => {
  return zipWith(
    (idx, mag) => {
      return { mag: `${mag}x`, zoom: idx, overzoomed: false };
    },
    // Desired zoom levels are figured out from the image's max TMS zoom, then one each
    // for the magnifications on the image until they're less than one
    range(
      // why subtract one from the length of magnifications? because "length + an endpoint"
      // will go one past the end of the > 1 magnifications
      maxZoom - (magnifications.length - 1),
      // why + 1? range isn't inclusive, and we want the max zoom
      maxZoom + 1
    ),
    magnifications
  );
};

const SetZoom = () => {
  const dispatch = useAppDispatch();

  const map = useMapEvent("zoom", () => {
    map && dispatch(setZoom(map.getZoom()));
  });

  return null;
};

function resolutionInMeters(image: Image): number {
  return "extent" in image // Narrow type to processed image
    ? image.physicalResolution * 10 ** -6
    : 0.2 * 10 ** -6; // default to the Huron image's physical resolution if no resolution is present
}

// Image processing assumes this max zoom level is the default (what was used for Huron images
// initially)
const DEFAULT_MAX_ZOOM = 19;

const ImageViewer = (props: Props) => {
  const dispatch = useAppDispatch();

  const allHighlightedAnnotations = useAppSelector(
    state => state.caseImageViewer.allHighlightedAnnotations
  );
  const highlightedAnnotations = useAppSelector(
    state => state.caseImageViewer.highlightedAnnotations
  );
  const brightnessAdjust = useAppSelector(state => state.caseImageViewer.brightnessAdjust);
  const hideAnnotations = useAppSelector(state => state.caseImageViewer.hideAnnotations);
  const isAnnotationInProgress = useAppSelector(
    state => state.caseImageViewer.annotation !== initialState.annotation
  );
  const isSidebarExpanded = useAppSelector(state => state.caseImageViewer.isSidebarExpanded);
  const isMicroscopeActive = useAppSelector(state => state.caseImageViewer.isMicroscopeActive);
  const selectedAnnotation = useAppSelector(state =>
    state.caseImageViewer.annotation.data
      ? state.caseImageViewer.annotation.data.selectedAnnotationType
      : null
  );
  const tempHpf = useAppSelector(state => state.caseImageViewer.tempHpf);
  const hpfCursorColor = useAppSelector(state => state.caseImageViewer.hpfCursorColor);
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const zoom = useAppSelector(state => state.map.zoom);
  const indications: ReadonlyArray<Indication> = useAppSelector(
    state => state.studyConfiguration.study.data.indications.value
  );

  const { imageWithAnnotations, tileServerLocation, topBar, sidebar } = props;

  const [mapElement, setMapElement] = useState<L.Map | null>(null);
  const [mapBounds, setMapBounds] = useState<LatLngBounds | undefined>();
  const [hpfCursorVisible, setHpfCursorVisible] = useState<boolean | false>();
  const [showLineLabels, setShowLineLabels] = useState(false);
  const [spacebarDown, setSpacebarDown] = useState(false);

  const hpfRadius =
    imageWithAnnotations && "pixelSize" in imageWithAnnotations.imageAndQuery.image
      ? getHpfRadiusInMeters(
          imageWithAnnotations.imageAndQuery.image.pixelSize,
          resolutionInMeters(imageWithAnnotations.imageAndQuery.image),
          indications
        )
      : 100;

  // update the locator map box when sidebar is toggled
  useEffect(() => {
    setMapBounds(mapElement?.getBounds());
  }, [isSidebarExpanded, mapElement]);

  const tileUrl = imageWithAnnotations
    ? `${tileServerLocation}/tiles/${imageWithAnnotations.imageAndQuery.image.id}/{z}/{x}/{y}`
    : null;

  const magnifications: ReadonlyArray<ZoomDefinition> =
    imageWithAnnotations && "magnifications" in imageWithAnnotations.imageAndQuery.image
      ? makeZoomDefinitions(
          imageWithAnnotations.imageAndQuery.image.magnifications.filter(x => x >= 1),
          imageWithAnnotations.imageAndQuery.image.maxTmsZoom
        )
      : [];
  const maxZoom = getMaxZoom(magnifications);
  const minZoom = getMinZoom(magnifications);

  const canViewAnnotations: boolean =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewAnnotations]);

  const allHAnnotations: ReadonlyArray<Annotation> =
    !hideAnnotations && canViewAnnotations
      ? imageWithAnnotations
        ? // NOTE: We sort by when annotations were created here so that if annotations of the same
          // type are drawn on top of each other for some reason they'll show up in the appropriate
          // order since the order in this array determines the order in which they're added to the
          // DOM which determines the z-index.
          //sortBy(imageWithAnnotations.annotations, "createdAt")
          highlightedAnnotations.length > 0
          ? sortBy(allHighlightedAnnotations, "createdAt")
          : sortBy(allHighlightedAnnotations, "createdAt")
        : []
      : [];

  const isUserAdminOrReader =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_ImageViewer_EditCreateAnnotations]);

  const userCanDeleteAnnotations = isUserAdminOrReader;

  // This scale factor is used to scale the size of point annotations appropriately based on the max
  // zoom to avoid the issue where images with a higher max zoom than "expected" -- the max zoom is
  // determined by the number of overviews in the original image when processed since we start with
  // a desired min zoom and go from there -- would show up as having point annotations that are too
  // large. The reverse could also happen with images that have "too few" overviews, but that wasn't
  // seen in practice. This corrects for both cases so that point annotations are always the same
  // size when fully zoomed in for all images.
  const scaleFactor = maxZoom === 0 ? 1 : 2 ** (DEFAULT_MAX_ZOOM - maxZoom);

  const collapsedSidebarContainer = (
    <CollapsedSidebarContainer>
      <Expandable
        label="Sidebar"
        isExpanded={false}
        onToggle={() => dispatch(toggleSidebarExpanded())}
      />
    </CollapsedSidebarContainer>
  );

  const map =
    imageWithAnnotations && tileUrl ? (
      "extent" in imageWithAnnotations.imageAndQuery.image ? (
        // Image is processed and a tile url is set (tile url is dependent on a non-null image)
        <BrightnessFilter brightnessAdjust={brightnessAdjust}>
          {/* NOTE: Setting the key on MapContainer is important to ensure the map is reinitialized when image changes */}
          <MapContainer
            key={imageWithAnnotations.imageAndQuery.image.id}
            bounds={toLatLngBounds(imageWithAnnotations.imageAndQuery.image.extent)}
            zoomControl={false}
            fadeAnimation={false}
            zoomAnimationThreshold={100}
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0
            }}
            maxZoom={maxZoom}
            minZoom={minZoom}
            whenCreated={(map: L.Map) => {
              setMapBounds(map.getBounds());
              setMapElement(map);
              dispatch(setZoom(map.getZoom()));
            }}
            whenReady={() => {
              mapElement && dispatch(setZoom(mapElement.getZoom()));
            }}
          >
            <ImageMap
              minZoom={minZoom}
              maxZoom={maxZoom}
              allAnnotations={allHAnnotations}
              userCanDeleteAnnotations={userCanDeleteAnnotations}
              scaleFactor={scaleFactor}
              isAnnotationInProgress={isAnnotationInProgress}
              imageWithAnnotations={imageWithAnnotations as ProcessedImageWithAnnotations}
              selectedAnnotation={selectedAnnotation}
              tempHpf={tempHpf}
              hpfRadius={hpfRadius}
              zoom={zoom}
              magnifications={magnifications}
              isMicroscopeActive={isMicroscopeActive}
              isSidebarExpanded={isSidebarExpanded}
              resolutionInMeters={resolutionInMeters(imageWithAnnotations.imageAndQuery.image)}
              tileUrl={tileUrl}
              hpfCursorVisible={hpfCursorVisible || false}
              hpfCursorColor={hpfCursorColor}
              showLineLabels={showLineLabels}
              spacebarDown={spacebarDown}
              loggedInUser={loggedInUser}
              drawMap={mapElement as L.DrawMap}
            />
            <SetZoom />
          </MapContainer>
          {mapElement && "resource" in loggedInUser && (
            <MapControls
              mapElement={mapElement}
              maxZoom={maxZoom}
              minZoom={minZoom}
              imageWithAnnotations={imageWithAnnotations as ProcessedImageWithAnnotations}
              isAnnotationInProgress={isAnnotationInProgress}
              loggedInUser={loggedInUser.resource}
              zoom={zoom}
              magnifications={magnifications}
              isMicroscopeActive={isMicroscopeActive}
              resolutionInMeters={resolutionInMeters(imageWithAnnotations.imageAndQuery.image)}
              tileUrl={tileUrl}
              mapBounds={mapBounds}
              setMapBounds={setMapBounds}
              hpfCursorVisible={hpfCursorVisible || false}
              setHpfCursorVisible={setHpfCursorVisible}
              setShowLineLabels={setShowLineLabels}
            />
          )}
          <ExpandablesContainer>
            {"resource" in loggedInUser &&
            loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewLabel]) ? (
              <MetadataImagesPanel>
                <MetadataImages
                  imageId={
                    imageWithAnnotations ? imageWithAnnotations.imageAndQuery.image.id : null
                  }
                />
              </MetadataImagesPanel>
            ) : null}
            {!isSidebarExpanded ? collapsedSidebarContainer : null}
          </ExpandablesContainer>
        </BrightnessFilter>
      ) : (
        // No extent means image is not processed
        <BlankMapContainer>
          {"processingStatus" in imageWithAnnotations.imageAndQuery.image &&
          imageWithAnnotations.imageAndQuery.image.processingStatus ===
            ProcessingStatusType.Fail ? (
            <>Image processing failed</>
          ) : (
            <>
              Image processing...&nbsp;
              <Icon name="processing" />
            </>
          )}
          {!isSidebarExpanded ? (
            <ExpandablesContainer>{collapsedSidebarContainer}</ExpandablesContainer>
          ) : null}
        </BlankMapContainer>
      )
    ) : (
      // No image
      <BlankMapContainer>
        No image(s) found&nbsp;
        <Icon name="warning" />
      </BlankMapContainer>
    );

  return (
    <>
      {topBar}
      <Container>
        <StyledMapContainer>{map}</StyledMapContainer>
        {imageWithAnnotations && "pixelSize" in imageWithAnnotations.imageAndQuery.image && (
          <AnnotationSidebar
            mapElement={mapElement as L.DrawMap}
            pixelSize={imageWithAnnotations.imageAndQuery.image.pixelSize}
            resolutionInMeters={resolutionInMeters(imageWithAnnotations.imageAndQuery.image)}
            scaleFactor={scaleFactor}
            hpfRadius={hpfRadius}
            spacebarDown={spacebarDown}
            setSpacebarDown={setSpacebarDown}
          />
        )}

        {isSidebarExpanded ? (
          <Expandable
            label={"Sidebar"}
            isExpanded={true}
            onToggle={() => dispatch(toggleSidebarExpanded())}
          >
            {sidebar}
          </Expandable>
        ) : null}
      </Container>
    </>
  );
};

export default ImageViewer;
