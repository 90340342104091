import { Box, Button, Callout, Dialog, Intent, Text } from "@blasterjs/core";
import React, { useEffect } from "react";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearDownloadImageDialog,
  closeDownloadImageDialog,
  imageDownload
} from "../slices/downloadImageDialog";

const downloadImageDialog = () => {
  const dispatch = useAppDispatch();
  const downloadImageDialog = useAppSelector(state => state.downloadImageDialog);

  useEffect(() => {
    if (downloadImageDialog.imageUrl) {
      window.open(downloadImageDialog.imageUrl, "_blank");
      dispatch(clearDownloadImageDialog());
    }
  });

  const closeDialog = () => {
    dispatch(closeDownloadImageDialog());
  };

  const onConfirm = () => {
    dispatch(imageDownload());
  };

  const errorText =
    downloadImageDialog.isOpen && "errorMessage" in downloadImageDialog.image ? (
      <Box mb="2">
        <Callout intent={Intent.DANGER}>
          <Text>{downloadImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  return downloadImageDialog.isOpen ? (
    <Dialog isOpen={downloadImageDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader title="Download image" closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box>Are you sure you want to download {downloadImageDialog.image.data.image.name}?</Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button onClick={onConfirm} appearance="prominent" intent="danger">
            Confirm
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  ) : null;
};

export default downloadImageDialog;
