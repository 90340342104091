import { Appearance, Button, Icon } from "@blasterjs/core";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Image, ImageListView, ImageWithCase, ProcessingStatusType, UUID } from "../models";
import type { AppDispatch } from "../store";

import QueryIcon from "../components/QueryIcon";
import { StyledDataTable } from "../components/Table";
import Timestamp from "../components/Timestamp";
import TooltipFlyout, { TooltipLink, TooltipLinkDanger } from "../components/Tooltip";
import TooltipText from "../components/TooltipText";
import Content from "./Content";
import CopyImageDialog from "./CopyImageDialog";
import DeleteImageDialog from "./DeleteImageDialog";
import DownloadImageDialog from "./DownloadImageDialog";
import ImageDialog from "./ImageDialog";
import MoveImageDialog from "./MoveImageDialog";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { imagesFetch } from "../slices/images";
import { openImageDialog } from "../slices/imageDialog";
import { openMoveImageDialog } from "../slices/moveImageDialog";
import { openCopyImageDialog } from "../slices/copyImageDialog";
import { openUploadDialog } from "../slices/uploadDialog";
import { openDeleteImageDialog } from "../slices/deleteImageDialog";
import { openDownloadImageDialog } from "../slices/downloadImageDialog";
import { LoggedInUser, RolePermissions } from "../permissions";

interface Props {
  readonly studyId: UUID;
  readonly loggedInUser: LoggedInUser;
}

const editImage = (image: Image, dispatch: AppDispatch) => () =>
  dispatch(openImageDialog({ image: image, refreshSingleImageOnSuccess: false }));
const moveImage = (image: Image, dispatch: AppDispatch) => () =>
  dispatch(openMoveImageDialog(image));

const copyImage = (image: Image, dispatch: AppDispatch) => () =>
  dispatch(openCopyImageDialog(image));
const replaceImage = (image: Image, studyId: UUID, dispatch: AppDispatch) => () =>
  dispatch(openUploadDialog({ studyId: studyId, imageToReplace: image }));
const deleteImage = (image: Image, dispatch: AppDispatch) => () =>
  dispatch(openDeleteImageDialog(image));
const downloadImage = (image: Image, dispatch: AppDispatch) => () =>
  dispatch(openDownloadImageDialog(image));

const imageIsProcessed = (image: Image): boolean => "cogKey" in image;
const imageProcessingFailed = (image: Image): boolean =>
  !("cogKey" in image) && image.processingStatus === ProcessingStatusType.Fail;

const ImagesTable = ({ studyId, loggedInUser }: Props) => {
  const dispatch = useAppDispatch();
  const images = useAppSelector(state => state.images.images);

  useEffect(() => {
    dispatch(imagesFetch(studyId));
  }, [studyId]);

  const navigate = useNavigate();
  const [imageRowsPerPage, setImageRowsPerPage] = useLocalIntStorage("imageRowsPerPage", 20);
  const viewImage = ({ imageAndQuery }: ImageListView) =>
    navigate(`/studies/${imageAndQuery.image.studyId}/images/${imageAndQuery.image.id}`);
  const userCanEditImages = loggedInUser.can([
    RolePermissions.S_ImageListTab_MoveImage,
    RolePermissions.S_ImageListTab_CopyImage
  ]);
  const userCanDeleteImages = loggedInUser.can([RolePermissions.IM_ImagesListTab_Archive]);
  const userCanDownloadImages = loggedInUser.can([RolePermissions.IM_ImagesListTab_DownloadImages]);
  const userCanUploadImages = loggedInUser.can([RolePermissions.S_ImageListTab_UploadImage]);
  const columns: ReadonlyArray<object> = [
    {
      name: "File Name",
      grow: 2,
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.name,
      sortable: true
    },
    {
      name: "Uploaded",
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.uploadedAt,
      sortable: true,
      format: ({ imageAndQuery }: ImageListView) => (
        <Timestamp date={imageAndQuery.image.uploadedAt} />
      )
    },
    {
      name: "Accession #",
      grow: 1,
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.accessionNumber,
      sortable: true
    },
    {
      name: "Anatomical Segment",
      grow: 1,
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.biopsyLocation,
      sortable: true
    },
    {
      name: "Processed",
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.image.processingStatus,
      sortable: true,
      grow: 0,
      cell: ({ imageAndQuery }: ImageListView) =>
        imageIsProcessed(imageAndQuery.image) ? (
          <TooltipText text="Succeeded">
            <Icon name="processSuccess" color="green" />
          </TooltipText>
        ) : imageProcessingFailed(imageAndQuery.image) ? (
          <TooltipText text="Failed">
            <Icon name="processFail" color="red" />
          </TooltipText>
        ) : null
    },
    {
      name: "Query",
      selector: ({ imageAndQuery }: ImageListView) => imageAndQuery.query,
      sortable: true,
      grow: 0,
      cell: ({ imageAndQuery }: ImageListView) => <QueryIcon imageAndQuery={imageAndQuery} />
    },
    ...(loggedInUser.can([RolePermissions.IM_CaseListTab_ViewPage])
      ? [
          {
            name: "Case",
            selector: (imageListView: ImageWithCase) => imageListView.simpleCase?.procId,
            sortable: true,
            grow: 1,
            cell: (imageListView: ImageWithCase) =>
              imageListView.simpleCase ? (
                <Link
                  to={`/studies/${imageListView.imageAndQuery.image.studyId}/cases/${imageListView.simpleCase.id}?tab=images`}
                  style={{ textDecoration: "none" }}
                >
                  <Button>{imageListView.simpleCase.procId}</Button>
                </Link>
              ) : null
          }
        ]
      : []),
    {
      right: true,
      grow: 0,
      cell: ({ imageAndQuery }: ImageListView) =>
        userCanEditImages || userCanUploadImages || userCanDeleteImages ? (
          <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
            <Icon name="menu" />
            <TooltipFlyout placement={"left-start"}>
              {loggedInUser.can([RolePermissions.S_ImageListTab_CopyImage]) && (
                <TooltipLink onClick={editImage(imageAndQuery.image, dispatch)}>Edit</TooltipLink>
              )}
              {loggedInUser.can([RolePermissions.S_ImageListTab_CopyImage]) && (
                <TooltipLink onClick={copyImage(imageAndQuery.image, dispatch)}>Copy</TooltipLink>
              )}
              {loggedInUser.can([RolePermissions.S_ImageListTab_MoveImage]) && (
                <TooltipLink onClick={moveImage(imageAndQuery.image, dispatch)}>Move</TooltipLink>
              )}

              {userCanUploadImages && imageAndQuery.query && imageAndQuery.image.studyId ? (
                <>
                  <TooltipLink
                    onClick={replaceImage(
                      imageAndQuery.image,
                      imageAndQuery.image.studyId,
                      dispatch
                    )}
                  >
                    Replace
                  </TooltipLink>
                </>
              ) : null}
              {userCanDeleteImages ? (
                <TooltipLinkDanger onClick={deleteImage(imageAndQuery.image, dispatch)}>
                  Archive
                </TooltipLinkDanger>
              ) : null}
              {userCanDownloadImages ? (
                <TooltipLinkDanger onClick={downloadImage(imageAndQuery.image, dispatch)}>
                  Download
                </TooltipLinkDanger>
              ) : null}
            </TooltipFlyout>
          </Button>
        ) : null
    }
  ];
  const moveImageDialog = userCanEditImages ? <MoveImageDialog /> : null;
  const editImageDialog = userCanEditImages ? <ImageDialog /> : null;
  const deleteImageDialog = userCanDeleteImages ? <DeleteImageDialog /> : null;
  const downloadImageDialog = userCanDownloadImages ? <DownloadImageDialog /> : null;
  const copyImageDialog = userCanEditImages ? <CopyImageDialog /> : null;

  return (
    <>
      <Content isLoading={"isPending" in images}>
        {"resource" in images ? (
          <StyledDataTable
            columns={columns}
            data={images.resource}
            highlightOnHover={true}
            pointerOnHover={true}
            defaultSortField="imageAndQuery.image.uploadedAt"
            defaultSortAsc={false}
            sortIcon={<Icon name="caretUp" />}
            className="data-table"
            onRowClicked={viewImage}
            noHeader={true}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            paginationPerPage={imageRowsPerPage}
            onChangeRowsPerPage={setImageRowsPerPage}
            customTheme={dataTableTheme}
          />
        ) : null}
      </Content>
      {moveImageDialog}
      {copyImageDialog}
      {editImageDialog}
      {deleteImageDialog}
      {downloadImageDialog}
    </>
  );
};

export default ImagesTable;
