import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CasesAndCounts, Studies, UUID } from "../models";
import { Resource } from "../types";
import {
  assignImageToCaseApi,
  copyImage,
  deleteImage,
  downloadImage,
  fetchCases,
  fetchStudiesForUser,
  moveImageToCaseApi,
  moveImageToStudyApi
} from "../api";
import { castDraft } from "immer";

export enum DialogMode {
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

/*
operaions (cases)
   - deselect all
   - revert status
   - put on hold
   - remove hold
   - download image (single select)
   - archive
   more menu:
    inputs:
      caseId or caseIds (array)
      - revert status
        - list of statuses in DDL
        - reason for change inputbox
      - put on hold
        - reason for change inputbox
      - remove hold
        - reason for change inputbox
      - view images
      - download
        - button to download images for case
      - archive
        - reason for change inputbox
*/
export enum ImageRowOptions {
  AssignToCase = "AssignToCase",
  CopyImage = "Copy Image",
  MoveImage = "Move Image",
  ViewImage = "View Image",
  DownloadImage = "Download Image",
  Archive = "Archive Image"
}

export type ImageManagementDialogParentType = string;

export interface ImageManagementImageDialogParentState {
  readonly mode: DialogMode;
  readonly modalMessage: ModalMessage | null;
  readonly modalParams: OpenImageManagementImageDialogParentParams | null;
  readonly cases: Resource<CasesAndCounts>;
  readonly studies: Resource<Studies>;
  readonly downloadImageControl: DownloadImageControl | null;
}

export interface DownloadImageControl {
  imageUrl: string | null;
}

// user feedback messages displayed in the modals
export interface ModalMessage {
  success: boolean;
  message: string;
}

export const initialState: ImageManagementImageDialogParentState = {
  mode: DialogMode.Closed,
  modalMessage: null,
  modalParams: null,
  cases: { isPending: false },
  studies: {
    isPending: false
  },
  downloadImageControl: null
};

export type ImageDialogParentSingleSelectionParams = {
  imageRowOptions: ImageRowOptions;
  imageId: UUID | null;
};

export type ImageDialogParentMultiSelectionParams = {
  imageRowOptions: ImageRowOptions;
  imageId: UUID | null;
  imageFilename: string | null;
  studyId: UUID | null;
};

export type OpenImageManagementImageDialogParentParams =
  | ImageDialogParentSingleSelectionParams
  | ImageDialogParentMultiSelectionParams;

// thunks
export const openImageManagementImageDialogParent = createAsyncThunk(
  "imageManagementImageDialogParent/openImageManagementDialogParent",
  (params: OpenImageManagementImageDialogParentParams, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setOpenImageManagementImageDialogParent(params));
  }
);

export const casesFetch = createAsyncThunk(
  "imageManagementImageDialogParent/casesFetch",
  async (studyId: string) => {
    const response = await fetchCases(studyId);
    return response;
  }
);

export const studiesForUserFetch = createAsyncThunk(
  "imageManagementImageDialogParent/studiesForUserFetch",
  async () => {
    const response = await fetchStudiesForUser();
    return response;
  }
);

export interface MoveImageToStudyParams {
  studyId: UUID;
  imageId: UUID;
  reasonForChange: string;
}

export const moveImageToStudy = createAsyncThunk(
  "imageManagementImageDialogParent/moveImageToStudy",
  async (params: MoveImageToStudyParams) => {
    await moveImageToStudyApi(params.imageId, {
      value: params.studyId,
      reasonForChange: params.reasonForChange || undefined
    });
  }
);

export interface MoveImageToCaseParams {
  caseId: UUID;
  imageId: UUID;
  reasonForChange: string;
}

export const moveImageToCase = createAsyncThunk(
  "imageManagementImageDialogParent/moveImageToCase",
  async (params: MoveImageToCaseParams) => {
    await moveImageToCaseApi(params.imageId, {
      value: params.caseId,
      reasonForChange: params.reasonForChange || undefined
    });
  }
);

export interface ImageCopyParams {
  imageId: UUID;
  duplicateImageName: string;
}
export const imageCopy = createAsyncThunk(
  "imageManagementImageDialogParent/imageCopy",
  async (params: ImageCopyParams, thunkApi) => {
    const { dispatch } = thunkApi;
    const response = await copyImage(params.imageId, params.duplicateImageName);
    if (response) {
      dispatch(
        setModalMessaging({
          success: true,
          message: "Image copied successfully"
        })
      );
    }
    return response;
  }
);

export interface ArchiveImageParams {
  imageId: UUID;
  reasonForChange: string;
}
export const archiveImage = createAsyncThunk(
  "imageManagementImageDialogParent/archiveImage",
  async (params: ArchiveImageParams) => {
    await deleteImage({
      value: params.imageId,
      reasonForChange: params.reasonForChange || undefined
    });
  }
);

export interface AssignImageToCaseParams {
  caseId: UUID;
  imageId: UUID;
  reasonForChange: string;
}
export const assignImageToCase = createAsyncThunk(
  "imageManagementImageDialogParent/assignImageToCase",
  async (params: AssignImageToCaseParams) => {
    await assignImageToCaseApi(params.imageId, params.caseId, params.reasonForChange);
  }
);

export const imageDownload = createAsyncThunk(
  "imageManagementImageDialogParent/imageDownload",
  async (imageId: UUID, thunkApi) => {
    const { dispatch } = thunkApi;
    const downloadResponse = await downloadImage(imageId);
    if (downloadResponse && downloadResponse.url) {
      dispatch(setDownloadImageUrl(downloadResponse.url));
    }
  }
);

// slice
export const imageManagementImageDialogParentSlice = createSlice({
  name: "imageManagementImageDialogParent",
  initialState: initialState,
  reducers: {
    setOpenImageManagementImageDialogParent: (state, action) => {
      return {
        ...initialState,
        mode: DialogMode.OpenForEdit,
        modalParams: action.payload
      };
    },
    closeOpenImageManagementImageDialogParent: state => {
      state.mode = DialogMode.Closed;
    },
    setDownloadImageUrl: (state, action) => {
      state.downloadImageControl = {
        imageUrl: action.payload
      };
    },
    clearDownloadImageControl: state => {
      state.downloadImageControl = null;
    },
    setModalMessaging: (state, action: PayloadAction<ModalMessage>) => {
      state.modalMessage = {
        success: action.payload.success,
        message: action.payload.message
      };
    },
    clearModalMessaging: state => {
      state.modalMessage = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(studiesForUserFetch.pending, state => {
      state.studies = { isPending: true };
    });
    builder.addCase(studiesForUserFetch.fulfilled, (state, action) => {
      state.studies = { resource: castDraft(action.payload) };
    });
    builder.addCase(studiesForUserFetch.rejected, (state, action) => {
      state.studies = { errorMessage: action.error.message || "" };
    });

    builder.addCase(casesFetch.pending, state => {
      state.cases = { isPending: true };
    });
    builder.addCase(casesFetch.fulfilled, (state, action) => {
      state.cases = { resource: castDraft(action.payload) };
    });
    builder.addCase(casesFetch.rejected, (state, action) => {
      state.cases = { errorMessage: action.error.message || "" };
    });

    // modal feedback

    builder.addCase(archiveImage.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The image was archived successfully"
      };
    });
    builder.addCase(archiveImage.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
    builder.addCase(assignImageToCase.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The image was assigned successfully"
      };
    });
    builder.addCase(assignImageToCase.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
    builder.addCase(moveImageToStudy.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The image was moved successfully"
      };
    });
    builder.addCase(moveImageToStudy.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
    builder.addCase(moveImageToCase.fulfilled, state => {
      state.modalMessage = {
        success: true,
        message: "The image was moved successfully"
      };
    });
    builder.addCase(moveImageToCase.rejected, (state, action) => {
      state.modalMessage = {
        success: false,
        message: action.error.message || ""
      };
    });
  }
});

export const {
  setOpenImageManagementImageDialogParent,
  closeOpenImageManagementImageDialogParent,
  setDownloadImageUrl,
  clearDownloadImageControl,
  setModalMessaging,
  clearModalMessaging
} = imageManagementImageDialogParentSlice.actions;
export default imageManagementImageDialogParentSlice.reducer;
