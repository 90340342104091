import { Appearance, Box, Button, Icon } from "@blasterjs/core";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "../components/Avatar";
import { StyledDataTable } from "../components/Table";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
import Content from "./Content";
import {
  AdminCase,
  CaseAndCounts,
  CaseAndCountsAdminView,
  formatCaseStatus,
  UUID
} from "../models";
import { AppDispatch } from "../store";
import { dataTableTheme } from "../theme";
import CaseDialog from "./CaseDialog";
import Timestamp from "../components/Timestamp";

import CaseStatusDialog from "./CaseStatusDialog";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { casesFetch } from "../slices/cases";
import { openEditCaseDialog } from "../slices/caseDialog";
import { openCaseStatusDialog } from "../slices/caseStatusDialog";
import { LoggedInUser, RolePermissions } from "../permissions";

interface Props {
  readonly studyId: UUID;
  readonly loggedInUser: LoggedInUser;
  readonly userIsReader: boolean;
}

const editCase = (histoCase: AdminCase, dispatch: AppDispatch) => () =>
  dispatch(
    openEditCaseDialog({
      id: histoCase.id,
      studyId: histoCase.studyId,
      refreshSingleCaseOnSuccess: false
    })
  );

const editCaseStatus = (histoCase: AdminCase, dispatch: AppDispatch) => () =>
  dispatch(openCaseStatusDialog(histoCase));

const CasesTable = ({ studyId, loggedInUser, userIsReader }: Props) => {
  const dispatch = useAppDispatch();
  const cases = useAppSelector(state => state.cases.cases);

  useEffect(() => {
    dispatch(casesFetch(studyId));
  }, [studyId]);
  const navigate = useNavigate();
  const userCanEditCaseStatus = loggedInUser.can([RolePermissions.S_CaseListTab_EditCase]);
  const columns: ReadonlyArray<object> = [
    ...[
      {
        name: "Endo Procedure ID",
        width: "19rem",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.procId,
        sortable: true
      },
      {
        name: "Histo Procedure ID",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.histoProcedureId,
        sortable: true,
        width: "19rem"
      },
      {
        name: "Created",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.createdAt,
        sortable: true,
        width: "12rem",
        format: (caseAndCounts: CaseAndCounts) => (
          <Timestamp date={caseAndCounts.caseWithStatus.createdAt} />
        )
      }
    ],
    ...(loggedInUser.can([RolePermissions.AP_CaseData_ViewSubjectID])
      ? [
          {
            name: "Subject ID",
            selector: (caseAndCounts: CaseAndCountsAdminView) =>
              caseAndCounts.caseWithStatus.subjectId,
            sortable: true,
            width: "14rem"
          }
        ]
      : []),
    ...(loggedInUser.can([RolePermissions.AP_CaseData_ViewVisitID])
      ? [
          {
            name: "Visit ID",
            selector: (caseAndCounts: CaseAndCountsAdminView) =>
              caseAndCounts.caseWithStatus.visitId,
            sortable: true,
            width: "14rem"
          }
        ]
      : []),
    ...[
      ...(loggedInUser.can([RolePermissions.AP_ImageData_ViewImageStatus])
        ? [
            {
              name: "Status",
              selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.caseWithStatus.status,
              sortable: true,
              format: (caseAndCounts: CaseAndCounts) =>
                formatCaseStatus(caseAndCounts.caseWithStatus.status)
            }
          ]
        : []),
      ...(userIsReader
        ? []
        : [
            {
              name: "QC",
              selector: (caseInfo: CaseAndCountsAdminView) => caseInfo.qc1User,
              sortable: true,
              width: "78px",
              format: (caseInfo: CaseAndCountsAdminView) =>
                caseInfo.qc1User ? <Avatar user={caseInfo.qc1User} /> : "—"
            },
            {
              name: "Assigned",
              selector: (caseInfo: CaseAndCountsAdminView) => caseInfo.assignedReaders,
              sortable: true,
              width: "128px",
              format: (caseInfo: CaseAndCountsAdminView) => {
                if (caseInfo.assignedReaders) {
                  const firstAssignedReader =
                    0 in caseInfo.assignedReaders ? caseInfo.assignedReaders[0] : null;
                  const secondAssignedReader =
                    1 in caseInfo.assignedReaders ? caseInfo.assignedReaders[1] : null;
                  return (
                    <span>
                      {firstAssignedReader && <Avatar user={firstAssignedReader} />}
                      {secondAssignedReader && (
                        <Box ml="3px" mr="3px" display="inline">
                          <Avatar user={secondAssignedReader} />
                        </Box>
                      )}
                      {caseInfo.assignedReaders.length > 2 ? "…" : null}
                    </span>
                  );
                } else {
                  return "—";
                }
              }
            }
          ]),
      {
        name: "Images",
        selector: (caseAndCounts: CaseAndCounts) => caseAndCounts.numberOfImages,
        width: "128px",
        sortable: true
      }
    ],
    ...(userIsReader
      ? []
      : [
          {
            name: "Queries",
            selector: (caseAndCountsAdminView: CaseAndCountsAdminView) =>
              caseAndCountsAdminView.caseWithStatus.openQueries,
            sortable: true,
            width: "128px"
          }
        ]),
    ...[
      {
        right: true,
        width: "40px",
        cell: (caseAndCounts: CaseAndCounts) =>
          "subjectId" in caseAndCounts.caseWithStatus &&
          loggedInUser.can([RolePermissions.S_CaseListTab_EditCase]) ? (
            <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
              <Icon name="menu" />
              <TooltipFlyout placement={"left-start"}>
                <TooltipLink onClick={editCase(caseAndCounts.caseWithStatus, dispatch)}>
                  Edit
                </TooltipLink>
                {caseAndCounts.caseWithStatus.prevWorkflowStatuses.length ? (
                  <TooltipLink onClick={editCaseStatus(caseAndCounts.caseWithStatus, dispatch)}>
                    Revert status
                  </TooltipLink>
                ) : null}
              </TooltipFlyout>
            </Button>
          ) : null
      }
    ]
  ];
  const viewCase = (caseAndCounts: CaseAndCounts) =>
    navigate(
      `/studies/${caseAndCounts.caseWithStatus.studyId}/cases/${caseAndCounts.caseWithStatus.id}`
    );

  const [caseRowsPerPage, setCaseRowsPerPage] = useLocalIntStorage("caseRowsPerPage", 20);

  const caseStatusDialog = userCanEditCaseStatus ? <CaseStatusDialog /> : null;
  return (
    <Content isLoading={"isPending" in cases || "isPending" in loggedInUser}>
      {"resource" in cases ? (
        <StyledDataTable
          columns={columns}
          data={cases.resource}
          highlightOnHover={true}
          pointerOnHover={true}
          defaultSortField="caseWithStatus.createdAt"
          defaultSortAsc={false}
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          onRowClicked={viewCase}
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={caseRowsPerPage}
          onChangeRowsPerPage={setCaseRowsPerPage}
          customTheme={dataTableTheme}
        />
      ) : null}
      <CaseDialog />
      {caseStatusDialog}
    </Content>
  );
};

export default CasesTable;
