import React from "react";

import { formatModality, Modality } from "../models";
import TooltipText from "./TooltipText";

interface Props {
  readonly modalities: ReadonlyArray<Modality>;
}

const Modalities = ({ modalities }: Props) => {
  const text = modalities.map(formatModality).join(", ");
  return <TooltipText text={text}>{text}</TooltipText>;
};

export default Modalities;
