import { Box, Icon, TextInput } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";

const ReasonForChangeStyled = styled(Box)`
  position: relative;
`;

interface Props {
  readonly rkey: string | undefined;
  readonly onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly placeholder?: string;
  readonly value?: string;
  // allow for passing other blasterjs props, whatever they may be since no types exist for blaster
  readonly [key: string]: any;
}

const ReasonForChange = ({ rkey, onChange, value, placeholder, ...otherProps }: Props) => {
  return (
    <ReasonForChangeStyled key={rkey} {...otherProps}>
      <Icon
        name="reasonForChange"
        style={{ position: "absolute", top: "1.2rem", left: "1.2rem", zIndex: "1" }}
      />
      <TextInput
        placeholder={placeholder || "Reason for change"}
        value={value || ""}
        onChange={onChange}
        style={{ paddingLeft: "4rem" }}
      />
    </ReasonForChangeStyled>
  );
};

export default ReasonForChange;
