import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateFilter, ReportStudyData, ReportUserData } from "../models";
import { WriteResource } from "../types";
import { downloadReportCsv } from "../api";
import { RootState } from "../store";

export type ReportType =
  | "image-details"
  | "hpf-annotations"
  | "celiac-annotations"
  | "query"
  | "access";

export type ReportState = {
  [reportType in ReportType]: WriteResource<
    {
      readonly studyData?: ReportStudyData;
      readonly userData?: ReportUserData;
      readonly dateFilter?: DateFilter;
    } | null,
    void
  >;
};

export const initialState: ReportState = {
  "image-details": { data: null },
  "hpf-annotations": { data: null },
  "celiac-annotations": { data: null },
  query: { data: null },
  access: { data: null }
};

export interface SetReportStudyDataParams {
  reportType: ReportType;
  studyData: ReportStudyData;
}

export interface SetReportUserDataParams {
  reportType: ReportType;
  userData: ReportUserData;
}

export interface SetReportDateFilterParams {
  reportType: ReportType;
  dateFilter: DateFilter;
}

// thunks
export const requestReportCsv = createAsyncThunk(
  "studies/requestReportCsv",
  async (reportType: ReportType, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await downloadReportCsv(
      reportType,
      state.reports[reportType].data?.studyData || null,
      state.reports[reportType].data?.userData || null,
      state.reports[reportType].data?.dateFilter
    );
    return response;
  }
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setReportStudyData: (state, action: PayloadAction<SetReportStudyDataParams>) => {
      state[action.payload.reportType].data = {
        ...state[action.payload.reportType].data,
        studyData: action.payload.studyData
      };
    },
    setReportUserData: (state, action: PayloadAction<SetReportUserDataParams>) => {
      state[action.payload.reportType].data = {
        ...state[action.payload.reportType].data,
        userData: action.payload.userData
      };
    },
    setReportDateFilter: (state, action: PayloadAction<SetReportDateFilterParams>) => {
      state[action.payload.reportType].data = {
        ...state[action.payload.reportType].data,
        dateFilter: action.payload.dateFilter
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(requestReportCsv.pending, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        isPending: true
      };
    });
    builder.addCase(requestReportCsv.fulfilled, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        resource: void 0
      };
    });
    builder.addCase(requestReportCsv.rejected, (state, action) => {
      state[action.meta.arg] = {
        ...state[action.meta.arg],
        errorMessage: action.error.message
      };
    });
  }
});

export const { setReportStudyData, setReportUserData, setReportDateFilter } = reportsSlice.actions;
export default reportsSlice.reducer;
