import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Image,
  AddReasonForChange,
  NestValues,
  onlyUpdates,
  UUID,
  UpdateValueWithReasonForChange
} from "../models";
import { WriteResource } from "../types";
import { RootState } from "../store";
import { updateImageApi } from "../api";
import { refreshImage, SelectedAnnotationType } from "./caseImageViewer";
import { imagesFetch } from "./images";

export type ImageMetadataForm = AddReasonForChange<
  NestValues<{
    readonly name: string | null;
    readonly accessionNumber: string | null;
    readonly biopsyLocation: string | null;
  }>
>;

export type ImageDialogState =
  | {
      readonly isOpen: true;
      readonly savedImage: Image;
      readonly image: WriteResource<ImageMetadataForm, Image>;
      readonly refreshSingleImageOnSuccess: boolean;
    }
  | {
      readonly isOpen: false;
    };

export type OpenImageDialogParams = {
  readonly image: Image;
  readonly refreshSingleImageOnSuccess: boolean;
};

export type BeginDroppingAnnotationParams = {
  selectedAnnotationType: SelectedAnnotationType;
  imageId: UUID;
};

// converst the reason for change property to nullable
// migrating from redux-loop types checks parameters now so these little issues have come up
// that need fixing
export function convertUpdatedValueToNullableRFC(
  v: String | null | undefined,
  rfc?: string | undefined
): UpdateValueWithReasonForChange<string | null> | undefined {
  if (v === undefined) {
    return undefined;
  } else {
    return {
      value: v,
      reasonForChange: rfc === undefined ? null : rfc
    } as UpdateValueWithReasonForChange<string | null>;
  }
}

// thunks
export const updateImage = createAsyncThunk(
  "imageDialog/updateImage",
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state = getState() as RootState;

    const updates = state.imageDialog.isOpen
      ? onlyUpdates(
          {
            name: {
              value: state.imageDialog.savedImage.name
            },
            accessionNumber: {
              value: state.imageDialog.savedImage.accessionNumber
            },
            biopsyLocation: {
              value: state.imageDialog.savedImage.biopsyLocation
            }
          },
          state.imageDialog.image.data
        )
      : null;

    if (state.imageDialog.isOpen && updates) {
      const response = await updateImageApi(
        state.imageDialog.savedImage.id,
        convertUpdatedValueToNullableRFC(updates.name?.value, updates.name?.reasonForChange),
        convertUpdatedValueToNullableRFC(
          updates.accessionNumber?.value,
          updates.accessionNumber?.reasonForChange
        ),
        convertUpdatedValueToNullableRFC(
          updates.biopsyLocation?.value,
          updates.biopsyLocation?.reasonForChange
        )
      );
      if ("savedImage" in state.imageDialog) {
        if (
          "refreshSingleImageOnSuccess" in state.imageDialog &&
          state.imageDialog.refreshSingleImageOnSuccess
        ) {
          await dispatch(refreshImage({ imageId: state.imageDialog.savedImage.id }));
        } else {
          if (state.imageDialog.savedImage.studyId) {
            await dispatch(imagesFetch(state.imageDialog.savedImage.studyId));
          }
        }
        dispatch(resetState());
      }

      return response;
    }
  }
);

export const imageDialogSlice = createSlice({
  name: "imageDialog",
  initialState: { isOpen: false } as ImageDialogState,
  reducers: {
    resetState: () => {
      return { isOpen: false } as ImageDialogState;
    },
    openImageDialog: (state, action: PayloadAction<OpenImageDialogParams>) => {
      return {
        isOpen: true,
        savedImage: action.payload.image,
        image: {
          data: {
            name: {
              value: action.payload.image.name
            },
            accessionNumber: {
              value: action.payload.image.accessionNumber
            },
            biopsyLocation: {
              value: action.payload.image.biopsyLocation
            }
          }
        },
        refreshSingleImageOnSuccess: action.payload.refreshSingleImageOnSuccess
      };
    },
    closeImageDialog: state => {
      state.isOpen = false;
    },
    editImage: (state, action: PayloadAction<ImageMetadataForm>) => {
      if (state.isOpen) {
        state.image.data = action.payload;
      }
    }
  }
});

export const {
  resetState,
  openImageDialog,
  closeImageDialog,
  editImage
} = imageDialogSlice.actions;
export default imageDialogSlice.reducer;
