import React from "react";
import { Box, Callout } from "@blasterjs/core";
import Page, { PageHeader, PageHeading } from "../components/Page";

const Welcome = () => (
  <Page>
    <PageHeader>
      <PageHeading>Welcome</PageHeading>
    </PageHeader>
    <Box style={{ padding: "0 2rem 4rem" }}>
      <Callout>Please see an administrator to finish registering your account.</Callout>
    </Box>
  </Page>
);

export default Welcome;
