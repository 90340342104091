import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Callout,
  Dialog,
  Intent,
  Select as BlasterSelect,
  Label,
  Text
} from "@blasterjs/core";

import ConfirmationDialog from "../components/ConfirmationDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { useAppDispatch, useAppSelector } from "../hooks";
import { studiesFetch, studyFetch } from "../slices/studies";
import {
  addSelectedCase,
  clearSelectedCase,
  closeMoveImageDialog,
  loadCaseRequest,
  moveImage,
  searchCasesRequest,
  setMoveImageForm,
  setMoveType
} from "../slices/moveImageDialog";
import { AdminCase, CaseSummary, ImageStatusType, StudyListView } from "../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Select, { SelectOfType } from "../components/Select";
import { imagesFetch } from "../slices/images";

library.add(faTriangleExclamation);

const SelectCases = Select as SelectOfType<CaseSummary>;

export function caseLabel(theCase: CaseSummary): string {
  return theCase.label;
}

const MoveImageDialog = () => {
  const dispatch = useAppDispatch();
  const studies = useAppSelector(state => state.studies.studies);
  const study = useAppSelector(state => state.studyConfiguration);
  const moveImageDialog = useAppSelector(state => state.moveImageDialog);

  useEffect(() => {
    dispatch(studiesFetch());
    if (moveImageDialog.isOpen && moveImageDialog.image.data.studyId) {
      dispatch(studyFetch(moveImageDialog.image.data.studyId));
    }
    if (moveImageDialog.isOpen && moveImageDialog.savedImage.caseId) {
      dispatch(loadCaseRequest(moveImageDialog.savedImage.caseId));
    }
    // eslint-disable-next-line
  }, []);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const closeDialog = () => {
    dispatch(closeMoveImageDialog());
  };
  const onSelectMoveType = (e: React.ChangeEvent<HTMLInputElement>) => {
    moveImageDialog.isOpen && dispatch(setMoveType(e.target.value));
  };
  const onSelectStudy = (e: React.ChangeEvent<HTMLInputElement>) => {
    moveImageDialog.isOpen &&
      dispatch(
        setMoveImageForm({
          ...moveImageDialog.image.data,
          studyId: e.target.value
        })
      );
  };

  const onChangeReasonForChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    moveImageDialog.isOpen &&
      dispatch(
        setMoveImageForm({
          ...moveImageDialog.image.data,
          reasonForChange: e.target.value
        })
      );
  };
  const onSave = () => {
    setConfirmationDialogOpen(true);
  };

  const onConfirm = () => {
    setConfirmationDialogOpen(false);
    dispatch(moveImage()).then(
      () => study.existingStudy && dispatch(imagesFetch(study.existingStudy.study.id))
    );
  };

  const onCancel = () => {
    setConfirmationDialogOpen(false);
  };

  const errorText =
    moveImageDialog.isOpen && "errorMessage" in moveImageDialog.image ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{moveImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;
  const imageStudyId =
    moveImageDialog.isOpen && moveImageDialog.savedImage && moveImageDialog.savedImage.studyId;
  const moveTypeSelect = moveImageDialog.isOpen ? (
    <BlasterSelect onChange={onSelectMoveType} defaultValue={moveImageDialog.moveType}>
      <option key="study_key" value="study">
        Study
      </option>
      <option key="case_key" disabled={!imageStudyId} value="case">
        Case
      </option>
    </BlasterSelect>
  ) : null;
  const imageName: string =
    (moveImageDialog.isOpen && moveImageDialog.savedImage && moveImageDialog.savedImage.name) || "";
  const sponsor: string = (study.study && study.study.data?.sponsor.value) || "";
  const studySelect =
    "resource" in studies && moveImageDialog.isOpen ? (
      <BlasterSelect onChange={onSelectStudy}>
        <option key="" value="" />
        {studies.resource.map(study => {
          const isCurrentStudy =
            moveImageDialog.savedImage &&
            moveImageDialog.savedImage.studyId === study.studyView.study.id;
          return (
            <option
              key={study.studyView.study.id}
              value={study.studyView.study.id}
              disabled={isCurrentStudy}
            >
              {study.studyView.study.name}
            </option>
          );
        })}
      </BlasterSelect>
    ) : null;

  const onCaseSearchTextChange = (value: string) => {
    return (
      study.existingStudy &&
      dispatch(searchCasesRequest({ studyId: study.existingStudy.study.id, searchText: value }))
    );
  };
  const onSelectCase = (theCase: CaseSummary) => {
    dispatch(addSelectedCase(theCase));
  };
  const onDeselectCase = () => {
    dispatch(clearSelectedCase());
  };

  const caseSelect = moveImageDialog.isOpen ? (
    <Box display="flex">
      <SelectCases
        placeholder={"Search Cases"}
        searchText={moveImageDialog.casesSearchText}
        searchResults={
          "resource" in moveImageDialog.casesSearchResults
            ? moveImageDialog.casesSearchResults.resource
            : []
        }
        onSelect={onSelectCase}
        onChangeSearchText={onCaseSearchTextChange}
        onDeselect={onDeselectCase}
        selectedItems={moveImageDialog.selectedCases}
        isLoading={
          "isPending" in moveImageDialog.casesSearchResults &&
          moveImageDialog.casesSearchResults.isPending
        }
        format={caseLabel}
      />
    </Box>
  ) : null;

  const confirmDialog = (
    <ConfirmationDialog
      title="Confirm Image Move"
      message="Are you sure you want to move this image?"
      isOpen={isConfirmationDialogOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
  const studyName: string = (study.study && study.study.data?.name.value) || "";
  const selectedStudy: StudyListView | undefined =
    ("resource" in studies &&
      moveImageDialog.isOpen &&
      studies.resource.find(
        study => study.studyView.study.id == moveImageDialog.image.data.studyId
      )) ||
    undefined;
  const moveStudy = moveImageDialog.isOpen && moveImageDialog.moveType == "study";
  const newStudyName: string = (selectedStudy && selectedStudy.studyView.study.name) || "";
  const newSponsor: string = (selectedStudy && selectedStudy.studyView.study.sponsor) || "";
  const studyNameMessage =
    moveStudy && selectedStudy ? (
      <li>
        Study Name will change from <b>{studyName}</b> to <b>{newStudyName}</b>
      </li>
    ) : null;
  const sponsorMessage =
    moveStudy && selectedStudy ? (
      <li>
        Sponsor will change from <b>{sponsor}</b> to <b>{newSponsor}</b>
      </li>
    ) : null;
  const moveCase = moveImageDialog.isOpen && moveImageDialog.moveType == "case";
  const selectedCase =
    moveImageDialog.isOpen &&
    moveImageDialog.moveType == "case" &&
    moveImageDialog.selectedCases.length &&
    moveImageDialog.selectedCases[0];

  const subjectId =
    moveImageDialog.isOpen &&
    moveImageDialog.imagesCase &&
    "resource" in moveImageDialog.imagesCase &&
    (moveImageDialog.imagesCase.resource.caseWithStatus as AdminCase).subjectId;
  const newSubjectId = selectedCase && selectedCase.subjectId;
  const subjectIdMessage =
    moveCase && selectedCase ? (
      <li>
        Subject ID will change from <b>{subjectId}</b> to <b>{newSubjectId}</b>
      </li>
    ) : null;

  const visitId =
    moveImageDialog.isOpen &&
    moveImageDialog.imagesCase &&
    "resource" in moveImageDialog.imagesCase &&
    (moveImageDialog.imagesCase.resource.caseWithStatus as AdminCase).visitId;
  const newVisitId = selectedCase && selectedCase.visitId;
  const visitIdMessage =
    moveCase && selectedCase ? (
      <li>
        Visit ID will change from <b>{visitId}</b> to <b>{newVisitId}</b>
      </li>
    ) : null;

  const siteId =
    moveImageDialog.isOpen &&
    moveImageDialog.imagesCase &&
    "resource" in moveImageDialog.imagesCase &&
    (moveImageDialog.imagesCase.resource.caseWithStatus as AdminCase).siteId;
  const newSiteId = selectedCase && selectedCase.siteId;
  const siteIdMessage =
    moveCase && selectedCase ? (
      <li>
        Site ID will change from <b>{siteId}</b> to <b>{newSiteId}</b>
      </li>
    ) : null;

  const caseStatusMessage =
    moveImageDialog.isOpen &&
    moveImageDialog.moveType == "case" &&
    moveImageDialog.selectedCases.length
      ? `Case status ${moveImageDialog.selectedCases[0]?.caseStatus}`
      : null;

  const imageStatus = moveImageDialog.isOpen && moveImageDialog.savedImage.status;

  function imageStateLegalForStudyMove(): boolean {
    if (moveImageDialog.isOpen) {
      return !(
        imageStatus == ImageStatusType.ReadyForReading || imageStatus == ImageStatusType.Complete
      );
    } else return false;
  }

  const imageStatusMessage = moveImageDialog.isOpen ? (
    !(
      imageStatus == ImageStatusType.ReadyForReading || imageStatus == ImageStatusType.Complete
    ) ? null : (
      <li> {`Can not move image in status ${imageStatus}`} </li>
    )
  ) : null;

  function imageStateLegalForCaseMove(): boolean {
    if (moveImageDialog.isOpen) {
      return !(
        imageStatus == ImageStatusType.ReadyForReading || imageStatus == ImageStatusType.Complete
      );
    } else return false;
  }

  const messageText =
    moveImageDialog.isOpen &&
    ((moveImageDialog.moveType == "study" && (studyNameMessage || sponsorMessage)) ||
      caseStatusMessage) ? (
      <Box
        style={{
          borderRadius: "8px",
          backgroundColor: "rgba(227, 43, 36, 0.20)",
          padding: "6px",
          marginTop: 12,
          display: "flex"
        }}
      >
        <div style={{ marginRight: "8px" }}>
          <FontAwesomeIcon
            style={{ color: "red", fontSize: "1.2em" }}
            icon={faTriangleExclamation}
          />
        </div>
        <div>
          <span>WARNING! The following data points will be updated.</span>
          <ul>
            {imageStatusMessage}
            {studyNameMessage}
            {sponsorMessage}
            {caseStatusMessage}
            {subjectIdMessage}
            {visitIdMessage}
            {siteIdMessage}
          </ul>
        </div>
      </Box>
    ) : null;
  const moveButtonEnabled = moveImageDialog.isOpen
    ? moveImageDialog.image.data.reasonForChange &&
      ((moveImageDialog.moveType == "study" &&
        moveImageDialog.image.data.studyId &&
        imageStateLegalForStudyMove()) ||
        (moveImageDialog.moveType == "case" &&
          moveImageDialog.selectedCases.length &&
          imageStateLegalForCaseMove()))
    : false;
  return (
    <>
      <Dialog
        isOpen={moveImageDialog.isOpen && !isConfirmationDialogOpen}
        onRequestClose={closeDialog}
        appElementId="root"
      >
        <DialogHeader title="Move Image" closeDialog={closeDialog} />
        <DialogBody>
          {errorText}
          <Box>
            Would you like to move image <b>{imageName}</b> to a Study or Case?
          </Box>
          <Box>{moveTypeSelect}</Box>
          {moveImageDialog.isOpen && moveImageDialog.moveType == "study" ? (
            <>
              <Box style={{ marginTop: 12 }}>What study would you like to move the image to?</Box>
              <Box>{studySelect}</Box>
            </>
          ) : (
            <Label>
              <Box style={{ marginTop: 12 }}>What case would you like to move the image to?</Box>
              {caseSelect}
            </Label>
          )}
          <Box style={{ marginTop: 12 }}>
            <Box>Reason For Change</Box>
            <ReasonForChange
              rkey="moveImage_reason"
              onChange={onChangeReasonForChange}
              value={(moveImageDialog.isOpen && moveImageDialog.image.data.reasonForChange) || ""}
            />
          </Box>
          {messageText}
        </DialogBody>
        <DialogFooter>
          <Box>
            <Button
              onClick={onSave}
              disabled={!moveButtonEnabled}
              appearance="prominent"
              intent="primary"
              isLoading={
                moveImageDialog.isOpen &&
                "isPending" in moveImageDialog.image &&
                moveImageDialog.image.isPending
              }
            >
              Move image
            </Button>
          </Box>
          <Box>
            <Button onClick={closeDialog}>Cancel</Button>
          </Box>
        </DialogFooter>
      </Dialog>
      {confirmDialog}
    </>
  );
};

export default MoveImageDialog;
