import L, { LatLngLiteral } from "leaflet";
import { Text as BlasterText } from "@blasterjs/core";
import { GeoJSON, Popup } from "react-leaflet";
import React from "react";
import { UUID } from "../models";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";

interface EllipseProps {
  readonly text: string | null;
  readonly date: string;
  readonly radii: readonly [number, number];
  readonly tilt: number;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  dispatch: AppDispatch;
}

type EllipseWithPopupProps = EllipseProps & {
  readonly isClickable: boolean;
  readonly canDelete: boolean;
};

const EllipseAnnotationWithPopup = ({
  text,
  date,
  annotationId,
  position,
  radii,
  tilt,
  canDelete,
  dispatch
}: EllipseProps & EllipseWithPopupProps) => {
  const ellipse = new L.Ellipse({
    center: position,
    semiMinor: radii[0],
    semiMajor: radii[1],
    bearing: tilt
  });

  // Need to convert the ellipse to a React Leaflet geojson
  return (
    <GeoJSON
      key={annotationId}
      data={ellipse.toGeoJSON()}
      style={{ color: "black", fillOpacity: 0, weight: 3 }}
    >
      <Popup closeButton={false} className="annotation-popup">
        <AnnotationTooltip annotationId={annotationId} canDelete={canDelete} dispatch={dispatch}>
          <div style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem", lineHeight: "1" }}>
            <BlasterText fontSize="10pt" fontWeight="bold">
              {text}
            </BlasterText>
            <br />
            <BlasterText fontSize="8pt">{date}</BlasterText>
          </div>
        </AnnotationTooltip>
      </Popup>
    </GeoJSON>
  );
};

export default EllipseAnnotationWithPopup;
