import { css } from "styled-components";

interface ButtonProps {
  readonly appearance: string;
  readonly borderRadius: number;
  readonly disabled: boolean;
}

interface SelectProps {
  readonly borderRadius: number;
}

interface TextInputProps {
  readonly borderRadius: number;
}

const borderRadius = "2px";

const colors = {
  primary: "#57B7B3",
  primaryTint: "#C3E6E4",
  primaryShade: "#499996",
  secondary: "#215A96",
  secondaryTint: "#215A96",
  secondaryShade: "#215A96",
  gray100: "#F0F2F5",
  gray200: "#DDE0E6",
  gray300: "#D0D3D9",
  gray400: "#B7BABF",
  gray500: "#9FA1A6",
  gray600: "#727779",
  gray700: "#6E7073",
  gray800: "#565759",
  gray900: "#3D3E40",
  white: "#FFFFFF",
  offWhite: "#F4F5F7",
  black: "#000000",
  green: "#91C66A",
  greenTint: "#C4E8A9",
  greenShade: "#5B833E",
  red: "#DC3831",
  redTint: "#F8B2B8",
  redShade: "#941D27",
  yellow: "#ECBC40",
  yellowTint: "#FFE9AF",
  yellowShade: "#C19523",
  textBase: "#231F20",
  textMedium: "#574D4F",
  textLight: "#86888C",
  link: "#215A96",
  linkHover: "#2C79C9",
  success: "#91C66A",
  successTint: "#C4E8A9",
  successShade: "#5B833E",
  danger: "#E0535F",
  dangerTint: "#F8B2B8",
  dangerShade: "#941D27",
  warning: "#ECBC40",
  warningTint: "#FFE9AF",
  warningShade: "#C19523",
  a: {
    color: "#215A96",
    colorHover: "#2C79C9"
  },
  badge: {
    background: "#6E779B",
    color: "#FFFFFF"
  },
  brand: {
    color: "#005A9B"
  },
  breadcrumbs: {
    color: "#959CB6",
    colorHover: "#738FFC",
    colorSeparator: "#E0E5F5",
    colorHighlight: "#2A304D"
  },
  card: {
    background: "#FFFFFF",
    borderColor: "#E0E5F5"
  },
  checkbox: {
    checkedColor: "#738FFC",
    uncheckedColor: "#F2F5FF",
    indeterminateColor: "#738FFC"
  },
  dialog: {
    bg: "red"
  },
  divider: {
    horizontal: "#C3CADF",
    vertical: "#C3CADF"
  },
  heading: {
    color: "#231F20"
  },
  navBar: {
    bg: "#464F77"
  },
  progressBar: {
    bg: "#E0E5F5",
    color: "#738FFC"
  },
  radio: {
    checkedColor: "#738FFC",
    uncheckedColor: "#6f7073"
  },
  table: {
    borderColor: "#E0E5F5",
    stripeColor: "#E0E5F5",
    hoverColor: "#738FFC"
  },
  textInput: {
    bg: "#FFFFFF",
    color: "#2A304D",
    borderColor: "#2A304D"
  },
  tooltip: {
    color: "#000000",
    bg: "#E0E5F5"
  },
  avatars: [
    "#083763",
    "#274F13",
    "#FF7537",
    "#E6AB02",
    "#B99BFF",
    "#C49869",
    "#7C7D80",
    "#4986E7",
    "#15A765",
    "#EF1D23",
    "#FFAE47",
    "#5A04B7",
    "#7E4B00",
    "#4B4B4D"
  ]
};

const fonts = {
  body: "InterVariable",
  display: "InterVariable",
  code: "'Source Code Pro', monospace",
  heading: {
    fontFamily: "InterVariable"
  },
  text: {
    fontFamily: "InterVariable"
  }
};

const fontSizes: ReadonlyArray<string> = [
  "1rem",
  "1.4rem",
  "1.6rem",
  "1.8rem",
  "2.2rem",
  "2.8rem",
  "3.2rem",
  "3.7rem"
];

const select = {
  styles: css<SelectProps>`
    white-space: nowrap;
    border-radius: ${borderRadius};
    border: none;
    font-weight: 400;
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
    box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
    height: 3.8rem;
    line-height: 1.5;
  `
};

const text = {
  styles: css<SelectProps>`
    font-size: ${fontSizes[2]};
  `
};

const table = {
  styles: css<SelectProps>`
    td {
      padding: 0 0 0.2rem 0;
      vertical-align: top;
    }
  `
};

const checkbox = {
  colors: {
    uncheckedColor: colors.gray600
  },
  styles: css`
    position: relative;
  `
};

const radio = {
  colors: {
    uncheckedColor: colors.gray600
  }
};

const label = {
  styles: css`
    line-height: 2;
    font-weight: 500;
  `
};

const textInput = {
  styles: css<TextInputProps>`
    box-shadow: inset 0px 1px 1px 0px rgba(16, 22, 26, 0.2),
      inset 0px 0px 0px 1px rgba(16, 22, 26, 0.15);
    border-width: 0;
    border-radius: ${borderRadius};
    position: relative;
    height: 3.8rem;
  `
};

const dialog = {
  closeTimeoutMS: 2000,
  minWidths: {
    minWidth: "500px"
  },
  shadows: {
    boxShadow:
      "0 0 0 1px rgba(16, 22, 26, 0.1), 0 4px 8px rgba(16, 22, 26, 0.2),0 15px 45px 6px rgba(16, 22, 26, 0.2)"
  },
  styles: css`
    outline: none !important;
    display: flex;
    flex-direction: column;
  `
};

const button = {
  base: {
    colors: {
      defaultBg: "rgb(245, 248, 250)"
    }
  },
  intents: {
    colors: {
      default: colors.textMedium
    }
  },

  /* eslint-disable @typescript-eslint/no-unused-vars */
  styles: css<ButtonProps>`
    white-space: nowrap;
    border-radius: ${borderRadius};
    border: none;
    font-weight: 500;
    line-height: 1.5;
    transition: none;
    ${props => css`
      display: inline-flex;
      & > span {
        display: flex;
        align-items: center;
      }
    `}
    ${props =>
      props.appearance === "minimal" &&
      css`
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 1rem;
        padding-right: 1rem;
        &:hover,
        &:focus&:active {
          background-color: rgba(0, 0, 0, 0.1);
        }
      `}
    ${props =>
      (props.appearance === "default" || props.appearance === null) &&
      css`
        background-color: rgb(245, 248, 250);
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
        box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
        color: ${props.disabled ? colors.gray400 : colors.gray900};
      `}
    ${props =>
      props.appearance === "prominent" &&
      css`
        background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
        box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
      `}
  `
  /* eslint-enable @typescript-eslint/no-unused-vars */
};

const callout = {
  styles: css`
    margin-bottom: 1.2rem;
    box-shadow: none;
  `
};

const heading = {
  styles: css`
    font-weight: 700;
  `
};

const space: ReadonlyArray<string> = [
  "0",
  "0.8rem",
  "1.6rem",
  "2.4rem",
  "3.2rem",
  "4rem",
  "4.8rem",
  "5.6rem",
  "6.4rem",
  "7.2rem",
  "8rem"
];

const lineHeights = {
  inherit: "inherit",
  small: "1",
  base: "1.5",
  large: "1.8",
  badge: {
    lineHeight: "1"
  },
  heading: {
    lineHeight: "1.8"
  },
  text: {
    lineHeight: "1.5"
  }
};

const fontWeights = {
  base: 400,
  bold: 700,
  badge: {
    fontWeight: 400
  },
  heading: {
    fontWeight: "700"
  },
  button: {
    borderRadius: "200"
  }
};

const widths = {
  sidebar: {
    width: "34rem"
  }
};

const heights = {
  progressBar: {
    height: "8px"
  },
  appHeader: {
    height: "5.6rem"
  }
};

const tooltip = {
  styles: css`
    background: hsl(0, 0%, 100%);
    border-radius: 0;
    padding: 4px 0px;
    font-weight: ${fontWeights.base}
    box-shadow: 0 0 0 1px rgba(16,22,26,.1), 0 2px 4px rgba(16,22,26,.2), 0 8px 24px rgba(16,22,26,.2);
  `
};

export default {
  button,
  callout,
  select,
  heading,
  textInput,
  checkbox,
  radio,
  label,
  tooltip,
  colors,
  text,
  table,
  dialog,
  fonts,
  fontSizes,
  space,
  lineHeights,
  fontWeights,
  widths,
  heights,
  icons: {
    reasonForChange: {
      title: "reasonForChange",
      viewBox: "0 0 512 512",
      path:
        "M448 0H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h96v84c0 9.8 11.2 15.5 19.1 9.7L304 416h144c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64zM215.4 310.6l-48.2 5.4c-6.5.7-11.9-4.8-11.2-11.2l5.4-48.2 96.3-96.3 54 54-96.3 96.3zm150.7-150.7l-31.8 31.8-54-54 31.8-31.8c7.9-7.9 20.7-7.9 28.6 0l25.4 25.4c7.9 7.9 7.9 20.7 0 28.6z"
    },
    clipboard: {
      title: "clipboard",
      viewBox: "0 0 384 512",
      path:
        "M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 40c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm144 418c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V118c0-3.3 2.7-6 6-6h42v36c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12v-36h42c3.3 0 6 2.7 6 6z"
    },
    cross: {
      title: "cross",
      viewBox: "0 0 320 512",
      path:
        "M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
    },
    check: {
      title: "check",
      viewBox: "0 0 512 512",
      path:
        "M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
    },
    keyboard: {
      title: "keyboard",
      viewBox: "0 0 576 512",
      path:
        "M528 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h480c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM128 180v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-336 96v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm288 0v-40c0-6.627-5.373-12-12-12H172c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h232c6.627 0 12-5.373 12-12zm96 0v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12z"
    },
    photo: {
      title: "photo",
      viewBox: "0 0 512 512",
      path:
        "M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"
    },
    text: {
      title: "text",
      viewBox: "0 0 448 448",
      path:
        "m432 0c8.836556 0 16 7.163444 16 16v96c0 8.836556-7.163444 16-16 16h-32c-8.836556 0-16-7.163444-16-16v-32h-120v304h40c8.836556 0 16 7.163444 16 16v32c0 8.836556-7.163444 16-16 16h-160c-8.836556 0-16-7.163444-16-16v-32c0-8.836556 7.163444-16 16-16h40v-304h-120v32c0 8.836556-7.163444 16-16 16h-32c-8.836556 0-16-7.163444-16-16v-96c0-8.836556 7.163444-16 16-16z"
    },
    circle: {
      title: "circle",
      viewBox: "0 0 486 448",
      path:
        "m243 0c-134.237903 0-243 100.258065-243 224s108.762097 224 243 224 243-100.258065 243-224-108.762097-224-243-224z"
    },
    ellipse: {
      title: "ellipse",
      viewBox: "0 0 90 90",
      path:
        "M44.166,75.062C19.812,75.062,0,61.202,0,44.167C0,27.13,19.812,13.27,44.166,13.27c24.354,0,44.166,13.859,44.166,30.896 C88.332,61.204,68.52,75.062,44.166,75.062z M44.166,16.27C21.467,16.27,3,28.784,3,44.167c0,15.381,18.467,27.896,41.166,27.896 s41.166-12.515,41.166-27.896C85.332,28.785,66.865,16.27,44.166,16.27z"
    },
    plus: {
      title: "plus",
      viewBox: "0 0 11 11",
      path:
        "m5.87498838 9.99997854c.20718334 0 .3749991-.16781576.3749991-.3749991v-3.37499196h3.37499196c.20718334 0 .3749991-.16781576.3749991-.3749991v-.74999821c0-.20718335-.16781576-.37499911-.3749991-.37499911h-3.37499196v-3.37499195c0-.20718335-.16781576-.37499911-.3749991-.37499911h-.74999821c-.20718335 0-.37499911.16781576-.37499911.37499911v3.37499195h-3.37499195c-.20718335 0-.37499911.16781576-.37499911.37499911v.74999821c0 .20718334.16781576.3749991.37499911.3749991h3.37499195v3.37499196c0 .20718334.16781576.3749991.37499911.3749991z"
    },
    minus: {
      title: "minus",
      viewBox: "0 0 11 11",
      path:
        "m9.62497944 6.5c.20718334 0 .3749991-.16781934.3749991-.37500268v-.74999821c0-.20718335-.16781576-.37499911-.3749991-.37499911h-8.24998033c-.20718335 0-.37499911.16781576-.37499911.37499911v.74999821c0 .20718334.16781576.37500268.37499911.37500268z"
    },
    bullseye: {
      title: "bullseye",
      viewBox: "0 0 496 512",
      path:
        "M242.16 240.67L27.98 301.55c-15.17 4.31-16.95 25.1-2.73 31.92l68.47 32.89-89.17 89.17c-6.07 6.06-6.07 15.9 0 21.96l21.96 21.96c6.07 6.06 15.9 6.06 21.96 0l89.17-89.17 32.89 68.47c6.83 14.22 27.61 12.44 31.92-2.73l60.87-214.18c3.68-12.91-8.25-24.83-21.16-21.17zm27.36 117.03l-14.08 49.55C335.92 403.3 400 337.46 400 256c0-84.02-68-152-152-152-81.47 0-147.3 64.1-151.25 144.57l49.55-14.08C156.25 187.44 198.04 152 248 152c57.35 0 104 46.65 104 104 0 49.96-35.44 91.75-82.48 101.7zM248 8C111.03 8 0 119.03 0 256c0 7.3.47 14.49 1.09 21.63 3.46-1.97 7-3.87 10.99-5l36.24-10.3c-.07-2.12-.32-4.19-.32-6.33 0-110.28 89.72-200 200-200s200 89.72 200 200-89.72 200-200 200c-2.14 0-4.21-.25-6.33-.32l-10.3 36.24c-1.14 4.02-3.15 7.5-5.14 10.98 7.19.63 14.42 1.1 21.77 1.1 136.97 0 248-111.03 248-248S384.97 8 248 8z"
    },
    collapse: {
      title: "collapse",
      viewBox: "0 0 448 512",
      path:
        "M436 192H312c-13.3 0-24-10.7-24-24V44c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v100h100c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12zm-276-24V44c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v100H12c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 300V344c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h100v100c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12zm176 0V368h100c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H312c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12z"
    },
    expand: {
      title: "expand",
      viewBox: "0 0 448 512",
      path:
        "M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H48v100c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v24c0 6.6 5.4 12 12 12h100v100c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-24c-6.6 0-12 5.4-12 12v100H300c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-24c0-6.6-5.4-12-12-12H48V332c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"
    },
    queryResolved: {
      title: "query-resolved",
      viewBox: "0 0 17 15",
      path:
        "M7.414 0c1.131 0 2.204.257 3.164.716L9.237 1.738a5.917 5.917 0 00-1.823-.286c-3.304 0-5.98 2.706-5.98 6.048 0 3.344 2.675 6.048 5.98 6.048 2.686 0 4.955-1.787 5.71-4.251l1.016 1.362C12.96 13.223 10.392 15 7.414 15 3.319 15 0 11.643 0 7.5 0 3.36 3.32 0 7.414 0zm0 9.133c.692 0 1.255.57 1.255 1.27 0 .7-.563 1.27-1.255 1.27-.693 0-1.256-.57-1.256-1.27 0-.7.563-1.27 1.256-1.27zm.818-5.504a.36.36 0 01.355.305l.004.076-.204 4.113a.362.362 0 01-.288.338l-.07.007h-1.23a.36.36 0 01-.349-.275l-.01-.07-.203-4.113a.362.362 0 01.283-.373l.075-.008zm7.676-.64a.35.35 0 01.495 0l.494.496a.35.35 0 010 .495l-4.025 4.025a.35.35 0 01-.494 0L10.103 5.73a.35.35 0 010-.495l.494-.495a.35.35 0 01.495 0l1.533 1.532z"
    },
    queryUnresolveable: {
      title: "query-unresolveable",
      viewBox: "0 0 17 15",
      path:
        "M7.414 0c1.131 0 2.204.257 3.164.716L9.237 1.738a5.917 5.917 0 00-1.823-.286c-3.304 0-5.98 2.706-5.98 6.048 0 3.344 2.675 6.048 5.98 6.048 2.686 0 4.955-1.787 5.71-4.251l1.016 1.362C12.96 13.223 10.392 15 7.414 15 3.319 15 0 11.643 0 7.5 0 3.36 3.32 0 7.414 0zm0 9.133c.692 0 1.255.57 1.255 1.27 0 .7-.563 1.27-1.255 1.27-.693 0-1.256-.57-1.256-1.27 0-.7.563-1.27 1.256-1.27zm4.108-6.216a.533.533 0 01.76 0l1.71 1.73 1.71-1.73a.533.533 0 01.76 0l.38.385c.21.212.21.556 0 .769l-1.71 1.73 1.71 1.73c.21.212.21.556 0 .768l-.38.385a.533.533 0 01-.76 0l-1.71-1.73-1.71 1.73a.533.533 0 01-.76 0l-.38-.385a.548.548 0 010-.768l1.71-1.73-1.71-1.73a.548.548 0 010-.77zm-3.29.712a.36.36 0 01.355.305l.004.076-.204 4.113a.362.362 0 01-.288.338l-.07.007h-1.23a.36.36 0 01-.349-.275l-.01-.07-.203-4.113a.362.362 0 01.283-.373l.075-.008z"
    },
    query: {
      title: "query",
      viewBox: "0 0 18 15",
      path:
        "M9.5 15a7.5 7.5 0 100-15 7.501 7.501 0 000 15zm.734-6.532H8.766a.363.363 0 01-.362-.343l-.225-4.113a.363.363 0 01.363-.383h1.916c.209 0 .374.175.363.383l-.225 4.113a.363.363 0 01-.362.343zM9.5 11.794a1.391 1.391 0 110-2.782 1.391 1.391 0 010 2.782z"
    },
    processFail: {
      title: "process-fail",
      viewBox: "0 0 17 15",
      path:
        "M8.5 15c4.143 0 7.5-3.357 7.5-7.5S12.643 0 8.5 0A7.499 7.499 0 001 7.5C1 11.643 4.357 15 8.5 15zm-2.483-3.823L4.823 9.98a.363.363 0 010-.514L6.806 7.5 4.823 5.531a.363.363 0 010-.514L6.02 3.82a.363.363 0 01.514 0L8.5 5.806l1.969-1.983a.363.363 0 01.514 0L12.18 5.02a.363.363 0 010 .514L10.194 7.5l1.983 1.969a.363.363 0 010 .514l-1.197 1.194a.363.363 0 01-.514 0L8.5 9.194l-1.969 1.983a.363.363 0 01-.514 0z"
    },
    processSuccess: {
      title: "process-success",
      viewBox: "0 0 17 15",
      path:
        "M8.5 15a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm-1.552-3.529L3.803 8.326a.484.484 0 010-.684l.684-.685a.484.484 0 01.685 0l2.118 2.12 4.538-4.539a.484.484 0 01.685 0l.684.684a.484.484 0 010 .685L7.632 11.47a.484.484 0 01-.684 0z"
    },
    image: {
      title: "image",
      viewBox: "0 0 17 15",
      path:
        "M14.594 13C15.37 13 16 12.37 16 11.594V3.156c0-.776-.63-1.406-1.406-1.406H2.406C1.63 1.75 1 2.38 1 3.156v8.438C1 12.37 1.63 13 2.406 13zM4.28 6.672a1.64 1.64 0 110-3.281 1.64 1.64 0 010 3.28zm9.844 4.453H2.875V9.719L4.97 7.624a.352.352 0 01.497 0L7.094 9.25l3.97-3.97a.352.352 0 01.497 0l2.564 2.564z"
    },
    microscope: {
      title: "microscope",
      viewBox: "0 0 512 512",
      path:
        "M476 464h-40.5c37.06-33.68 60.5-82.1 60.5-136 0-98.75-78.26-179.36-176-183.6V97.14c0-18.39-10.16-34.45-25.16-42.88V36.58c0-20.17-16.4-36.58-36.56-36.58H157.72c-20.16 0-36.56 16.41-36.56 36.58v17.69C106.16 62.69 96 78.75 96 97.14v197.72c0 22.02 14.56 40.7 34.56 46.94v37.62c0 20.17 16.41 36.58 36.59 36.58h81.69c20.19 0 36.59-16.41 36.59-36.58V341.8c20-6.23 34.56-24.92 34.56-46.94V192.81c71.21 4.23 128 62.95 128 135.19 0 74.98-61 136-136 136H36c-19.88 0-36 16.12-36 36 0 6.63 5.37 12 12 12h488c6.63 0 12-5.37 12-12 0-19.88-16.12-36-36-36zm-297.44-96v-40h58.88v40h-58.88zm92.28-72H145.16l-1.16-1.14L145.16 96h24V48h77.69L248 96h24l-1.16 200z"
    }
  }
};

export const dataTableTheme = {
  header: {
    fontSize: "1.6rem",
    fontColor: colors.textLight,
    fontWeight: "300",
    height: "4.8rem"
  },
  rows: {
    fontSize: "1.6rem",
    fontColor: colors.black,
    borderWidth: "1px",
    borderColor: "rgb(226, 228, 233)",
    hoverBackgroundColor: "rgba(0, 0, 0, .04)",
    height: "4.8rem"
  },
  cells: {
    cellPadding: "4.8rem"
  }
};
