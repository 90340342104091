import React from "react";

import { Box, Button, Callout, Dialog, Intent, Select, Label, Text } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  closeWorkAsDialog,
  DialogMode,
  setSelectedRole,
  updateWorkAsRole
} from "../slices/workAsDialog";

const WorkAsDialog = () => {
  const dispatch = useAppDispatch();

  const dialogMode = useAppSelector(state => state.workAsDialog.mode);
  const availableRoles = useAppSelector(state => state.workAsDialog.availableRoles);
  const selectedRole = useAppSelector(state => state.workAsDialog.selectedRole);

  const closeDialog = () => {
    dispatch(closeWorkAsDialog());
  };
  const onSave = () => {
    if (selectedRole) {
      dispatch(updateWorkAsRole(selectedRole)).then(() => window.location.reload());
    }
    closeDialog();
  };

  const onRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const roleId = e.currentTarget.value;
    dispatch(setSelectedRole(roleId));
  };

  const errorText =
    "errorMessage" in availableRoles ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{availableRoles.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  return (
    <Dialog
      isOpen={
        dialogMode === DialogMode.OpenForEdit &&
        // Avoid flicker by waiting to open dialog until case has loaded
        !("isPending" in availableRoles)
      }
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      <DialogHeader title={"Work As..."} closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>
              {"resource" in availableRoles && availableRoles.resource.length > 0 ? (
                <span>What role would you like to choose?</span>
              ) : null}
              {"resource" in availableRoles && availableRoles.resource.length > 0 ? (
                <Select onChange={onRoleChange} value={selectedRole || ""}>
                  <option key="" value="">
                    Select a Role
                  </option>
                  {"resource" in availableRoles &&
                    availableRoles.resource.map(role => {
                      return (
                        <option key={role.id} value={role.id}>
                          {role.displayName}
                        </option>
                      );
                    })}
                </Select>
              ) : (
                "You do not have any other roles associated with your account."
              )}
            </Label>
          </Box>
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            intent="primary"
            appearance="prominent"
            onClick={onSave}
            isLoading={"isSaving" in availableRoles}
            disabled={!("resource" in availableRoles) || !(availableRoles.resource.length > 1)}
          >
            Save
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default WorkAsDialog;
