import L, { LatLngLiteral } from "leaflet";
import React from "react";
import { Text as BlasterText } from "@blasterjs/core";
import { Marker, Popup } from "react-leaflet";
import { UUID } from "../models";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";

interface Props {
  readonly text: string;
  readonly date: string;
  readonly position: LatLngLiteral;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  dispatch: AppDispatch;
}

const TextMarker = ({ text, date, position, annotationId, canDelete, dispatch }: Props) => {
  const myIcon = L.icon({
    iconAnchor: [12, 12],
    iconSize: [25, 25],
    iconUrl: "/images/icon-text.svg",
    popupAnchor: [0, -4]
  });

  return (
    <Marker icon={myIcon} position={position}>
      <Popup closeButton={false} className="annotation-popup">
        <AnnotationTooltip annotationId={annotationId} canDelete={canDelete} dispatch={dispatch}>
          <div style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem", lineHeight: "1" }}>
            <BlasterText fontSize="10pt" fontWeight="bold">
              {text}
            </BlasterText>
            <br />
            <BlasterText fontSize="8pt">{date}</BlasterText>
          </div>
        </AnnotationTooltip>
      </Popup>
    </Marker>
  );
};

export default TextMarker;
