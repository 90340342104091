import { Button } from "@blasterjs/core";
import React from "react";

import { ImageAndQuery } from "../models";
import UploadDialog from "../components/UploadDialog";
import { useAppDispatch, useAppSelector } from "../hooks";
import { openUploadDialog } from "../slices/uploadDialog";
import { userCanUpload } from "../permissions";

interface Props {
  readonly imageAndQuery: ImageAndQuery;
  readonly refreshCase?: true;
}

const ReplaceImage = ({ imageAndQuery, refreshCase }: Props) => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const studyId = imageAndQuery.image.studyId;

  const canUpload = userCanUpload(("resource" in loggedInUser && loggedInUser.resource) || null);
  const replaceImageButton =
    canUpload && studyId ? (
      <Button
        block={true}
        className="query-white-button"
        onClick={() =>
          dispatch(
            openUploadDialog({
              studyId: studyId,
              imageToReplace: imageAndQuery.image,
              refreshImageOnSuccessfulReplace: true,
              refreshCaseOnSuccessfulReplace: refreshCase
            })
          )
        }
      >
        Replace Image
      </Button>
    ) : null;
  const uploadDialog = canUpload ? <UploadDialog /> : null;
  return canUpload ? (
    <>
      {replaceImageButton}
      {uploadDialog}
    </>
  ) : null;
};

export default ReplaceImage;
