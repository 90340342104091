import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Image } from "../models";
import { WriteResource } from "../types";
import { RootState } from "../store";
import { deleteImage } from "../api";

export interface DeleteImageForm {
  readonly image: Image;
  readonly reasonForChange: string | null;
}

export type DeleteImageDialogState =
  | {
      readonly isOpen: true;
      readonly image: WriteResource<DeleteImageForm, Image>;
    }
  | {
      readonly isOpen: false;
    };

// thunks
export const imageDelete = createAsyncThunk(
  "deleteImageDialog/imageDelete",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.deleteImageDialog;

    if (state.isOpen) {
      await deleteImage({
        value: state.image.data.image.id,
        reasonForChange: state.image.data.reasonForChange || undefined
      });
    }
  }
);

export const deleteImageDialogSlice = createSlice({
  name: "deleteImageDialog",
  initialState: { isOpen: false } as DeleteImageDialogState,
  reducers: {
    openDeleteImageDialog: (state, action) => {
      return {
        isOpen: true,
        image: {
          data: {
            image: action.payload,
            reasonForChange: null
          }
        }
      };
    },
    closeDeleteImageDialog: state => {
      state.isOpen = false;
    },
    setDeleteImageForm: (state, action) => {
      if (state.isOpen) {
        state.image = {
          data: action.payload
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(imageDelete.fulfilled, () => {
      return { isOpen: false } as DeleteImageDialogState;
    });
    builder.addCase(imageDelete.rejected, (state, action) => {
      if (state.isOpen) {
        state.image = {
          data: state.image.data,
          errorMessage: action.error.message
        };
      }
    });
  }
});

export const {
  openDeleteImageDialog,
  closeDeleteImageDialog,
  setDeleteImageForm
} = deleteImageDialogSlice.actions;
export default deleteImageDialogSlice.reducer;
