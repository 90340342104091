import { Box, Button, Callout, Dialog, Intent, Select, Text } from "@blasterjs/core";
import React, { useState } from "react";

import { Ok } from "ts.data.json";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { caseStatusDecoder } from "../decoders";
import { CaseStatus, formatCaseStatus } from "../models";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  closeCaseStatusDialog,
  revertCaseStatus,
  setCaseStatusForm
} from "../slices/caseStatusDialog";

const CaseStatusDialog = () => {
  const dispatch = useAppDispatch();
  const caseStatusDialog = useAppSelector(state => state.caseStatusDialog);

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const closeDialog = () => {
    dispatch(closeCaseStatusDialog());
  };
  const onSelectCaseStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    const caseStatusResult = caseStatusDecoder.decode(e.target.value);
    caseStatusDialog.isOpen &&
      dispatch(
        setCaseStatusForm({
          ...caseStatusDialog.histoCase.data,
          status: caseStatusResult instanceof Ok ? caseStatusResult.value : CaseStatus.Invalid
        })
      );
  };
  const onChangeReasonForChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    caseStatusDialog.isOpen &&
      dispatch(
        setCaseStatusForm({
          ...caseStatusDialog.histoCase.data,
          reasonForChange: e.target.value
        })
      );
  };
  const onSave = () => {
    setConfirmationDialogOpen(true);
  };
  const onConfirm = () => {
    setConfirmationDialogOpen(false);
    dispatch(revertCaseStatus());
  };
  const onCancel = () => {
    setConfirmationDialogOpen(false);
  };
  const errorText =
    caseStatusDialog.isOpen && "errorMessage" in caseStatusDialog.histoCase ? (
      <Callout intent={Intent.DANGER}>
        <Text>{caseStatusDialog.histoCase.errorMessage}</Text>
      </Callout>
    ) : null;

  const caseStatusSelect = caseStatusDialog.isOpen ? (
    <Select onChange={onSelectCaseStatus} defaultValue={caseStatusDialog.savedCase.status}>
      <option key="" value="" />
      {caseStatusDialog.savedCase.prevWorkflowStatuses.map(status => {
        return (
          <option key={status} value={status}>
            {formatCaseStatus(status)}
          </option>
        );
      })}
    </Select>
  ) : null;
  const confirmMessage =
    caseStatusDialog.isOpen &&
    caseStatusDialog.histoCase.data &&
    caseStatusDialog.histoCase.data.status
      ? `Are you sure you want to set the status of case '${
          caseStatusDialog.savedCase.procId
        }' back to '${formatCaseStatus(caseStatusDialog.histoCase.data.status)}'?`
      : "";
  const confirmDialog = (
    <ConfirmationDialog
      title="Confirm Revert Case Status"
      message={confirmMessage}
      isOpen={isConfirmationDialogOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
  return (
    <>
      <Dialog
        isOpen={caseStatusDialog.isOpen && !isConfirmationDialogOpen}
        onRequestClose={closeDialog}
        appElementId="root"
      >
        <DialogHeader title="Revert Case Status" closeDialog={closeDialog} />
        <DialogBody>
          {errorText}
          <Box>{caseStatusSelect}</Box>
          <Box style={{ marginTop: 12 }}>
            <ReasonForChange
              rkey="histoCase_reason"
              value={
                (caseStatusDialog.isOpen && caseStatusDialog.histoCase.data.reasonForChange) || ""
              }
              onChange={onChangeReasonForChange}
            />
          </Box>
        </DialogBody>
        <DialogFooter>
          <Box>
            <Button
              intent="primary"
              appearance="prominent"
              onClick={onSave}
              isLoading={
                caseStatusDialog.isOpen &&
                "isPending" in caseStatusDialog.histoCase &&
                caseStatusDialog.histoCase.isPending
              }
              disabled={
                !caseStatusDialog.isOpen ||
                !caseStatusDialog.histoCase.data ||
                !caseStatusDialog.histoCase.data.status
              }
            >
              Revert
            </Button>
          </Box>
          <Box>
            <Button onClick={closeDialog}>Cancel</Button>
          </Box>
        </DialogFooter>
      </Dialog>
      {confirmDialog}
    </>
  );
};

export default CaseStatusDialog;
