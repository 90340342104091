import { Box } from "@blasterjs/core";
import React from "react";

interface Props {
  readonly isLoading: boolean;
  readonly children?: React.ReactNode;
}

const Content = ({ isLoading, children }: Props) =>
  isLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" mt={4} minHeight="24em">
      <Box className="loader" />
    </Box>
  ) : (
    <Box>{children}</Box>
  );

export default Content;
