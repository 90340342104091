import React, { useState } from "react";

import { Box, Button, Callout, Dialog, Intent, Text, TextInput } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { useAppDispatch, useAppSelector } from "../hooks";
import { closeCopyImageDialog, imageCopy, setCopyImageForm } from "../slices/copyImageDialog";

const CopyImageDialog = () => {
  const dispatch = useAppDispatch();
  const copyImageDialog = useAppSelector(state => state.copyImageDialog);

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const closeDialog = () => {
    dispatch(closeCopyImageDialog());
  };

  const onConfirm = (_: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationDialogOpen(false);
    dispatch(imageCopy());
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    copyImageDialog.isOpen &&
      dispatch(
        setCopyImageForm({
          duplicateImageName: e.target.value
        })
      );
  };

  const errorText =
    copyImageDialog.isOpen && "errorMessage" in copyImageDialog.image ? (
      <Box mb={2}>
        <Callout intent={Intent.DANGER}>
          <Text>{copyImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  return (
    <>
      <Dialog
        isOpen={copyImageDialog.isOpen && !isConfirmationDialogOpen}
        onRequestClose={closeDialog}
        appElementId="root"
      >
        <DialogHeader title="Copy Image" closeDialog={closeDialog} />
        <DialogBody>
          {errorText}
          <Box style={{ marginTop: 12 }}>
            <TextInput
              value={
                (copyImageDialog.isOpen && copyImageDialog.image.data.duplicateImageName) || ""
              }
              onChange={onChange}
            />
          </Box>
        </DialogBody>
        <DialogFooter>
          <Box>
            <Button
              onClick={onConfirm}
              appearance="prominent"
              intent="primary"
              isLoading={
                copyImageDialog.isOpen &&
                "isPending" in copyImageDialog.image &&
                copyImageDialog.image.isPending
              }
            >
              Copy image
            </Button>
          </Box>
          <Box>
            <Button onClick={closeDialog}>Cancel</Button>
          </Box>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CopyImageDialog;
