import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryMetadata, QueryComment, QueryDetails, UUID, QueryObjectType } from "../models";
import {
  fetchQueryMetadata,
  addQueryComment,
  fetchAllQueriesByCase,
  fetchAllQueriesByImage,
  newQueryFollowUpReminder,
  markQueryAsResolved,
  createQuery,
  closeQueryApi
} from "../api";
import { Resource } from "../types";
import { castDraft } from "immer";
import { RootState } from "../store";

export interface QueryViewObjectType {
  queryViewObjectType: QueryObjectType;
  viewObjectId: string;
}

export interface QueryDetailsState {
  readonly currentViewQueryObjectType: QueryViewObjectType;
  readonly queryDetailsMetadata: Resource<QueryMetadata>;
  readonly queryDetails: Resource<QueryDetails>;
}

export const initialState: QueryDetailsState = {
  currentViewQueryObjectType: {
    queryViewObjectType: QueryObjectType.Case,
    viewObjectId: ""
  },
  queryDetailsMetadata: {
    isPending: false
  },
  queryDetails: {
    isPending: false
  }
};

export interface OpenQueryParams {
  readonly queryObjectType: QueryObjectType;
  readonly objectId: UUID;
  readonly studyId: UUID | null;
  readonly caseId: UUID;
  readonly category: string;
  readonly categoryOtherText: string | null;
  readonly followUpInDays: number;
  readonly commentText: string | null;
  readonly withRoleId: UUID | null;
  readonly responseOptionId: UUID | null;
  readonly childResponseOptionId: string | null;
}

export interface queryFollowUpParams {
  readonly queryId: string;
  readonly queryReminderId: string;
  readonly followUpInDays: number;
}

export interface queryMarkAsResolvedParams {
  readonly queryId: UUID;
  readonly resolutionOption: string;
  readonly commentText: string;
}

export interface CloseQueryParams {
  readonly queryId: string;
  readonly detailedReasonText: string;
}

// thunks
export const openQuery = createAsyncThunk(
  "queryDetails/openQuery",
  async (params: OpenQueryParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state = getState() as RootState;

    const response = await createQuery(
      params.queryObjectType,
      params.objectId,
      params.studyId,
      params.caseId,
      params.category,
      params.categoryOtherText,
      params.followUpInDays,
      params.commentText,
      params.withRoleId,
      params.responseOptionId,
      params.childResponseOptionId
    );

    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Case
    ) {
      await dispatch(
        queryDetailsFetchByCase(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }
    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Image
    ) {
      await dispatch(
        queryDetailsFetchByImage(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }

    return response;
  }
);

export const queryDetailsMetadataFetch = createAsyncThunk(
  "queryDetails/queryDetailsMetadataFetch",
  async (studyId: UUID | null) => {
    const response = await fetchQueryMetadata(studyId);
    return response;
  }
);

export const queryDetailsFetchByCase = createAsyncThunk(
  "queryDetails/queryDetailsFetchByCase",
  async (caseId: UUID) => {
    const response = await fetchAllQueriesByCase(caseId);
    return response;
  }
);

export const queryDetailsFetchByImage = createAsyncThunk(
  "queryDetails/queryDetailsFetchByImage",
  async (imageId: UUID) => {
    const response = await fetchAllQueriesByImage(imageId);
    return response;
  }
);

export const addQueryCommentThunk = createAsyncThunk(
  "queryDetails/addQueryComment",
  async (comment: QueryComment, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const { queryId, text } = comment;
    const response = await addQueryComment(queryId, text);

    const state = getState() as RootState;

    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Case
    ) {
      await dispatch(
        queryDetailsFetchByCase(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }
    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Image
    ) {
      await dispatch(
        queryDetailsFetchByImage(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }

    return response;
  }
);

export const followUpQuery = createAsyncThunk(
  "queryDetails/followUpQuery",
  async (params: queryFollowUpParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;

    const response = await newQueryFollowUpReminder(
      params.queryId,
      params.queryReminderId,
      params.followUpInDays
    );

    const state = getState() as RootState;

    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Case
    ) {
      await dispatch(
        queryDetailsFetchByCase(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }
    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Image
    ) {
      await dispatch(
        queryDetailsFetchByImage(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }

    return response;
  }
);

export const markAsResolved = createAsyncThunk(
  "queryDetails/markAsResolved",
  async (params: queryMarkAsResolvedParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;

    const response = await markQueryAsResolved(
      params.queryId,
      params.resolutionOption,
      params.commentText
    );

    const state = getState() as RootState;

    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Case
    ) {
      await dispatch(
        queryDetailsFetchByCase(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }
    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Image
    ) {
      await dispatch(
        queryDetailsFetchByImage(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }

    return response;
  }
);

export const closeQuery = createAsyncThunk(
  "queryDetails/closeQuery",
  async (params: CloseQueryParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;

    const response = await closeQueryApi(params.queryId, params.detailedReasonText);

    const state = getState() as RootState;

    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Case
    ) {
      await dispatch(
        queryDetailsFetchByCase(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }
    if (
      state.queryDetails.currentViewQueryObjectType.queryViewObjectType === QueryObjectType.Image
    ) {
      await dispatch(
        queryDetailsFetchByImage(state.queryDetails.currentViewQueryObjectType.viewObjectId)
      );
    }

    return response;
  }
);

// slice
export const queryDetailsSlice = createSlice({
  name: "queryDetails",
  initialState: initialState,
  reducers: {
    setCurrentViewQueryObjectType: (state, action: PayloadAction<QueryViewObjectType>) => {
      state.currentViewQueryObjectType = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(queryDetailsFetchByCase.pending, state => {
      state.queryDetails = { isPending: true };
    });
    builder.addCase(queryDetailsFetchByCase.fulfilled, (state, action) => {
      state.queryDetails = { resource: castDraft(action.payload) };
    });
    builder.addCase(queryDetailsFetchByCase.rejected, (state, action) => {
      state.queryDetails = { errorMessage: action.error.message || "" };
    });

    builder.addCase(queryDetailsFetchByImage.pending, state => {
      state.queryDetails = { isPending: true };
    });
    builder.addCase(queryDetailsFetchByImage.fulfilled, (state, action) => {
      state.queryDetails = { resource: castDraft(action.payload) };
    });
    builder.addCase(queryDetailsFetchByImage.rejected, (state, action) => {
      state.queryDetails = { errorMessage: action.error.message || "" };
    });

    builder.addCase(queryDetailsMetadataFetch.pending, state => {
      state.queryDetailsMetadata = { isPending: true };
    });
    builder.addCase(queryDetailsMetadataFetch.fulfilled, (state, action) => {
      state.queryDetailsMetadata = { resource: castDraft(action.payload) };
    });
    builder.addCase(queryDetailsMetadataFetch.rejected, (state, action) => {
      state.queryDetailsMetadata = { errorMessage: action.error.message || "" };
    });
  }
});

export const { setCurrentViewQueryObjectType } = queryDetailsSlice.actions;
export default queryDetailsSlice.reducer;
