import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AddReasonForChange,
  AdminCaseWithImagesAndReaders,
  Images,
  NestValues,
  onlyUpdates,
  ReadersStudyStats,
  ReaderStudyStats,
  UpdateValueWithReasonForChange,
  UUID
} from "../models";
import { Resource, WriteResource } from "../types";
import {
  createCase,
  editCase,
  fetchCase,
  searchStudyImages,
  searchStudyReaders,
  suggestReaders
} from "../api";
import { RootState } from "../store";
import { Image } from "../models";
import { casesFetch } from "./cases";
import { castDraft } from "immer";
import { refreshCase } from "./caseImageViewer";

export enum CaseDialogMode {
  OpenForCreate = "OPEN_FOR_CREATE",
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

type Form = NestValues<{
  readonly procId: string;
  readonly histoProcedureId: string | null;
  readonly subjectId: string;
  readonly visitId: string;
  readonly siteId: string | null;
  readonly images: Images;
  readonly readers: ReadersStudyStats;
}>;

export type UpdateCaseForm = AddReasonForChange<Form>;

export type CreateCaseForm = Form;

export type CaseForm<M extends CaseDialogMode> = M extends CaseDialogMode.OpenForEdit
  ? UpdateCaseForm
  : CreateCaseForm;

function adminCaseToForm(adminCase: AdminCaseWithImagesAndReaders): UpdateCaseForm {
  return {
    procId: {
      value: adminCase.caseWithStatus.procId
    },
    histoProcedureId: {
      value: adminCase.caseWithStatus.histoProcedureId
    },
    subjectId: {
      value: adminCase.caseWithStatus.subjectId
    },
    siteId: {
      value: adminCase.caseWithStatus.siteId
    },
    visitId: {
      value: adminCase.caseWithStatus.visitId
    },
    images: {
      value: adminCase.images
    },
    readers: {
      value: adminCase.readers
    }
  };
}

export interface CaseDialogState {
  readonly mode: CaseDialogMode;
  readonly studyId: UUID | null;
  readonly id: UUID | null;
  readonly savedHistoCase: AdminCaseWithImagesAndReaders | null;
  readonly histoCase: WriteResource<CaseForm<CaseDialogMode>, AdminCaseWithImagesAndReaders>;
  readonly readersSearchResults: Resource<ReadersStudyStats>;
  readonly readersSearchText: string;
  readonly imagesSearchResults: Resource<Images>;
  readonly imagesSearchText: string;
  readonly readerSuggestions: Resource<ReadersStudyStats>;
  readonly refreshSingleCaseOnSuccess: boolean;
}

export const initialState: CaseDialogState = {
  mode: CaseDialogMode.Closed,
  studyId: null,
  id: null,
  savedHistoCase: null,
  histoCase: {
    data: {
      procId: { value: "" },
      histoProcedureId: { value: "" },
      subjectId: { value: "" },
      siteId: { value: "" },
      visitId: { value: "" },
      images: { value: [] },
      readers: { value: [] }
    }
  },
  readersSearchResults: {
    resource: []
  },
  readersSearchText: "",
  imagesSearchResults: {
    resource: []
  },
  imagesSearchText: "",
  readerSuggestions: {
    resource: []
  },
  refreshSingleCaseOnSuccess: false
};

export interface OpenEditCaseDialogParams {
  readonly id: UUID;
  readonly studyId: UUID;
  readonly refreshSingleCaseOnSuccess: boolean;
}

// thunks
export const openEditCaseDialog = createAsyncThunk(
  "caseDialog/openEditCaseDialog",
  async (params: OpenEditCaseDialogParams, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setOpenEditCaseDialog(params));
    await dispatch(fetchCaseRequest(params.id));
  }
);

export const fetchCaseRequest = createAsyncThunk(
  "caseDialog/fetchCaseRequest",
  async (caseId: UUID) => {
    const response = await fetchCase(caseId);
    return response;
  }
);

export const caseSuggestReadersRequest = createAsyncThunk(
  "caseDialog/caseSuggestReadersRequest",
  async (subjectId: string, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (state.caseDialog.studyId !== null) {
      const response = await suggestReaders(state.caseDialog.studyId, subjectId);
      return response;
    }
  }
);

export interface CaseSearchReadersRequestParams {
  readonly name: string;
  readonly studyId: string | null;
}

export const caseSearchReadersRequest = createAsyncThunk(
  "caseDialog/caseSearchReadersRequest",
  async (params: CaseSearchReadersRequestParams, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (state.caseDialog.studyId) {
      const response = await searchStudyReaders(state.caseDialog.studyId, params.name);
      return response;
    }
  }
);

export const imagesSearch = createAsyncThunk(
  "caseDialog/imagesSearch",
  async (name: string, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const state = getState() as RootState;
    if (state.caseDialog.histoCase.data && state.caseDialog.studyId) {
      dispatch(setImageSearch(name));
      const response = await searchStudyImages(
        state.caseDialog.studyId,
        state.caseDialog.id,
        name,
        state.caseDialog.histoCase.data.images.value.map(image => image.id)
      );
      return response;
    }
  }
);

export const createCaseRequest = createAsyncThunk(
  "caseDialog/createCaseRequest",
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi;

    await dispatch(createCaseRequestInner());

    const state = getState() as RootState;

    if (state.caseDialog.studyId !== null) {
      const studyId = state.caseDialog.studyId;
      if (state.caseDialog.refreshSingleCaseOnSuccess) {
        await dispatch(refreshCase());
      } else {
        await dispatch(casesFetch(studyId));
      }
    }
  }
);

export const createCaseRequestInner = createAsyncThunk(
  "caseDialog/createCaseRequestInner",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (
      state.caseDialog.studyId !== null &&
      state.caseDialog.histoCase.data &&
      state.caseDialog.mode === CaseDialogMode.OpenForCreate
    ) {
      const createCaseResponse = await createCase(
        state.caseDialog.studyId,
        state.caseDialog.histoCase.data.procId.value,
        state.caseDialog.histoCase.data.subjectId.value,
        state.caseDialog.histoCase.data.visitId.value,
        state.caseDialog.histoCase.data.siteId.value,
        state.caseDialog.histoCase.data.readers.value.map(readerStats => readerStats.reader.id),
        state.caseDialog.histoCase.data.images.value.map(image => image.id)
      );

      return createCaseResponse;
    }
  }
);

interface UpdatedCaseProperty {
  readonly value: string | null;
  readonly reasonForChange?: string;
}

function convertUpdatedValue(
  uv: UpdatedCaseProperty | undefined
): UpdateValueWithReasonForChange<string> | undefined {
  if (uv === undefined) {
    return undefined;
  } else {
    if ("reasonForChange" in uv) {
      return {
        value: uv.value,
        reasonForChange: uv.reasonForChange
      } as UpdateValueWithReasonForChange<string> | undefined;
    } else {
      return undefined;
    }
  }
}

export const editCaseRequest = createAsyncThunk(
  "caseDialog/editCaseRequest",
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi;

    await dispatch(editCaseRequestInner());

    const state = getState() as RootState;

    if (state.caseDialog.studyId !== null) {
      if (state.caseDialog.refreshSingleCaseOnSuccess) {
        await dispatch(refreshCase());
      } else {
        if (state.caseDialog.studyId) {
          await dispatch(casesFetch(state.caseDialog.studyId));
        }
      }
    }
  }
);

const editCaseRequestInner = createAsyncThunk(
  "caseDialog/editCaseRequestInner",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const updates =
      state.caseDialog.savedHistoCase &&
      onlyUpdates(
        adminCaseToForm(state.caseDialog.savedHistoCase),
        state.caseDialog.histoCase.data
      );

    if (
      state.caseDialog.id !== null &&
      updates &&
      state.caseDialog.histoCase.data &&
      state.caseDialog.mode === CaseDialogMode.OpenForEdit
    ) {
      const editCaseResponse = await editCase(
        state.caseDialog.id,
        convertUpdatedValue(updates.procId),
        convertUpdatedValue(updates.histoProcedureId),
        convertUpdatedValue(updates.siteId),
        convertUpdatedValue(updates.subjectId),
        convertUpdatedValue(updates.visitId),
        updates.readers
          ? {
              ...updates.readers,
              value: updates.readers.value.map(
                (readerStats: ReaderStudyStats) => readerStats.reader.id
              ),
              reasonForChange:
                updates.readers &&
                updates.readers !== undefined &&
                "reasonForChange" in updates.readers
                  ? (updates.readers["reasonForChange"] as string)
                  : ""
            }
          : undefined,
        updates.images
          ? {
              ...updates.images,
              value: updates.images.value.map((image: Image) => image.id),
              reasonForChange:
                "reasonForChange" in updates.images
                  ? (updates.images["reasonForChange"] as string)
                  : ""
            }
          : undefined
      );

      return editCaseResponse;
    }
  }
);

export const caseDialogSlice = createSlice({
  name: "caseDialog",
  initialState: initialState,
  reducers: {
    setOpenEditCaseDialog: (state, action: PayloadAction<OpenEditCaseDialogParams>) => {
      return {
        ...initialState,
        mode: CaseDialogMode.OpenForEdit,
        studyId: action.payload.studyId,
        id: action.payload.id,
        refreshSingleCaseOnSuccess: action.payload.refreshSingleCaseOnSuccess
      };
    },
    openCreateCaseDialog: (state, action: PayloadAction<string | null>) => {
      return {
        ...initialState,
        mode: CaseDialogMode.OpenForCreate,
        studyId: action.payload
      };
    },
    closeCaseDialog: state => {
      state.mode = CaseDialogMode.Closed;
    },
    changeCase: (state, action: PayloadAction<CaseForm<CaseDialogMode>>) => {
      state.histoCase = {
        data: castDraft(action.payload)
      };
    },
    addReader: (state, action: PayloadAction<ReaderStudyStats>) => {
      if (state.histoCase.data) {
        state.histoCase = {
          data: {
            ...state.histoCase.data,
            readers: {
              value: !state.histoCase.data.readers.value
                .map(readerStats => readerStats.reader.id)
                .includes(action.payload.reader.id)
                ? castDraft([...state.histoCase.data.readers.value, action.payload])
                : state.histoCase.data.readers.value
            }
          }
        };
      }
    },
    dropReader: (state, action: PayloadAction<ReaderStudyStats>) => {
      if (state.histoCase.data) {
        state.histoCase.data.readers = {
          value: state.histoCase.data.readers.value.filter(
            readerStudyStats => readerStudyStats.reader.id !== action.payload.reader.id
          )
        };
      }
    },
    setImageSearch: (state, action: PayloadAction<string>) => {
      state.imagesSearchText = action.payload;
      state.imagesSearchResults = { isPending: true };
    },
    assignImage: (state, action: PayloadAction<Image>) => {
      if (state.histoCase.data) {
        state.histoCase.data.images.value = [...state.histoCase.data.images.value, action.payload];
      }
    },
    unassignImage: (state, action: PayloadAction<Image>) => {
      if (state.histoCase.data) {
        state.histoCase.data.images = {
          value: state.histoCase.data.images.value.filter(
            imageWithAnnotations => imageWithAnnotations.id !== action.payload.id
          )
        };
      }
    }
  },

  extraReducers: builder => {
    builder.addCase(fetchCaseRequest.pending, state => {
      state.histoCase = {
        data: state.histoCase.data,
        isPending: true
      };
    });
    builder.addCase(fetchCaseRequest.fulfilled, (state, action) => {
      if (state.mode === CaseDialogMode.OpenForEdit) {
        if (action.payload && "imageQueries" in action.payload) {
          state.savedHistoCase = castDraft(action.payload);

          state.histoCase = {
            data: castDraft(adminCaseToForm(action.payload)),
            resource: castDraft(action.payload)
          };
        }
      }
    });
    builder.addCase(fetchCaseRequest.rejected, (state, action) => {
      state.histoCase = {
        data: state.histoCase.data,
        errorMessage: action.error.message
      };
    });

    // caseSuggestReadersRequest
    builder.addCase(caseSuggestReadersRequest.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.readerSuggestions = { resource: castDraft(action.payload) };
      }
    });
    builder.addCase(caseSuggestReadersRequest.rejected, (state, action) => {
      state.readerSuggestions = { errorMessage: action.error.message || "" };
    });

    // caseSearchReadersRequest
    builder.addCase(caseSearchReadersRequest.pending, (state, action) => {
      state.readersSearchText = action.meta.arg.name;
      state.readersSearchResults = { isPending: true };
    });
    builder.addCase(caseSearchReadersRequest.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.readersSearchResults = { resource: castDraft(action.payload) };
      }
    });
    builder.addCase(caseSearchReadersRequest.rejected, (state, action) => {
      state.readersSearchResults = { errorMessage: action.error.message || "" };
    });

    // imagesSearch
    builder.addCase(imagesSearch.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.imagesSearchResults = { resource: castDraft(action.payload) };
      }
    });
    builder.addCase(imagesSearch.rejected, (state, action) => {
      state.imagesSearchResults = { errorMessage: action.error.message || "" };
    });

    // createCaseRequestInner
    builder.addCase(createCaseRequestInner.pending, state => {
      state.histoCase = {
        data: state.histoCase.data,
        isSaving: true
      };
    });
    builder.addCase(createCaseRequestInner.fulfilled, (state, action) => {
      if (state.studyId !== null) {
        state.mode = CaseDialogMode.Closed;
        if (action.payload !== undefined) {
          state.histoCase = {
            data: state.histoCase.data,
            resource: castDraft(action.payload)
          };
        }
      }
    });
    builder.addCase(createCaseRequestInner.rejected, (state, action) => {
      state.histoCase = {
        data: state.histoCase.data,
        errorMessage: action.error.message || ""
      };
    });

    // editCaseRequest
    builder.addCase(editCaseRequestInner.pending, state => {
      state.histoCase = {
        data: state.histoCase.data,
        isSaving: true
      };
    });
    builder.addCase(editCaseRequestInner.fulfilled, (state, action) => {
      state.mode = CaseDialogMode.Closed;
      if (action.payload !== undefined) {
        state.histoCase = {
          data: state.histoCase.data,
          resource: castDraft(action.payload as AdminCaseWithImagesAndReaders)
        };
      }
    });
    builder.addCase(editCaseRequestInner.rejected, (state, action) => {
      state.histoCase = {
        data: state.histoCase.data,
        errorMessage: action.error.message || ""
      };
    });
  }
});

export const {
  setImageSearch,
  setOpenEditCaseDialog,
  openCreateCaseDialog,
  closeCaseDialog,
  changeCase,
  addReader,
  dropReader,
  assignImage,
  unassignImage
} = caseDialogSlice.actions;

export default caseDialogSlice.reducer;
