import { Appearance, Box, Button, Heading, Icon, Table, Text } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";
import { Image, ImageAndQuery } from "../models";

import ImageDialog from "../components/ImageDialog";
import { copyText } from "../components/AnnotationNest";
import Timestamp from "./Timestamp";
import { useAppDispatch, useAppSelector } from "../hooks";
import { openImageDialog } from "../slices/imageDialog";
import { RolePermissions } from "../permissions";
import { formatImageStatus } from "../pages/ImageManagement";
import { toggleImageForCR } from "../slices/caseImageViewer";

const CompactTable = styled(Table)`
  td:first-child {
    font-weight: 600;
    padding-right: 1rem;
    width: 12rem;
  }
`;

const SelectionBox = styled(Box)`
  display: flex;
  align-items: flex-start;
`;

interface Props {
  readonly imageAndQuery: ImageAndQuery;
  readonly collapseIcon?: React.ReactNode;
  readonly inQueryChain: boolean;
}

const ImageInfo = ({ imageAndQuery, collapseIcon, inQueryChain }: Props) => {
  const dispatch = useAppDispatch();

  const image: Image = imageAndQuery.image;

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const canEdit =
    loggedInUser &&
    "resource" in loggedInUser &&
    loggedInUser.resource.can([
      RolePermissions.AP_ImageData_EditAccessionNumber,
      RolePermissions.AP_ImageData_EditFileName,
      RolePermissions.AP_ImageData_EditAnatomicalSegment
    ]);
  const editIcon = canEdit ? (
    <Button
      value={image.id}
      onClick={() => dispatch(openImageDialog({ image: image, refreshSingleImageOnSuccess: true }))}
      appearance={Appearance.MINIMAL}
      title="Edit Image"
    >
      <Icon name="edit" />
    </Button>
  ) : null;

  const imageDialog = canEdit ? <ImageDialog /> : null;

  const toggleImageToReaderOnClick = () => {
    dispatch(toggleImageForCR(image.id));
  };

  const showToReaderButton =
    "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.AP_CaseViewer_ViewButtonPassISCQC]) &&
    inQueryChain ? (
      <Box>
        <SelectionBox>
          <Icon name="image" color="gray" />
          Show this image to reader? {image.ignored}
        </SelectionBox>
        <Text>
          {imageAndQuery.query === null
            ? "This image was uploaded as a replacement for an image with a query"
            : "This image has a query but can still be shown to readers"}
        </Text>
        {image.ignored ? (
          <Button
            mt={1}
            block={true}
            onClick={toggleImageToReaderOnClick}
            title={"Using this image will make it visible to readers"}
            appearance="prominent"
            intent="primary"
          >
            Show to Reader
          </Button>
        ) : (
          <Button
            mt={1}
            block={true}
            onClick={toggleImageToReaderOnClick}
            title={"Hiding this image will make it invisible to readers"}
            appearance="prominent"
            intent="primary"
          >
            Hide from Reader
          </Button>
        )}
      </Box>
    ) : null;

  return (
    <>
      <Box display="flex" width="100%">
        <Heading textAlign="left" as="h6" mb={1}>
          Image
        </Heading>
        <Box ml={"auto"}>
          {editIcon}
          {collapseIcon}
        </Box>
      </Box>
      <CompactTable fontSize={1}>
        <tbody>
          <tr>
            <td>File Name</td>
            <td>
              <Box lineHeight="1.3">{image.name}</Box>
            </td>
          </tr>
          <tr>
            <td>Uploaded</td>
            <td>
              <Timestamp date={image.uploadedAt} />
            </td>
          </tr>
          <tr>
            <td>Accession #</td>
            <td>
              <Box
                className="copyableBox"
                title="click to copy"
                lineHeight="1.3"
                onClick={(e: MouseEvent) =>
                  e && e.target && copyText((e.target as HTMLSpanElement).innerText)
                }
              >
                {image.accessionNumber}
              </Box>
            </td>
          </tr>
          <tr>
            <td>Anatomical Segment</td>
            <td>
              <Box lineHeight="1.3">{image.biopsyLocation}</Box>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <Box lineHeight="1.3">{formatImageStatus(image.status)}</Box>
            </td>
          </tr>
        </tbody>
      </CompactTable>
      {showToReaderButton}
      {imageDialog}
    </>
  );
};

export default ImageInfo;
