import React, { createRef } from "react";
import Autocomplete from "react-autocomplete";

import { Box, Button } from "@blasterjs/core";

interface Props<T> {
  readonly placeholder: string;
  readonly searchText: string;
  readonly selectedItems: ReadonlyArray<T>;
  readonly onSelect: (model: T) => void;
  readonly onChangeSearchText: (value: string) => void;
  readonly onDeselect: (model: T) => void;
  readonly searchResults: ReadonlyArray<T>;
  readonly isLoading: boolean;
  readonly format: (model: T) => string;
  readonly disabled?: boolean;
  readonly disabledText?: string;
}

export type SelectOfType<T> = React.FC<Props<T>>;

const Select = <T extends object>({
  placeholder,
  searchText,
  onSelect,
  onChangeSearchText,
  onDeselect,
  selectedItems,
  searchResults,
  isLoading,
  format,
  disabled,
  disabledText
}: Props<T>) => {
  // NOTE: After making a selection we want to blur the text input and hide the
  // search results. However, the `Autocomplete` component doesn't expose the
  // event object directly. To achieve this, we bind events to cover the ways
  // in which you can select a result (by clicking or by pressing enter) and do
  // the blurring ourselves.
  const onRenderMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    blurSearchInput();
  };
  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      blurSearchInput();
    }
  };
  const onSelectItem = (_value: string, item: T) => {
    onSelect(item);
    onChangeSearchText("");
  };
  const onFocus = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChangeSearchText(e.currentTarget.value);
  const onChange = (_e: React.ChangeEvent<HTMLInputElement>, value: string) =>
    onChangeSearchText(value);
  const onButtonClick = (model: T) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onDeselect(model);
    e.preventDefault();
    blurSearchInput();
  };
  const blurSearchInput = () => searchInput.current && searchInput.current.blur();
  const renderMenu = (_models: ReadonlyArray<T>) => (children: ReadonlyArray<React.ReactNode>) => (
    <div className="menu" onClick={onRenderMenuClick}>
      {isLoading ? (
        <div style={{ padding: "2px 8px" }}>Loading ...</div>
      ) : children.length ? (
        children
      ) : (
        <div style={{ padding: "2px 8px" }}>No results</div>
      )}
    </div>
  );
  const renderItem = (model: T, isHighlighted: boolean) => {
    const label = format(model);
    return (
      <div className={`item ${isHighlighted ? "item-highlighted" : ""}`} key={label}>
        {label}
      </div>
    );
  };
  const searchInput = createRef<Autocomplete>();
  return (
    <Box>
      <Autocomplete
        ref={searchInput}
        inputProps={{
          placeholder,
          onFocus,
          onKeyUp,
          style: {
            width: "100%",
            padding: "0.8rem",
            background: "#FFFFFF",
            border: "1px solid",
            borderColor: "#231F20",
            borderRadius: "0px",
            fontFamily: "inherit",
            fontSize: "1.6rem",
            boxShadow:
              "inset 0px 1px 1px 0px rgba(16,22,26,0.2), inset 0px 0px 0px 1px rgba(16,22,26,0.15)",
            borderWidth: "0",
            position: "relative",
            height: "3.8rem"
          },
          disabled,
          ...(disabledText
            ? {
                title: disabledText
              }
            : {})
        }}
        wrapperStyle={{
          position: "relative",
          display: "inline-block",
          width: "100%"
        }}
        value={searchText}
        items={Array.from(searchResults)}
        getItemValue={format}
        onSelect={onSelectItem}
        onChange={onChange}
        renderMenu={renderMenu(searchResults)}
        renderItem={renderItem}
      />
      <Box mt={1}>
        {selectedItems.length
          ? selectedItems.map((model, index) => (
              <Button
                key={index}
                iconAfter="cross"
                appearance="prominent"
                intent="secondary"
                mr={1}
                mb={1}
                onClick={onButtonClick(model)}
                disabled={disabled}
              >
                {format(model)}
              </Button>
            ))
          : null}
      </Box>
    </Box>
  );
};

export default Select;
