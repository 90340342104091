import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Studies, StudiesAccess, User, UUID } from "../models";
import {
  addStudyAccess,
  fetchStudiesForUser,
  fetchUserById,
  fetchUserStudyAccess,
  updateUser
} from "../api";
import { Resource } from "../types";
import { castDraft } from "immer";
import { RootState } from "../store";

export interface UserForm {
  readonly firstName: string | null;
  readonly lastName: string | null;
  readonly email: string;
  readonly organizationId: UUID | null;
}

export interface NewStudyAccess {
  readonly studyId: string | null;
  readonly roleId: string | null;
  readonly studyName: string | null;
  readonly roleName: string | null;
}

export interface UserProfileState {
  readonly user: User | undefined;
  readonly editUser: Resource<User>;
  readonly userForm: UserForm;
  readonly newStudyAccess: NewStudyAccess;
  readonly saveButtonDisabled: boolean;
  readonly errorMessage: string | null;
  readonly studiesAccess: Resource<StudiesAccess>;
  readonly studiesForUser: Resource<Studies>;
}

const initialState: UserProfileState = {
  user: undefined,
  editUser: { isPending: false },
  userForm: {
    firstName: "",
    lastName: "",
    email: "",
    organizationId: null
  },
  newStudyAccess: { studyId: null, roleId: null, studyName: null, roleName: null },
  saveButtonDisabled: true,
  errorMessage: null,
  studiesAccess: { isPending: false },
  studiesForUser: { isPending: false }
};

function enableSaveButton(uf: UserForm, user: User | undefined): boolean {
  return user !== undefined && (uf.firstName !== user.firstName || uf.lastName !== user.lastName);
}

// thunks
export const userFetch = createAsyncThunk(
  "userProfile/userFetch",
  async (userId: string, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const userForm = (getState() as RootState).userProfile.userForm;
    const response = await fetchUserById(userId);
    dispatch(
      changeUserForm({
        ...userForm,
        firstName: response.firstName,
        lastName: response.lastName,
        email: response.username,
        organizationId: response.organizationId
      })
    );
    return response;
  }
);
export const userStudyAccessFetch = createAsyncThunk(
  "userProfile/userStudyAccessFetch",
  async (userId: string) => {
    const response = await fetchUserStudyAccess(userId);
    return response;
  }
);

export const studiesForUserFetch = createAsyncThunk("userProfile/studiesForUserFetch", async () => {
  const response = await fetchStudiesForUser();
  return response;
});

export interface RemoveStudyAccessParams {
  userId: UUID;
  studyId: UUID;
  roleId: UUID;
}

export const userStudyAccessRemove = createAsyncThunk(
  "userProfile/userStudyAccessRemove",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (params: RemoveStudyAccessParams) => {
    //console.log(`remove study access for: studyId=${params.studyId}, userId=${params.userId}`)
  }
);

export const userUpdate = createAsyncThunk("userProfile/userUpdate", async (_: void, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  const state = getState() as RootState;
  if ("resource" in state.userProfile.editUser) {
    const userId = state.userProfile.editUser.resource.id;
    const response = await updateUser(
      userId,
      state.userProfile.userForm.firstName || undefined,
      state.userProfile.userForm.lastName || undefined,
      state.userProfile.userForm.organizationId || undefined
    );
    await dispatch(userFetch(userId));
    return response;
  } else {
    //dispatch(userUpdateFailure("Invalid state."));
  }
});

export const addStudyAccessRequest = createAsyncThunk(
  "userProfile/addStudyAccessRequest",
  async (userId: string, thunkApi) => {
    const { getState } = thunkApi;
    const reduxState = getState() as RootState;
    const state = reduxState.userProfile;
    if (state.newStudyAccess.roleId && state.newStudyAccess.studyId) {
      const response = await addStudyAccess(
        state.newStudyAccess.studyId,
        userId,
        state.newStudyAccess.roleId
      );
      return response;
    }
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {
    changeUserForm: (state, action: PayloadAction<UserForm>) => {
      state.userForm = action.payload;
      state.saveButtonDisabled = !enableSaveButton(action.payload, state.user);
    },
    setNewStudyAccess: (state, action: PayloadAction<NewStudyAccess>) => {
      state.newStudyAccess = action.payload;
    }
  },

  extraReducers: builder => {
    builder.addCase(userFetch.pending, state => {
      state.editUser = { isPending: true };
    });
    builder.addCase(userFetch.fulfilled, (state, action) => {
      state.editUser = { resource: castDraft(action.payload) };
    });
    builder.addCase(userFetch.rejected, (state, action) => {
      state.editUser = { errorMessage: action.error.message || "" };
    });
    builder.addCase(userStudyAccessFetch.pending, state => {
      state.studiesAccess = { isPending: true };
    });
    builder.addCase(userStudyAccessFetch.fulfilled, (state, action) => {
      state.studiesAccess = { resource: castDraft(action.payload) };
    });
    builder.addCase(userStudyAccessFetch.rejected, (state, action) => {
      state.studiesAccess = { errorMessage: action.error.message || "" };
    });
    builder.addCase(studiesForUserFetch.pending, state => {
      state.studiesForUser = { isPending: true };
    });
    builder.addCase(studiesForUserFetch.fulfilled, (state, action) => {
      state.studiesForUser = { resource: castDraft(action.payload) };
    });
    builder.addCase(studiesForUserFetch.rejected, (state, action) => {
      state.studiesForUser = { errorMessage: action.error.message || "" };
    });
  }
});

export const { changeUserForm, setNewStudyAccess } = userProfileSlice.actions;

export default userProfileSlice.reducer;
