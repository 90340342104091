import { configureStore, ThunkAction, Action, createListenerMiddleware } from "@reduxjs/toolkit";
import annotationNestReducer from "./slices/annotationNest";
import auditTrailReducer from "./slices/auditTrail";
import authReducer, { logout, redirectAction } from "./slices/auth";
import caseDialogReducer from "./slices/caseDialog";
import caseImageViewerReducer from "./slices/caseImageViewer";
import casesReducer from "./slices/cases";
import caseStatusDialogReducer from "./slices/caseStatusDialog";
import configReducer from "./slices/config";

import copyImageDialogReducer from "./slices/copyImageDialog";
import deleteImageDialogReducer from "./slices/deleteImageDialog";
import downloadImageDialogReducer from "./slices/downloadImageDialog";
import imageDialogReducer from "./slices/imageDialog";
import imagesReducer from "./slices/images";
import imageManagementReducer from "./slices/imageManagement";
import imageManagementBulkCaseDialogReducer from "./slices/ImageManagementBulkCaseDialog";
import imageManagementBulkImageDialogReducer from "./slices/ImageManagementBulkImageDialog";
import imageManagementCaseDialogParentReducer from "./slices/ImageManagementCaseDialogParent";
import imageManagementImageDialogParentReducer from "./slices/ImageManagementImageDialogParent";
import imageManagementEditTableCellReducer from "./slices/ImageManagementEditTableCell";
import locaionMapReducer from "./slices/locationMap";
import mapReducer from "./slices/map";
import metadataImagesReducer from "./slices/metadataImages";
import moveImageDialogReducer from "./slices/moveImageDialog";
import queriesReducer from "./slices/queries";
import reportsReducer from "./slices/reports";
import studiesReducer from "./slices/studies";
import studyConfigurationReducer from "./slices/studyConfiguration";
import uploadDialogReducer from "./slices/uploadDialog";
import uploadSampleDataDialogReducer from "./slices/uploadSampleDataDialog";
import usersReducer from "./slices/users";
import userProfileReducer from "./slices/UserProfile";
import organizationsReducer from "./slices/organizations";
import permissionsReducer from "./slices/permissions";
import workAsDialogReducer from "./slices/workAsDialog";
import queryDetailsReducer from "./slices/queryDetails";

import { createBrowserHistory } from "history";
import Cookies from "js-cookie";

export const history = createBrowserHistory();

const ssoCookieMiddleware = createListenerMiddleware();
const listenerMiddleware = createListenerMiddleware();
const redirectMiddleware = createListenerMiddleware();
const logoutMiddleware = createListenerMiddleware();

ssoCookieMiddleware.startListening({
  predicate: () => true,
  effect: () => {
    Cookies.set("sso-origin", window.location.href, {
      // Set cookie domain.
      // NOTE: This is needed because the subdomains for the Lucidity app and
      // SSO are different.
      // For example, in staging:
      // histo.tst-robartsinc.com VS sso.tst-robartsinc.com
      // In this case, the cookie domain needs to
      // be set to tst-robartsinc.com to persist across subdomains.
      domain: new URL(window.location.href).hostname.split(".").slice(-2).join(".")
    });
  }
});

logoutMiddleware.startListening({
  actionCreator: logout,
  effect: action => {
    history.replace(action.payload);
    window.location.reload();
  }
});

redirectMiddleware.startListening({
  actionCreator: redirectAction,
  effect: action => {
    history.replace(action.payload);
    window.location.reload();
  }
});

export const store = configureStore({
  reducer: {
    annotationNest: annotationNestReducer,
    auditTrail: auditTrailReducer,
    auth: authReducer,
    caseDialog: caseDialogReducer,
    caseImageViewer: caseImageViewerReducer,
    cases: casesReducer,
    caseStatusDialog: caseStatusDialogReducer,
    config: configReducer,
    copyImageDialog: copyImageDialogReducer,
    deleteImageDialog: deleteImageDialogReducer,
    downloadImageDialog: downloadImageDialogReducer,
    imageDialog: imageDialogReducer,
    images: imagesReducer,
    imageManagement: imageManagementReducer,
    imageManagementBulkCaseDialog: imageManagementBulkCaseDialogReducer,
    imageManagementBulkImageDialog: imageManagementBulkImageDialogReducer,
    imageManagementCaseDialogParent: imageManagementCaseDialogParentReducer,
    imageManagementImageDialogParent: imageManagementImageDialogParentReducer,
    imageManagementEditTableCell: imageManagementEditTableCellReducer,
    locaionMap: locaionMapReducer,
    map: mapReducer,
    metadataImages: metadataImagesReducer,
    moveImageDialog: moveImageDialogReducer,
    organizations: organizationsReducer,
    permissions: permissionsReducer,
    queries: queriesReducer,
    queryDetails: queryDetailsReducer,
    reports: reportsReducer,
    studies: studiesReducer,
    studyConfiguration: studyConfigurationReducer,
    uploadDialog: uploadDialogReducer,
    uploadSampleDataDialog: uploadSampleDataDialogReducer,
    users: usersReducer,
    userProfile: userProfileReducer,
    workAsDialog: workAsDialogReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
      .prepend(ssoCookieMiddleware.middleware)
      .prepend(listenerMiddleware.middleware)
      .prepend(redirectMiddleware.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
