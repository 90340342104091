import { Polyline, PolylineProps, Popup, Tooltip } from "react-leaflet";

import React from "react";
import { Text as BlasterText } from "@blasterjs/core";
import { UUID } from "../models";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";

interface Props extends PolylineProps {
  readonly text: string;
  readonly date: string;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  readonly showLabel?: boolean;
  readonly lineLength: number | null;
  readonly maxZoom: number;
  dispatch: AppDispatch;
}

const LineAnnotation = ({
  positions,
  text,
  date,
  annotationId,
  canDelete,
  showLabel,
  dispatch
}: Props) => {
  return (
    <Polyline positions={positions} color={"#00FF00"} weight={5}>
      <Popup closeButton={false} className="annotation-popup">
        <AnnotationTooltip annotationId={annotationId} canDelete={canDelete} dispatch={dispatch}>
          <div style={{ paddingTop: "0.2rem", paddingBottom: "0.2rem", lineHeight: "1" }}>
            <BlasterText fontSize="10pt" fontWeight="bold">
              {text}
            </BlasterText>
            <br />
            <BlasterText fontSize="8pt">{date}</BlasterText>
          </div>
        </AnnotationTooltip>
      </Popup>
      {showLabel === true ? (
        <Tooltip
          key={"tt" + annotationId.toString()}
          className="line-annotation-label"
          direction="center"
          offset={[0, 0]}
          permanent={true}
        >
          {text}
        </Tooltip>
      ) : (
        <></>
      )}
    </Polyline>
  );
};

export default LineAnnotation;
