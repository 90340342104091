import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WriteResource } from "../types";
import { RootState } from "../store";
import { downloadImage } from "../api";
import { Image } from "../models";

export interface DownloadImageForm {
  readonly image: Image;
}

export type DownloadImageDialogState =
  | {
      readonly isOpen: true;
      readonly image: WriteResource<DownloadImageForm, Image>;
      readonly imageUrl: string | null;
    }
  | {
      readonly isOpen: false;
      readonly imageUrl: string | null;
    };

// thunks
export const imageDownload = createAsyncThunk(
  "downloadImageDialog/imageDownload",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.downloadImageDialog;

    if (state.isOpen) {
      return await downloadImage(state.image.data.image.id);
    }
  }
);

export const downloadImageDialogSlice = createSlice({
  name: "downloadImageDialog",
  initialState: { isOpen: false } as DownloadImageDialogState,
  reducers: {
    openDownloadImageDialog: (_, action) => {
      return {
        isOpen: true,
        image: {
          data: {
            image: action.payload
          }
        },
        imageUrl: null,
        downloadResults: {
          data: null
        }
      };
    },
    closeDownloadImageDialog: state => {
      state.isOpen = false;
    },
    clearDownloadImageDialog: state => {
      state.imageUrl = null;
    },
    setDownloadImageForm: (state, action) => {
      if (state.isOpen) {
        state.image = {
          data: action.payload
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(imageDownload.fulfilled, (_, action) => {
      const imageUrl = action.payload && action.payload.url;
      return {
        isOpen: false,
        imageUrl: imageUrl
      } as DownloadImageDialogState;
    });
    builder.addCase(imageDownload.rejected, (state, action) => {
      if (state.isOpen) {
        state.image = {
          data: state.image.data,
          errorMessage: action.error.message
        };
      }
    });
  }
});

export const {
  openDownloadImageDialog,
  closeDownloadImageDialog,
  clearDownloadImageDialog,
  setDownloadImageForm
} = downloadImageDialogSlice.actions;

export default downloadImageDialogSlice.reducer;
