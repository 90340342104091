import { Box } from "@blasterjs/core";
import React from "react";
import TooltipFlyout from "./Tooltip";
import { HexColorPicker, HexColorInput } from "react-colorful";

export default ({
  rkey,
  color,
  onChange
}: {
  readonly rkey: string;
  readonly color: string;
  readonly onChange: (hexCode: string) => void;
}) => {
  const presetColors = [
    "#87FF01",
    "#FF001E",
    "#FF9F00",
    "#FDEB03",
    "#013BFA",
    "#8601FC",
    "#4A90E2",
    "#00FFFF",
    "#09FF98"
  ];
  return (
    <Box key={rkey} className="colorPicker" data-tooltip={true} background={color}>
      <TooltipFlyout
        placement={"bottom-end"}
        className="colorPickerTool"
        onClick={(e: React.MouseEvent) => e.preventDefault()}
      >
        <HexColorPicker color={color} onChange={onChange} />
        <div className="picker__swatches">
          {presetColors.map(presetColor => (
            <button
              key={presetColor}
              className="picker__swatch"
              style={{ background: presetColor }}
              onClick={() => onChange(presetColor)}
            />
          ))}
        </div>
        <HexColorInput color={color} onChange={onChange} />
      </TooltipFlyout>
    </Box>
  );
};
