import { Box, Button, Callout, Text } from "@blasterjs/core";
import React, { useState } from "react";

import { ImageStatusType, ImageWithAnnotations, Indication, QueryObjectType } from "../models";
import { CollapseIcon } from "./Expandable";
import ConfirmationDialog from "./ConfirmationDialog";
import ImageInfo from "./ImageInfo";
import InfoSidebar from "./InfoSidebar";
import { toggleSidebarExpanded, transitionImageStatus } from "../slices/caseImageViewer";
import type { AppDispatch } from "../store";
import QueryList from "./QueryList";
import { LoggedInUser, RolePermissions } from "../permissions";
import InfoSidebarCeliacTable from "./InfoSidebarCeliacTable";

interface Props {
  readonly imageWithAnnotations: ImageWithAnnotations;
  readonly loggedInUser: LoggedInUser;
  dispatch: AppDispatch;
}

const collapseIcon = (dispatch: AppDispatch) => (
  <CollapseIcon onToggle={() => dispatch(toggleSidebarExpanded())} />
);

const InfoSidebarImage = ({ imageWithAnnotations, loggedInUser, dispatch }: Props) => {
  const imageAndQuery = imageWithAnnotations.imageAndQuery;
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const onTransition = () => setConfirmationDialogOpen(true);
  const onConfirm = () => {
    setConfirmationDialogOpen(false);
    dispatch(transitionImageStatus(imageAndQuery.image));
  };
  const onCancel = () => {
    setConfirmationDialogOpen(false);
  };
  const confirmTransitionDialog = (
    <ConfirmationDialog
      title="Confirm Image Status Change"
      message="Are you sure you want to change this image's status?"
      isOpen={isConfirmationDialogOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
  const qcButton =
    loggedInUser.can([RolePermissions.AP_ImageViewer_ViewButtonPassLabQC]) &&
    imageAndQuery.image.status === ImageStatusType.LabQcPending ? (
      <Button iconAfter="check" block={true} onClick={onTransition}>
        Pass QC
      </Button>
    ) : loggedInUser.can([RolePermissions.AP_CaseViewer_ViewButtonPassISCQC]) &&
      imageAndQuery.image.status === ImageStatusType.IscQcPending ? (
      <Button appearance="prominent" iconAfter="check" block={true} onClick={onTransition}>
        Pass QC
      </Button>
    ) : "errorMessage" in imageAndQuery ? (
      <Callout intent="danger">
        <Text as="p">Unable to transition image status</Text>
      </Callout>
    ) : null;
  return (
    <InfoSidebar>
      <ImageInfo
        imageAndQuery={imageAndQuery}
        collapseIcon={collapseIcon(dispatch)}
        inQueryChain={false} // false because show/hide buttons are only for the case viewer
      />
      <QueryList
        histoCase={null}
        selectedImage={imageAndQuery}
        queryObjectType={QueryObjectType.Image}
        loggedInUser={loggedInUser}
      />
      <Box mt={2} mb={3}>
        {qcButton}
      </Box>
      {confirmTransitionDialog}

      {imageWithAnnotations.study?.indications.includes(Indication.CeliacDisease) ? (
        <InfoSidebarCeliacTable
          annotationClasses={imageWithAnnotations.multilineAnnotationClassesWithCount}
          annotations={imageWithAnnotations.annotations}
          imageId={imageWithAnnotations.imageAndQuery.image.id}
        />
      ) : null}
    </InfoSidebar>
  );
};

export default InfoSidebarImage;
