import React from "react";

import { Box, Button, Dialog, Select, Label } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  closeImageManagementBulkCaseDialog,
  DialogMode,
  setSelectedRole
} from "../slices/ImageManagementBulkCaseDialog";

const ImageManagementBulkCaseDialog = () => {
  const dispatch = useAppDispatch();

  const dialogMode = useAppSelector(state => state.imageManagementBulkCaseDialog.mode);
  const selectedRole = useAppSelector(state => state.imageManagementBulkCaseDialog.selectedRole);

  const closeDialog = () => {
    dispatch(closeImageManagementBulkCaseDialog());
  };
  const onSave = () => {
    if (selectedRole) {
      //dispatch(updateWorkAsRole(selectedRole)).then(() => window.location.reload());
    }
    closeDialog();
  };

  const onRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const roleId = e.currentTarget.value;
    dispatch(setSelectedRole(roleId));
  };

  return (
    <Dialog
      isOpen={dialogMode === DialogMode.OpenForEdit}
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      <DialogHeader title={"Move"} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>
              <Select onChange={onRoleChange} value={selectedRole || ""}>
                <option key="" value=""></option>
              </Select>
            </Label>
          </Box>
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button intent="primary" appearance="prominent" onClick={onSave}>
            Save
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default ImageManagementBulkCaseDialog;
