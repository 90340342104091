import { Icon, Label } from "@blasterjs/core";
import React from "react";

import { ImageAndQuery, QueryRecord, QueryResolutionType, QueryStatus } from "../models";
import TooltipText from "./TooltipText";

interface Props {
  readonly imageAndQuery: ImageAndQuery;
  readonly includeLabel?: true;
}

interface QueryData {
  readonly icon: React.ReactNode;
  readonly title: string;
}

function queryData(query: QueryRecord | null): QueryData | null {
  return query !== null
    ? query.queryStatus === QueryStatus.Open
      ? {
          icon: <Icon name="query" color="red" flexShrink="0" />,
          title: "Open Query"
        }
      : query.resolution == QueryResolutionType.Unresolvable
      ? {
          icon: <Icon name="queryUnresolveable" color="gray" flexShrink="0" />,
          title: "Unresolvable Query"
        }
      : {
          icon: <Icon name="query" color="orange" flexShrink="0" />,
          title: "Resolved Query"
        }
    : null;
  //   query.queryType === QueryType.PendingReview
  //   ? {
  //       icon: <Icon name="query" color="orange" flexShrink="0" />,
  //       title: "Answered Query"
  //     }
  //   : "queryResolution" in query && query.queryResolution === QueryResolutionType.Resolved
  //   ? {
  //       icon: <Icon name="queryResolved" color="gray" flexShrink="0" />,
  //       title: "Resolved Query"
  //     }
  //   : "queryResolution" in query && query.queryResolution === QueryResolutionType.Unresolvable
  //   ? {
  //       icon: <Icon name="queryUnresolveable" color="gray" flexShrink="0" />,
  //       title: "Unresolvable Query"
  //     }
  //   : null
  // : null;
}

const QueryIcon = ({ imageAndQuery, includeLabel }: Props) => {
  const data = queryData(imageAndQuery.lastLabQuery);
  const icon = data ? (
    <TooltipText text={data.title} style={{ fontSize: "1.2em" }}>
      {data.icon}
    </TooltipText>
  ) : null;
  return data && includeLabel ? (
    <>
      {icon}
      <Label fontSize={1} ml={"5px"}>
        {data.title}
      </Label>
    </>
  ) : (
    icon
  );
};

export default QueryIcon;
