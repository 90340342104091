import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../models";
import { fetchUser } from "../api";
import { Resource } from "../types";
import { castDraft } from "immer";
import { LoggedInUser } from "../permissions";

export interface AuthState {
  readonly user: Resource<User>;
  readonly loggedInUser: Resource<LoggedInUser>;
}

export const initialState: AuthState = {
  user: {
    isPending: false
  },
  loggedInUser: {
    isPending: false
  }
};

// thunks
export const userFetch = createAsyncThunk("auth/userFetch", async () => {
  const response = await fetchUser();
  return response;
});

export const checkLoggedIn = createAsyncThunk("auth/checkLoggedIn", async (_: void, thunkApi) => {
  const { dispatch } = thunkApi;
  // eslint-disable-next-line
  const response = await dispatch(userFetch);
  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout: (state, action: PayloadAction<string>) => {
      state.user = { errorMessage: "Please log in" };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    redirectAction: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line
      const a = 1;
    }
  },
  extraReducers: builder => {
    builder.addCase(userFetch.pending, state => {
      state.user = { isPending: true };
      state.loggedInUser = { isPending: true };
    });
    builder.addCase(userFetch.fulfilled, (state, action) => {
      state.user = { resource: castDraft(action.payload) };
      state.loggedInUser = { resource: castDraft(new LoggedInUser(action.payload)) };
    });
    builder.addCase(userFetch.rejected, (state, action) => {
      state.user = { errorMessage: action.error.message || "" };
      state.loggedInUser = { errorMessage: action.error.message || "" };
    });
  }
});

export const { logout, redirectAction } = authSlice.actions;
export default authSlice.reducer;
