import React from "react";
import Avatar from "react-avatar";
import { User } from "../models";

interface Props {
  readonly user: User;
  readonly isSmall?: boolean;
}

export default ({ user, isSmall }: Props) => {
  return (
    <Avatar
      name={
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.firstName
          ? user.firstName
          : user.username
      }
      size={isSmall ? "20" : "30"}
      round={true}
      textMarginRatio={0.1}
      textSizeRatio={30 / 14}
      style={{
        fontFamily: "InterVariable",
        zIndex: 100,
        position: "relative"
      }}
    />
  );
};
