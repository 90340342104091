import {
  Appearance,
  Box,
  Button,
  Callout,
  Heading,
  Icon,
  Label,
  Radio,
  Table,
  Text
} from "@blasterjs/core";
import React, { useState } from "react";
import styled from "styled-components";
import Avatar from "./Avatar";

import CaseDialog from "./CaseDialog";
import Content from "./Content";
import ConfirmationDialog from "./ConfirmationDialog";
import { CollapseIcon } from "./Expandable";
import ImageInfo from "./ImageInfo";
import InfoSidebar from "./InfoSidebar";
import AnnotationNestPanel from "./AnnotationNest";
import Timestamp from "./Timestamp";

import QueryList from "./QueryList";

import {
  CaseStatus,
  CaseWithImages,
  formatCaseStatus,
  Image,
  ImageAndQueries,
  ImageAndQuery,
  QueryObjectType,
  QueryStatus
} from "../models";
import { AppDispatch } from "../store";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  cancelImageAnnotation,
  selectImage,
  toggleSidebarExpanded,
  transitionCaseStatus
} from "../slices/caseImageViewer";
import { openEditCaseDialog } from "../slices/caseDialog";
import { RolePermissions } from "../permissions";

const RegularImage = styled.li`
  border-left: 2px solid transparent;
  padding-left: 3px;
  margin-left: -1rem;
  padding-top: 0.3rem;
  &:not(:last-child) {
    padding-bottom: 3px;
  }
`;

// Workaround since upgrading to styled-components v5 causes unwanted style changes and isn't being
// purused for now. See https://github.com/microsoft/TypeScript/issues/37597
const ResolvedImageQueryImage = styled(RegularImage as any)`
  border-color: gray;
`;

// Workaround since upgrading to styled-components v5 causes unwanted style changes and isn't being
// purused for now. See https://github.com/microsoft/TypeScript/issues/37597
const UnresolvedImageQueryImage = styled(RegularImage as any)`
  border-color: #dc3831;
`;

const ImageHiddenFromReaderLabel = styled.label`
  color: gray;
`;

const CompactTable = styled(Table)`
  width: 100%;
  td:first-child {
    font-weight: 600;
    padding-right: 1rem;
    width: 12rem;
  }
`;

const QueryChain = styled.ul`
  li:last-child {
    margin-bottom: 3px;
  }
`;

interface Props {
  readonly histoCase: CaseWithImages;
  readonly selectedImage: ImageAndQuery | null;
  readonly userCanDeleteAnnotations: boolean;
}

function imageQueriesWereClosed(imageQueries: ImageAndQueries): boolean {
  return imageQueries
    .map(imageAndQuery => imageAndQuery.lastLabQuery)
    .some(query => query && query.queryStatus == QueryStatus.Closed);
}

function imageQueriesNeedResolution(imageQueries: ImageAndQueries): boolean {
  return imageQueries
    .map(imageAndQuery => imageAndQuery.lastLabQuery)
    .some(query => query && query.queryStatus == QueryStatus.Open);
}

const onSelectImage = (image: Image, dispatch: AppDispatch) => async () => {
  // NOTE: It's important to dispatch this action to cancel any in-progress annotation to ensure the
  // annotation code cleans up after itself properly.
  dispatch(cancelImageAnnotation());
  await dispatch(selectImage(image.id));
};

const InfoSidebarCase = ({ histoCase, selectedImage, userCanDeleteAnnotations }: Props) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.auth.user);
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const onTransition = () => setConfirmationDialogOpen(true);
  const onConfirm = () => {
    setConfirmationDialogOpen(false);
    dispatch(transitionCaseStatus(histoCase.caseWithStatus));
  };
  const onCancel = () => {
    setConfirmationDialogOpen(false);
  };
  const confirmTransitionDialog = (
    <ConfirmationDialog
      title="Confirm Case Status Change"
      message="Are you sure you want to change this case's status?"
      isOpen={isConfirmationDialogOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );

  const button =
    "resource" in loggedInUser ? (
      loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewButtonPassLabQC]) &&
      histoCase.caseWithStatus.status === CaseStatus.PendingLabQC ? (
        <Button iconAfter="check" block={true} onClick={onTransition}>
          Pass QC
        </Button>
      ) : loggedInUser.resource.can([RolePermissions.AP_CaseViewer_ViewButtonPassISCQC]) &&
        histoCase.caseWithStatus.status === CaseStatus.PendingQC ? (
        <Button appearance="prominent" iconAfter="check" block={true} onClick={onTransition}>
          Pass QC
        </Button>
      ) : loggedInUser.resource.can([RolePermissions.AP_CaseViewer_MarkCaseasScored]) &&
        histoCase.caseWithStatus.status === CaseStatus.Processed ? (
        <Button appearance="prominent" iconAfter="check" block={true} onClick={onTransition}>
          Mark as Scored
        </Button>
      ) : "errorMessage" in histoCase ? (
        <Callout intent="danger">
          <Text as="p">Unable to transition case status</Text>
        </Callout>
      ) : null
    ) : null;
  const editIcon = "resource" in loggedInUser &&
    loggedInUser.resource.can([RolePermissions.S_CaseListTab_EditCase]) && (
      <Button
        value={histoCase.caseWithStatus.id}
        onClick={() =>
          dispatch(
            openEditCaseDialog({
              id: histoCase.caseWithStatus.id,
              studyId: histoCase.caseWithStatus.studyId,
              refreshSingleCaseOnSuccess: true
            })
          )
        }
        appearance={Appearance.MINIMAL}
        title="Edit Case"
      >
        <Icon name="edit" />
      </Button>
    );
  function inQueryChain(imageAndQuery: ImageAndQuery): boolean {
    if ("imageQueries" in histoCase) {
      const allImageQueries: readonly ImageAndQueries[] = histoCase.imageQueries || [];
      const imageQueries: ImageAndQueries =
        allImageQueries.find(iq => {
          return iq.length > 0 && iq.find(x => x.image.id == imageAndQuery.image.id) != null;
        }) || [];
      return imageQueriesNeedResolution(imageQueries) || imageQueriesWereClosed(imageQueries);
    } else {
      return false;
    }
  }
  const content =
    "resource" in loggedInUser ? (
      <>
        <Box display="flex" width="100%">
          <Heading textAlign="left" as="h6" mb={1}>
            Case
          </Heading>
          <Box ml={"auto"}>
            {editIcon}
            <CollapseIcon onToggle={() => dispatch(toggleSidebarExpanded())} />
          </Box>
        </Box>
        <CompactTable fontSize={1} compact={true}>
          <tbody>
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>Endo Procedure&nbsp;ID</td>
              <td>{histoCase.caseWithStatus.procId}</td>
            </tr>
            <tr>
              <td style={{ whiteSpace: "nowrap" }}>Histo Procedure&nbsp;ID</td>
              <td>{histoCase.caseWithStatus.histoProcedureId}</td>
            </tr>
            {"subjectId" in histoCase.caseWithStatus &&
              loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewSubjectID]) && (
                <tr>
                  <td>Subject&nbsp;ID</td>
                  <td>{histoCase.caseWithStatus.subjectId}</td>
                </tr>
              )}
            {"visitId" in histoCase.caseWithStatus &&
              loggedInUser.resource.can([RolePermissions.AP_CaseData_ViewVisitID]) && (
                <tr>
                  <td>Visit&nbsp;ID</td>
                  <td>{histoCase.caseWithStatus.visitId}</td>
                </tr>
              )}
            {"siteId" in histoCase.caseWithStatus &&
              loggedInUser.resource.can([RolePermissions.AP_CaseData_EditSiteID]) && ( // TODO: no ViewSiteID
                <tr>
                  <td>Site&nbsp;ID</td>
                  <td>{histoCase.caseWithStatus.siteId}</td>
                </tr>
              )}
            <tr>
              <td>Created</td>
              <td>
                <Timestamp date={histoCase.caseWithStatus.createdAt} />
              </td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{formatCaseStatus(histoCase.caseWithStatus.status)}</td>
            </tr>
            {"readers" in histoCase ? (
              <tr>
                <td>Assigned</td>
                <td>
                  {histoCase.readers.length ? (
                    <span>
                      {histoCase.readers.slice(0, 5).map(readerStats => (
                        <Box key={readerStats.reader.id} ml="1px" mr="1px" display="inline">
                          <Avatar isSmall={true} user={readerStats.reader} />
                        </Box>
                      ))}
                      {histoCase.readers.length > 5 ? "…" : null}
                    </span>
                  ) : (
                    "—"
                  )}
                </td>
              </tr>
            ) : null}
            {"resource" in loggedInUser &&
            "images" in histoCase &&
            histoCase.images.length &&
            !("imageQueries" in histoCase && histoCase.imageQueries.length) ? (
              <>
                <tr>
                  <td>Images</td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <ul>
                      {histoCase.images.map(image => {
                        const isSelected =
                          selectedImage !== null && image.id === selectedImage.image.id;
                        return (
                          <li key={image.id}>
                            <Label style={{ display: "flex", cursor: "pointer", marginTop: "5px" }}>
                              <Radio
                                name="caseImages"
                                value={image.id}
                                key={image.id}
                                checked={isSelected}
                                onChange={onSelectImage(image, dispatch)}
                                style={{
                                  pointerEvents: "none",
                                  marginTop: "-3px",
                                  marginRight: "2px",
                                  flexShrink: 0
                                }}
                              />
                              <Text
                                fontSize={1}
                                lineHeight="1.3"
                                flex="1"
                                style={{ pointerEvents: "none", wordBreak: "break-all" }}
                              >
                                {image.name}
                              </Text>
                            </Label>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              </>
            ) : "imageQueries" in histoCase && histoCase.imageQueries.length ? (
              <>
                <tr>
                  <td>Images</td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: "10px" }} colSpan={2}>
                    <ul style={{ marginTop: "5px" }}>
                      {histoCase.imageQueries.map((imageQueries, index) => {
                        const queryChain = imageQueries.map(imageAndQuery => {
                          const isSelected =
                            selectedImage !== null &&
                            imageAndQuery.image.id === selectedImage.image.id;
                          const label = imageAndQuery.isShownToReader ? (
                            <label>{imageAndQuery.image.name}</label>
                          ) : (
                            <ImageHiddenFromReaderLabel>
                              {imageAndQuery.image.name}
                            </ImageHiddenFromReaderLabel>
                          );
                          const content = (
                            <>
                              <Label style={{ display: "flex", cursor: "pointer" }}>
                                <Radio
                                  name="caseImages"
                                  value={imageAndQuery.image.id}
                                  key={imageAndQuery.image.id}
                                  checked={isSelected}
                                  onChange={onSelectImage(imageAndQuery.image, dispatch)}
                                  style={{
                                    pointerEvents: "none",
                                    marginTop: "-3px",
                                    marginRight: "2px",
                                    flexShrink: 0
                                  }}
                                />
                                <Text
                                  fontSize={1}
                                  lineHeight="1.3"
                                  flex="1"
                                  style={{ pointerEvents: "none", wordBreak: "break-all" }}
                                >
                                  {label}
                                </Text>
                              </Label>
                            </>
                          );
                          return imageQueriesNeedResolution(imageQueries) ? (
                            <UnresolvedImageQueryImage key={imageAndQuery.image.id}>
                              {content}
                            </UnresolvedImageQueryImage>
                          ) : imageQueriesWereClosed(imageQueries) ? (
                            <ResolvedImageQueryImage key={imageAndQuery.image.id}>
                              {content}
                            </ResolvedImageQueryImage>
                          ) : (
                            <RegularImage key={imageAndQuery.image.id}>{content}</RegularImage>
                          );
                        });
                        return (
                          <li key={index}>
                            <QueryChain key={index}>{queryChain}</QueryChain>
                          </li>
                        );
                      })}
                    </ul>
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </CompactTable>
        <Box mt={2} mb={3}>
          {button}
        </Box>
        <Box mt={1} mb={2}>
          <hr />
        </Box>
        {"resource" in loggedInUser && selectedImage !== null ? (
          <>
            <ImageInfo
              imageAndQuery={selectedImage}
              inQueryChain={
                inQueryChain(
                  selectedImage
                ) /*"imageQueries" in histoCase && histoCase.imageQueries.length > 0 || selectedImage.image.lastLabQueryId != null*/
              }
            />
            {"resource" in loggedInUser &&
              loggedInUser.resource.can([RolePermissions.AP_ImageViewer_ViewAnnotations]) && (
                <AnnotationNestPanel
                  userCanDeleteAnnotations={userCanDeleteAnnotations}
                  imageId={selectedImage.image.id}
                />
              )}
          </>
        ) : null}
        {confirmTransitionDialog}

        {"resource" in loggedInUser &&
        loggedInUser.resource.can([RolePermissions.AP_CaseViewer_ViewQueries]) ? (
          <div>
            <Box mt={3} mb={2}>
              <hr />
            </Box>
            <QueryList
              histoCase={histoCase}
              selectedImage={selectedImage}
              queryObjectType={QueryObjectType.Case}
              loggedInUser={loggedInUser.resource}
            />
          </div>
        ) : null}
      </>
    ) : null;
  return (
    <>
      <InfoSidebar>
        <Content isLoading={"isPending" in user || "isPending" in histoCase}>{content}</Content>
      </InfoSidebar>
      <CaseDialog />
    </>
  );
};

export default InfoSidebarCase;
