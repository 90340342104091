import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Resource } from "../types";
import { castDraft } from "immer";
import { Roles } from "../models";
import { fetchRoles } from "../api";

export interface PermissionsState {
  readonly roles: Resource<Roles>;
}

export const initialState: PermissionsState = {
  roles: {
    isPending: false
  }
};

// thunks
export const rolesFetch = createAsyncThunk("permissions/rolesFetch", async (_: void) => {
  const response = await fetchRoles();
  return response;
});

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(rolesFetch.pending, state => {
      state.roles = { isPending: true };
    });
    builder.addCase(rolesFetch.fulfilled, (state, action) => {
      state.roles = { resource: castDraft(action.payload) };
    });
    builder.addCase(rolesFetch.rejected, (state, action) => {
      state.roles = { errorMessage: action.error.message || "" };
    });
  }
});

//export const {  } = permissionsSlice.actions;
export default permissionsSlice.reducer;
