import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReportStudyData, ReportUserData } from "../models";
import type { RootState } from "../store";
import { downloadAudit } from "../api";

export interface AuditTrailState {
  readonly studyData?: ReportStudyData;
  readonly userData?: ReportUserData;
  readonly requestingCsv: boolean;
  readonly errorMessage?: string;
}

export const initialState: AuditTrailState = {
  studyData: undefined,
  userData: undefined,
  requestingCsv: false,
  errorMessage: undefined
};

// thunks
export const requestAuditTrailPdf = createAsyncThunk(
  "auditTrail/requestAuditTrailPdf",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await downloadAudit(
      "pdf",
      state.auditTrail.studyData,
      state.auditTrail.userData
    );
    return response;
  }
);

export const requestAuditTrailCsv = createAsyncThunk(
  "auditTrail/requestAuditTrailCsv",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await downloadAudit(
      "csv",
      state.auditTrail.studyData,
      state.auditTrail.userData
    );
    return response;
  }
);

export const auditTrailSlice = createSlice({
  name: "auditTrail",
  initialState: initialState,
  reducers: {
    setAuditTrailStudyData: (state, action: PayloadAction<ReportStudyData | undefined>) => {
      state.studyData = action.payload || undefined;
    },
    setAuditTrailUserData: (state, action: PayloadAction<ReportUserData | undefined>) => {
      state.userData = action.payload || undefined;
    }
  },
  extraReducers: builder => {
    builder.addCase(requestAuditTrailCsv.pending, state => {
      state.requestingCsv = true;
      state.errorMessage = undefined;
    });
    builder.addCase(requestAuditTrailCsv.fulfilled, state => {
      state.requestingCsv = false;
    });
    builder.addCase(requestAuditTrailCsv.rejected, (state, action) => {
      state.requestingCsv = false;
      state.errorMessage = action.error.message;
    });
    builder.addCase(requestAuditTrailPdf.pending, state => {
      state.requestingCsv = true;
      state.errorMessage = undefined;
    });
    builder.addCase(requestAuditTrailPdf.fulfilled, state => {
      state.requestingCsv = false;
    });
    builder.addCase(requestAuditTrailPdf.rejected, (state, action) => {
      state.requestingCsv = false;
      state.errorMessage = action.error.message;
    });
  }
});

export const { setAuditTrailStudyData, setAuditTrailUserData } = auditTrailSlice.actions;

export default auditTrailSlice.reducer;
