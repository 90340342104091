import React from "react";

import { formateDateToYearMonthDay, LOCALE } from "../utils";
import TooltipText from "./TooltipText";

interface Props {
  readonly date: Date | null;
}

const DateYearMonthDay = ({ date }: Props) => {
  const formattedDate = formateDateToYearMonthDay(date);
  return date == null ? (
    <span>{formattedDate}</span>
  ) : (
    <TooltipText text={date.toLocaleDateString(LOCALE) + " " + date.toLocaleTimeString(LOCALE)}>
      {formattedDate}
    </TooltipText>
  );
};

export default DateYearMonthDay;
