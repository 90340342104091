export interface ResourcePending {
  readonly isPending: boolean;
}
export interface ResourceSuccess<T> {
  readonly resource: T;
}
export interface ResourceFailure {
  readonly errorMessage: string;
}

export interface ResourceWritePending<D> {
  readonly data: D;
  readonly isPending: true;
}
export interface ResourceWriteEditing<D> {
  readonly data: D;
}
export interface ResourceWriteSaving<D> {
  readonly data: D;
  readonly isSaving: true;
}
export interface ResourceWriteSuccess<D, T> {
  readonly data: D;
  readonly resource: T;
}
export interface ResourceWriteFailure<D> {
  readonly data: D;
  readonly errorMessage: string;
}

// Resource being fetched (read-only)
export type Resource<T> = ResourcePending | ResourceSuccess<T> | ResourceFailure;

// Resource that is saved (create) or fetched and saved (updated)
export type WriteResource<D, T> =
  | ResourceWritePending<D>
  | ResourceWriteEditing<D>
  | ResourceWriteSaving<D>
  | ResourceWriteSuccess<D, T>
  | ResourceWriteFailure<D>;

/*
 * Helper function to get exhaustiveness checking.
 *
 * See: https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 */
export function assertNever(x: never): never {
  /* eslint-disable-next-line functional/no-throw-statement */
  throw new Error(`Unexpected: ${x}`);
}
