import React from "react";
import { Callout, Intent, Text } from "@blasterjs/core";
import Page from "../components/Page";
import Content from "../components/Content";
import { useAppSelector } from "../hooks";

const Login = () => {
  const config = useAppSelector(state => state.config);
  const user = useAppSelector(state => state.auth.user);

  const configErrorText =
    "errorMessage" in config.config ? (
      <Callout intent={Intent.DANGER}>
        <Text>{config.config.errorMessage}</Text>
      </Callout>
    ) : null;
  const loginErrorText =
    "errorMessage" in user ? (
      <Callout intent={Intent.WARNING}>
        <Text>{user.errorMessage}</Text>
      </Callout>
    ) : null;
  const errorText = configErrorText || loginErrorText;
  const isLoading =
    ("isPending" in config.config && config.config.isPending) ||
    ("isPending" in user && user.isPending);
  const content = errorText ? errorText : null;
  return (
    <Page>
      <Content isLoading={isLoading}>{content}</Content>
    </Page>
  );
};

export default Login;
