import { ProcessedImageWithAnnotations, ZoomDefinition } from "../models";
import { Button, Icon } from "@blasterjs/core";
import React, { useEffect, useState } from "react";

import AnnotationButtons from "./AnnotationButtons";
import BrightnessAdjust from "./BrightnessAdjust";
import ImageZoom from "./ImageZoom";
import L, { LatLngBounds } from "leaflet";
import MicroscopeMask from "./MicroscopeMask";
import ScaleBar from "./ScaleBar";
import ToggleAnnotations from "./ToggleAnnotations";
import ZStackSlider from "./ZStackSlider";
import styled from "styled-components";
import LocatorMap from "./LocatorMap";
import { toLatLngBounds } from "../utils";
import { useAppDispatch } from "../hooks";
import { adjustBrightness, toggleMicroscropeActive } from "../slices/caseImageViewer";
import { LoggedInUser, RolePermissions } from "../permissions";

const ZStack = styled.div`
  position: absolute;
  z-index: 20000;
  padding: 0;
  left: 15px;
  top: 15px;
  display: flex;
`;

const ImageControls = styled.div`
  position: absolute;
  z-index: 20000;
  padding: 0;
  left: 15px;
  bottom: 15px;
  display: flex;
`;

const ImageButtons = styled.div`
  position: absolute;
  z-index: 20000;
  padding: 0;
  right: 15px;
  bottom: 15px;
  display: flex;
`;

const MicroscopeModeButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  margin-left: 3px;
  margin-right: 3px;
  &.active {
    box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 4px 4px rgb(16 22 26 / 20%);
    background-image: none;
    background-color: #dde0e6;
  }
  &:hover,
  &:active {
    background-color: #f0f2f5;
  }
  background-color: rgb(245, 248, 250);
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0));
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%);
  color: #3d3e40;
`;

interface MapControlsProps {
  readonly mapElement: L.Map;
  readonly maxZoom: number;
  readonly minZoom: number;
  readonly imageWithAnnotations: ProcessedImageWithAnnotations;
  readonly isAnnotationInProgress: boolean;
  readonly loggedInUser: LoggedInUser;
  readonly zoom: number;
  readonly magnifications: ReadonlyArray<ZoomDefinition>;
  readonly isMicroscopeActive: boolean;
  readonly resolutionInMeters: number;
  readonly tileUrl: string | null;
  readonly mapBounds: LatLngBounds | undefined;
  readonly setMapBounds: (bounds: LatLngBounds) => void;
  readonly hpfCursorVisible: boolean;
  readonly setHpfCursorVisible: (flag: boolean) => void;
  readonly setShowLineLabels: (flag: boolean) => void;
}

const MapControls = ({
  mapElement,
  maxZoom,
  minZoom,
  imageWithAnnotations,
  isAnnotationInProgress,
  loggedInUser,
  zoom,
  magnifications,
  isMicroscopeActive,
  resolutionInMeters,
  tileUrl,
  mapBounds,
  setMapBounds,
  hpfCursorVisible,
  setHpfCursorVisible,
  setShowLineLabels
}: MapControlsProps) => {
  const [zStackIndex, setZStackIndex] = useState(0);

  const dispatch = useAppDispatch();

  // Switch between z-stacked tile layers
  useEffect(() => {
    mapElement.eachLayer((layer: L.Layer) => {
      // The Leaflet types are unfortunately not right here. Layers have options which contain
      // whatever extra props are set in TileLayer and also have a `setZIndex` method.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      "zStackIndex" in layer.options &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        layer.setZIndex(layer.options.zStackIndex === zStackIndex ? 1000 : 1);
    });
  }, [imageWithAnnotations, zStackIndex]);

  const onBrightnessChange = (newValue: number) => {
    dispatch(adjustBrightness(newValue));
  };

  const onBrightnessReset = () => {
    dispatch(adjustBrightness(0));
  };

  const { pixelSize, extent, zStackSize } = imageWithAnnotations.imageAndQuery.image;

  return (
    <>
      <ZStack>
        {zoom === maxZoom && zStackSize && zStackSize > 1 && (
          <ZStackSlider onChange={setZStackIndex} zStackSize={zStackSize} />
        )}
      </ZStack>
      <ImageControls>
        {mapElement ? (
          <LocatorMap
            parentMap={mapElement}
            mapBounds={mapBounds}
            setMapBounds={setMapBounds}
            zoom={zoom}
            tileUrl={tileUrl}
            minZoom={minZoom}
            imageBounds={toLatLngBounds(extent)}
          ></LocatorMap>
        ) : null}

        <ImageZoom
          zoom={zoom}
          zoomDefinitions={magnifications}
          zoomIn={() => mapElement.zoomIn()}
          zoomOut={() => mapElement.zoomOut()}
        />
        <MicroscopeModeButton
          title="Microscope Mode"
          appearance="minimal"
          className={isMicroscopeActive ? "active" : null}
          onClick={() => dispatch(toggleMicroscropeActive())}
        >
          <Icon name="microscope" />
        </MicroscopeModeButton>
        <BrightnessAdjust onChange={onBrightnessChange} onReset={onBrightnessReset} />
        <ScaleBar
          leafletMapElement={mapElement}
          pixelSize={pixelSize}
          resolutionInMeters={resolutionInMeters}
        />
      </ImageControls>
      <ImageButtons>
        <ToggleAnnotations />
        {loggedInUser.can([RolePermissions.AP_ImageViewer_ViewAnnotationsToolbar]) ? (
          <AnnotationButtons
            loggedInUser={loggedInUser}
            imageId={imageWithAnnotations.imageAndQuery.image.id}
            isAnnotationInProgress={isAnnotationInProgress}
            hpfAnnotationCount={imageWithAnnotations.hpfAnnotationCount}
            pointAnnotationCount={imageWithAnnotations.pointAnnotationCount}
            freehandAnnotationCount={imageWithAnnotations.freehandAnnotationCount}
            hpfCursorVisible={hpfCursorVisible}
            setHpfCursorVisible={setHpfCursorVisible}
            setShowLineLabels={setShowLineLabels}
          />
        ) : null}
      </ImageButtons>
      {isMicroscopeActive ? (
        <MicroscopeMask
          map={mapElement}
          pixelSize={pixelSize}
          resolutionInMeters={resolutionInMeters}
          indications={imageWithAnnotations.study?.indications || []}
        />
      ) : null}
    </>
  );
};

export default MapControls;
