import DataTable from "react-data-table-component";
import styled from "styled-components";

// NOTE: The underlying DataTable component gets confused if `cols` is initially an empty array so that table should not be rendered until everything required to define `cols` is available.
export const StyledDataTable = styled(DataTable)`
  * {
    transition: none !important;
  }

  .rdt_Table {
    height: auto;
  }
  .rdt_Table .rdt_TableCell {
    font-weight: 400;
  }
  .rdt_TableHeadRow .rdt_TableCol > div {
    font-weight: 500;
  }
  .rdt_TableCol_Sortable {
    cursor: pointer;
  }
  .rdt_TableCol_Sortable span {
  }
  .rdt_TableCol > div {
    margin-left: 0;
  }
  .rdt_TableCol:first-child,
  .rdt_TableCell:first-child {
    padding-left: 1rem;
  }
  i,
  svg {
    transition: none !important;
  }
  .rdt_TableRow:hover {
    border-bottom-color: ${props => props.theme.colors.gray200};
    outline-style: inherit;
    outline-width: inherit;
    background-color: ${props => props.theme.colors.gray100};
  }
  .rdt_TableCol:last-child,
  .rdt_TableCell:last-child {
    padding-right: 1rem;
  }
  .rdt_TableCol {
    font-size: 1.6rem;
  }
  .rdt_TableRow {
    font-size: 1.6rem;
  }
`;

export const TableContainer = styled.div`
  flex: 1;
  max-width: 144rem;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid ${props => props.theme.colors.gray200};
`;

export const TableFilters = styled.div`
  padding: 1rem 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.gray200};
`;
