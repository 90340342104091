import { Appearance, Box, Button, Text, Icon, Checkbox } from "@blasterjs/core";
import React, { useState } from "react";
import TooltipFlyout, { TooltipLink } from "../components/Tooltip";
import ConfirmationDialog from "./ConfirmationDialog";
import styled from "styled-components";
import { UUID, Fragment, HpfAnno, PointAnno, Indication } from "../models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown, faCircle as solidCircle } from "@fortawesome/free-solid-svg-icons";
//import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { useAppDispatch, useAppSelector } from "../hooks";
import type { AppDispatch } from "../store";
import {
  deleteAnnotation,
  setImageAnnotationHighlight,
  setImageAnnotationUnhighlight,
  toggleCeliacTableVisibility,
  toggleHpfVisibility
} from "../slices/caseImageViewer";
import { mapStateToFragments } from "../utils";
import InfoSidebarCeliacTable from "./InfoSidebarCeliacTable";

library.add(faUser);
library.add(faCircle);
library.add(solidCircle);
library.add(faCircleDot);
library.add(faCaretRight);
library.add(faCaretDown);

const ORPHAN = "Orphan";

interface Props {
  readonly imageId: UUID | null;
  readonly userCanDeleteAnnotations: boolean;
}

const TreeBox = styled.div`
  display: flex;
  padding: 1px;
  flex-direction: column;
  width: 300px;
`;

const TreeRow = styled.div`
  display: block;
  padding: 1px;
  flex-direction: row;
  vertical-align: middle;
  text-align: left;
  float: left;
  height: 32px;
  width: 300px;
  layout: row;
`;

const PointTreeRow = styled.div`
  display: block;
  padding: 1px;
  padding-left: 60px;
  flex-direction: row;
  vertical-align: middle;
  text-align: left;
  float: left;
  height: 32px;
  width: 300px;
  layout: row;
`;

const AnnoNextHeader = styled.div`
  display: flex;
  align-items: flex-end;
`;

const deleteAllHpfs = (f: Fragment, dispatch: AppDispatch) => () => {
  f.hpfs &&
    f.hpfs.forEach(hpf => {
      dispatch(deleteAnnotation({ annotationId: hpf.id, deleteNested: false }));
    });
};

const deleteAllPoints = (f: Fragment, dispatch: AppDispatch) => () => {
  f.hpfs &&
    f.hpfs.forEach(hpf => {
      hpf.points &&
        hpf.points.forEach(p => {
          p.pointIds.forEach(pId => {
            dispatch(deleteAnnotation({ annotationId: pId, deleteNested: false }));
          });
        });
    });
};

const deleteAllHpfsAndPoints = (f: Fragment, dispatch: AppDispatch) => () => {
  f.hpfs &&
    f.hpfs.forEach(hpf => {
      dispatch(deleteAnnotation({ annotationId: hpf.id, deleteNested: true }));
    });
};

const deleteHpf = (hpf: HpfAnno, dispatch: AppDispatch) => () => {
  dispatch(deleteAnnotation({ annotationId: hpf.id, deleteNested: false }));
};

const deleteHpfAndPoints = (hpf: HpfAnno, dispatch: AppDispatch) => () => {
  dispatch(deleteAnnotation({ annotationId: hpf.id, deleteNested: true }));
};

const deletePoints = (pointAnno: PointAnno, dispatch: AppDispatch) => () => {
  pointAnno.pointIds.forEach(pId => {
    dispatch(deleteAnnotation({ annotationId: pId, deleteNested: false }));
  });
};

const TreeCellPart = styled.div`
  float: left;
  classname: "treeController";
`;

export function copyText(t: string): string {
  const p = navigator.clipboard.writeText(t);
  const pr1 = p.catch(err => err).then(() => "done");
  return t + pr1.toString();
}

function toggleOpenTree(hele: HTMLElement, branchId: string) {
  const branchEle = document.getElementById(branchId);
  if (hele.classList.contains("treeIsOpen")) {
    hele.classList.remove("treeIsOpen");
    hele.classList.add("treeIsClosed");
    branchEle && branchEle.classList.remove("treeBranchOpen");
    branchEle && branchEle.classList.add("treeBranchClosed");
  } else {
    hele.classList.remove("treeIsClosed");
    hele.classList.add("treeIsOpen");
    branchEle && branchEle.classList.remove("treeBranchClosed");
    branchEle && branchEle.classList.add("treeBranchOpen");
  }
}

function highlightHpf(hele: HTMLElement, dispatch: AppDispatch) {
  const hpfId = hele.getAttribute("data-hpf-id");
  if (hpfId) {
    dispatch(setImageAnnotationHighlight(hpfId));
  }
}

function unhighlightHpf(hele: HTMLElement, dispatch: AppDispatch) {
  const hpfId = hele.getAttribute("data-hpf-id");
  if (hpfId) {
    dispatch(setImageAnnotationUnhighlight(hpfId));
  }
}

function highlightPoints(hele: HTMLElement, pt: PointAnno, dispatch: AppDispatch) {
  pt.pointIds.forEach(pointId => {
    dispatch(setImageAnnotationHighlight(pointId));
  });
}

function unhighlightPoints(hele: HTMLElement, pt: PointAnno, dispatch: AppDispatch) {
  pt.pointIds.forEach(pointId => {
    dispatch(setImageAnnotationUnhighlight(pointId));
  });
}

const HpfTreeRow = styled.div`
  display: block;
  padding: 2px;
  padding-left: 30px;
  flex-direction: row;
  vertical-align: middle;
  text-align: left;
  float: left;
  height: 32px;
  width: 300px;
  layout: row;
  onMouseEnter: {(e: MouseEvent) =>
    const hele = e.currentTarget as HTMLElement
    const hpfId = hele.getAttribute("data-hpf-id");
    if (hpfId) {
      store.dispatch(setImageAnnotationHighlight(hpfId));
    }
  };
                              //highlightHpf(e.currentTarget as HTMLElement)
                          onMouseLeave: {(e: MouseEvent) =>
                            unhighlightHpf(e.currentTarget as HTMLElement)
                          };
`;

function noop(): void {
  // don't do anything
}

export const AnnotationNestPanel = ({ userCanDeleteAnnotations }: Props) => {
  const dispatch = useAppDispatch();
  const topLevelAnnotations = useAppSelector(state => mapStateToFragments(state));

  const [isConfirmationDialog, setConfirmationDialogOpen] = useState({
    open: false,
    message: "Are you sure you want to delete the annotation?",
    onConfirm: noop
  });
  const onConfirm = (f: () => void) => () => {
    setConfirmationDialogOpen({ open: false, message: "the annotations", onConfirm: noop });
    f();
  };
  const onTransition = (m: string, f: () => void) => () =>
    setConfirmationDialogOpen({
      open: true,
      message: "Are you sure you want to delete " + m + "?",
      onConfirm: onConfirm(f)
    });
  const onCancel = () => {
    setConfirmationDialogOpen({ open: false, message: "", onConfirm: noop });
  };

  const confirmTransitionDialog = (
    <ConfirmationDialog
      title="Confirm Annotation Delete"
      message={isConfirmationDialog.message}
      isOpen={isConfirmationDialog.open}
      onConfirm={isConfirmationDialog.onConfirm}
      onCancel={onCancel}
    />
  );

  const hpfVisibility = useAppSelector(state => state.caseImageViewer.hpfVisibility);
  const celiacTableVisibility = useAppSelector(
    state => state.caseImageViewer.celiacTableVisibility
  );

  const imageWithAnnotations = useAppSelector(state => state.caseImageViewer.imageWithAnnotations);

  return (
    <>
      {confirmTransitionDialog}
      <AnnoNextHeader>
        <Text style={{ fontWeight: "bold" }}>Annotations</Text>
      </AnnoNextHeader>
      <TreeBox>
        <>
          {topLevelAnnotations.flatMap((anno, aidx) => {
            const hasOrphan: boolean =
              (anno?.hpfs && anno.hpfs.length > 0 && anno.hpfs[0]?.name === ORPHAN) || false;
            return (
              <div key={aidx}>
                <TreeRow key={aidx} className="highlight_anno_1 treeRow">
                  {anno.isOpen ? (
                    <>
                      <TreeCellPart>
                        <Box
                          className="treeController treeIsOpen"
                          data-frag-name="abc"
                          onClick={(e: MouseEvent) =>
                            e &&
                            e.currentTarget &&
                            toggleOpenTree(
                              e.currentTarget as HTMLElement,
                              "branch_" + aidx.toString()
                            )
                          }
                        >
                          <Box className="closedTree">
                            <FontAwesomeIcon icon={faCaretRight} />
                          </Box>
                          <Box className="openTree">
                            <FontAwesomeIcon icon={faCaretDown} />
                          </Box>
                        </Box>
                      </TreeCellPart>
                      <Box className="treeCell" color={anno.color}>
                        <FontAwesomeIcon icon={faCircle} />
                        &nbsp;
                        <Text style={{ color: "black" }}>{anno.name}</Text>
                        {!userCanDeleteAnnotations ? (
                          <></>
                        ) : (
                          <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
                            <Icon name="menu" />
                            {anno.name === "Other" ? (
                              <TooltipFlyout placement={"left-start"}>
                                <TooltipLink
                                  onClick={onTransition(
                                    "all unassigned annotations on " + anno.name,
                                    deleteAllPoints(anno, dispatch)
                                  )}
                                >
                                  Delete all unassigned Points
                                </TooltipLink>
                              </TooltipFlyout>
                            ) : (
                              <TooltipFlyout placement={"left-start"}>
                                <TooltipLink
                                  onClick={onTransition(
                                    "all the HPFS on " + anno.name,
                                    deleteAllHpfs(anno, dispatch)
                                  )}
                                >
                                  Delete All HPFs
                                </TooltipLink>
                                <TooltipLink
                                  onClick={onTransition(
                                    "all the HPFS and points on " + anno.name,
                                    deleteAllHpfsAndPoints(anno, dispatch)
                                  )}
                                >
                                  Delete All HPFs and Points
                                </TooltipLink>
                              </TooltipFlyout>
                            )}
                          </Button>
                        )}
                      </Box>
                      <Box className="hangRight">
                        <Checkbox
                          checked={
                            !hpfVisibility?.find(
                              x =>
                                x.hpfClassid === anno.id &&
                                x.hpfId === null &&
                                x.pointClassId === null
                            )
                          }
                          onChange={() => {
                            dispatch(
                              toggleHpfVisibility({
                                fragment: anno,
                                hpfVisibility: {
                                  hpfClassid: anno.id || null,
                                  hpfId: null,
                                  pointClassId: null
                                }
                              })
                            );
                          }}
                        ></Checkbox>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={(e: MouseEvent) =>
                          e && e.target && (e.target as HTMLSpanElement).innerText
                        }
                      >
                        open f
                      </Button>
                      <Box>{anno.name}</Box>
                    </>
                  )}
                </TreeRow>
                <Box id={"branch_" + aidx.toString()} className="treeBranchOpen">
                  {anno.hpfs?.map((hpf, hidx) => (
                    <div key={aidx.toString() + "_" + hidx.toString()}>
                      <HpfTreeRow
                        key={aidx.toString() + "_" + hidx.toString()}
                        className="highlight_anno_1 treeRow"
                      >
                        <Box
                          style={{
                            border: "thin empty black",
                            height: "30px",
                            backgroundColor: "transparent"
                          }}
                          data-hpf-id={hpf.id}
                          onMouseEnter={(e: MouseEvent) =>
                            highlightHpf(e.currentTarget as HTMLElement, dispatch)
                          }
                          onMouseLeave={(e: MouseEvent) =>
                            unhighlightHpf(e.currentTarget as HTMLElement, dispatch)
                          }
                        >
                          <TreeCellPart>
                            <Box
                              className="treeController treeIsOpen"
                              data-frag-name="abc"
                              onClick={(e: MouseEvent) =>
                                e &&
                                e.currentTarget &&
                                toggleOpenTree(
                                  e.currentTarget as HTMLElement,
                                  "branch_" + aidx.toString() + "_" + hidx.toString()
                                )
                              }
                            >
                              <Box className="closedTree">
                                <FontAwesomeIcon icon={faCaretRight} />
                              </Box>
                              <Box className="openTree">
                                <FontAwesomeIcon icon={faCaretDown} />
                              </Box>
                            </Box>
                          </TreeCellPart>
                          <Box className="treeCell" color={hpf.color}>
                            <FontAwesomeIcon icon={solidCircle} />
                            &nbsp;
                            <Text style={{ color: "black" }}>
                              {hpf.name || "HPF " + (hidx + (hasOrphan ? 0 : 1)).toString()}
                            </Text>
                            {hpf.name === ORPHAN || !userCanDeleteAnnotations ? (
                              <></>
                            ) : (
                              <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
                                <Icon name="menu" />
                                <TooltipFlyout placement={"left-start"}>
                                  {hpf.id !== "no-id" ? (
                                    <TooltipLink
                                      onClick={onTransition(
                                        "the " +
                                          (hpf.name ||
                                            "HPF " + (hidx + (hasOrphan ? 0 : 1)).toString()) +
                                          " HPF",
                                        deleteHpf(hpf, dispatch)
                                      )}
                                    >
                                      Delete HPF
                                    </TooltipLink>
                                  ) : (
                                    <></>
                                  )}
                                  <TooltipLink
                                    onClick={onTransition(
                                      "the " +
                                        (hpf.name ||
                                          "HPF " + (hidx + (hasOrphan ? 0 : 1)).toString()) +
                                        " HPF and its points",
                                      deleteHpfAndPoints(hpf, dispatch)
                                    )}
                                  >
                                    Delete HPF and Points
                                  </TooltipLink>
                                </TooltipFlyout>
                              </Button>
                            )}
                          </Box>
                          <Box className="hangRight">
                            <Text
                              onClick={(e: MouseEvent) =>
                                e && e.target && copyText((e.target as HTMLSpanElement).innerText)
                              }
                            >
                              {hpf.count}
                            </Text>

                            <Checkbox
                              checked={
                                !hpfVisibility?.find(
                                  x =>
                                    (x.hpfClassid === anno.id && x.hpfId === null) ||
                                    (x.hpfClassid === anno.id &&
                                      x.hpfId === hpf.id &&
                                      x.pointClassId === null)
                                )
                              }
                              onChange={() => {
                                dispatch(
                                  toggleHpfVisibility({
                                    fragment: anno,
                                    hpfVisibility: {
                                      hpfClassid: anno.id || null,
                                      hpfId: hpf.id,
                                      pointClassId: null
                                    }
                                  })
                                );
                              }}
                            ></Checkbox>
                          </Box>
                        </Box>
                      </HpfTreeRow>
                      <Box
                        id={"branch_" + aidx.toString() + "_" + hidx.toString()}
                        className="treeBranchOpen"
                      >
                        {hpf.points?.map((pt, pidx) => (
                          <PointTreeRow
                            key={aidx.toString() + "_" + hidx.toString() + "_" + pidx.toString()}
                            className="highlight_anno_1 treeRow"
                          >
                            <Box
                              className="treeCell"
                              data-hpf-pt-cls-id={hpf.id + pt.id}
                              onMouseEnter={(e: MouseEvent) =>
                                highlightPoints(e.currentTarget as HTMLElement, pt, dispatch)
                              }
                              onMouseLeave={(e: MouseEvent) =>
                                unhighlightPoints(e.currentTarget as HTMLElement, pt, dispatch)
                              }
                              color={pt.color}
                            >
                              <FontAwesomeIcon height="9" width="9" icon={solidCircle} />
                              &nbsp;
                              <Text style={{ color: "black" }}>{pt.name}</Text>
                              {!userCanDeleteAnnotations ? (
                                <></>
                              ) : (
                                <Button data-tooltip={true} appearance={Appearance.MINIMAL}>
                                  <Icon name="menu" />
                                  <TooltipFlyout placement={"left-start"}>
                                    <TooltipLink
                                      onClick={onTransition(
                                        "all " +
                                          pt.count.toString() +
                                          " class: " +
                                          pt.name +
                                          " points",
                                        deletePoints(pt, dispatch)
                                      )}
                                    >
                                      Delete Points
                                    </TooltipLink>
                                  </TooltipFlyout>
                                </Button>
                              )}
                            </Box>
                            <Box className="hangRight">
                              <Text
                                onClick={(e: MouseEvent) =>
                                  e && e.target && copyText((e.target as HTMLSpanElement).innerText)
                                }
                              >
                                {pt.count}
                              </Text>

                              <Checkbox
                                checked={
                                  !hpfVisibility?.find(
                                    x =>
                                      (x.hpfClassid === anno.id && x.hpfId === null) ||
                                      (x.hpfId === hpf.id &&
                                        (x.pointClassId === null || x.pointClassId === pt.id))
                                  )
                                }
                                onChange={() => {
                                  dispatch(
                                    toggleHpfVisibility({
                                      fragment: anno,
                                      hpfVisibility: {
                                        hpfClassid: anno.id || null,
                                        hpfId: hpf.id,
                                        pointClassId: pt.id
                                      }
                                    })
                                  );
                                }}
                              ></Checkbox>
                            </Box>
                          </PointTreeRow>
                        ))}
                      </Box>
                    </div>
                  ))}
                </Box>
              </div>
            );
          })}
          {imageWithAnnotations &&
          "resource" in imageWithAnnotations &&
          imageWithAnnotations.resource.study?.indications.includes(Indication.CeliacDisease) &&
          imageWithAnnotations.resource.multilineAnnotationClassesWithCount.length > 0 ? (
            <div key="InfoSidebarCeliacTable">
              <TreeRow key="InfoSidebarCeliacTableTreeRow" className="treeRow">
                <TreeCellPart>
                  <Box
                    className="treeController treeIsOpen"
                    data-frag-name="InfoSidebarCeliacTable"
                    onClick={(e: MouseEvent) =>
                      e &&
                      e.currentTarget &&
                      toggleOpenTree(
                        e.currentTarget as HTMLElement,
                        "branch_InfoSidebarCeliacTable"
                      )
                    }
                  >
                    <Box className="closedTree">
                      <FontAwesomeIcon icon={faCaretRight} />
                    </Box>
                    <Box className="openTree">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </Box>
                  </Box>
                </TreeCellPart>
                <Box className="treeCell">Celiac Line Measurements</Box>
                <Box className="hangRight">
                  <Checkbox
                    checked={celiacTableVisibility}
                    onChange={() => {
                      dispatch(toggleCeliacTableVisibility());
                    }}
                  ></Checkbox>
                </Box>
              </TreeRow>
              <Box id="branch_InfoSidebarCeliacTable" className="treeBranchOpen">
                <InfoSidebarCeliacTable
                  annotationClasses={
                    imageWithAnnotations.resource.multilineAnnotationClassesWithCount
                  }
                  annotations={imageWithAnnotations.resource.annotations}
                  imageId={imageWithAnnotations.resource.imageAndQuery.image.id}
                />
              </Box>
            </div>
          ) : null}
        </>
      </TreeBox>
    </>
  );
};

export default AnnotationNestPanel;
