import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSummaries, Users } from "../models";
import { Resource } from "../types";
import { fetchUserSummaries, fetchUsers } from "../api";
import { RootState } from "../store";
import { castDraft } from "immer";

export interface UsersState {
  readonly users: Resource<Users>;
  readonly userSummaries: Resource<UserSummaries>;
  readonly usersFilter: string | null;
}

export const initialState: UsersState = {
  users: {
    isPending: false
  },
  userSummaries: {
    isPending: false
  },
  usersFilter: null
};

// thunks
export const usersFetch = createAsyncThunk("users/usersFetch", async (_: void, thunkApi) => {
  const { getState } = thunkApi;
  const state = getState() as RootState;
  const response = await fetchUsers(state.users.usersFilter ?? undefined);
  return response;
});

export const userSummariesFetch = createAsyncThunk(
  "users/userSummariesFetch",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchUserSummaries(state.users.usersFilter ?? undefined);
    return response;
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setUsersFilter: (state, action: PayloadAction<string | null>) => {
      state.usersFilter = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(usersFetch.pending, state => {
      state.users = { isPending: true };
    });
    builder.addCase(usersFetch.fulfilled, (state, action) => {
      state.users = { resource: castDraft(action.payload) };
    });
    builder.addCase(usersFetch.rejected, (state, action) => {
      state.users = { errorMessage: action.error.message || "" };
    });

    builder.addCase(userSummariesFetch.pending, state => {
      state.userSummaries = { isPending: true };
    });
    builder.addCase(userSummariesFetch.fulfilled, (state, action) => {
      state.userSummaries = { resource: castDraft(action.payload) };
    });
    builder.addCase(userSummariesFetch.rejected, (state, action) => {
      state.userSummaries = { errorMessage: action.error.message || "" };
    });
  }
});

export const { setUsersFilter } = usersSlice.actions;

export default usersSlice.reducer;
