import React from "react";
import { Box } from "@blasterjs/core";
import styled from "styled-components";
import { MultilineAnnotationClassesWithCount, UUID, MultilineCategory } from "../models";
import { AppDispatch } from "../store";
import AnnotationTooltip from "./AnnotationTooltip";

const MultilineBox = styled(Box)`
  color: ${props => (props.highlighted ? "#3997BE" : "inherit")}
  font-weight: ${props => (props.highlighted ? "bold" : "inherit")}
`;

const ColorBox = styled.span`
  height: 12px;
  width: 12px;
  background-color: ${props => props.color};
`;

const MultilineAnnotationTooltip = ({
  annotationClasses,
  currentAnnotationClassId,
  multilineCategory,
  annotationId,
  canDelete,
  dispatch
}: {
  readonly annotationClasses?: MultilineAnnotationClassesWithCount;
  readonly currentAnnotationClassId: string;
  readonly multilineCategory: string;
  readonly annotationId: UUID;
  readonly canDelete: boolean;
  dispatch: AppDispatch;
}) => {
  const currentAnnotationClass = annotationClasses?.find(
    annotationClass => annotationClass.annotationClass.id == currentAnnotationClassId
  );

  return (
    <AnnotationTooltip annotationId={annotationId} canDelete={canDelete} dispatch={dispatch}>
      <MultilineBox>
        <ColorBox
          color={currentAnnotationClass?.annotationClass.color}
          style={{ marginRight: "4px", display: "inline-block" }}
        />
        {currentAnnotationClass?.annotationClass.name}
      </MultilineBox>
      <MultilineBox highlighted={multilineCategory == MultilineCategory.VillusHeight}>
        VH
      </MultilineBox>
      <MultilineBox highlighted={multilineCategory == MultilineCategory.CryptDepth}>
        CD
      </MultilineBox>
    </AnnotationTooltip>
  );
};

export default MultilineAnnotationTooltip;
