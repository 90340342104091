import React, { useEffect, useState } from "react";

import { Box, Button, Dialog, Select, Label, TextInput, Icon } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "./DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  ImageRowOptions,
  DialogMode,
  closeOpenImageManagementImageDialogParent,
  casesFetch,
  studiesForUserFetch,
  moveImageToStudy,
  imageCopy,
  archiveImage,
  assignImageToCase,
  moveImageToCase,
  imageDownload,
  clearDownloadImageControl,
  clearModalMessaging,
  ModalMessage
} from "../slices/ImageManagementImageDialogParent";
import { studyLabel } from "../models";

export const UserFeedbackMessage = ({ success, message }: ModalMessage) => {
  return (
    <>
      {message.length > 0 && (
        <Box
          style={{
            padding: "1.5rem",
            margin: "0.5rem",
            backgroundColor: success ? "#ACE1AF" : "#DC143C"
          }}
        >
          <div>
            {success ? <Icon name="check" /> : <Icon name="exclamation-circle" />} {message}
          </div>
        </Box>
      )}
    </>
  );
};

const ImageManagementImageDialogParent = () => {
  const dispatch = useAppDispatch();

  const dialogMode = useAppSelector(state => state.imageManagementImageDialogParent.mode);
  const modalParams = useAppSelector(state => state.imageManagementImageDialogParent.modalParams);

  const closeDialog = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
  };

  const childControl = () => {
    switch (modalParams?.imageRowOptions) {
      case ImageRowOptions.AssignToCase:
        return <AssignToCaseControl />;
      case ImageRowOptions.CopyImage:
        return <CopyImageControl />;
      case ImageRowOptions.MoveImage:
        return <MoveImageControl />;
      case ImageRowOptions.DownloadImage:
        return <DownloadImageControl />;
      case ImageRowOptions.Archive:
        return <ArchiveImageControl />;
      default:
        return <div>-</div>;
    }
  };

  return (
    <Dialog
      isOpen={dialogMode === DialogMode.OpenForEdit}
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      {childControl()}
    </Dialog>
  );
};

export default ImageManagementImageDialogParent;

/*
  child controls that will conditionally render in the parent dialog
*/
const AssignToCaseControl = () => {
  const dispatch = useAppDispatch();

  const modalParams = useAppSelector(state => state.imageManagementImageDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementImageDialogParent.modalMessage);
  const cases = useAppSelector(state => state.imageManagementImageDialogParent.cases);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedCaseId, setSelectedCaseId] = useState("");
  const [reasonForChange, setReasonForChange] = useState("");

  const studyId = modalParams && "studyId" in modalParams ? modalParams?.studyId : null;
  const imageFilename = modalParams && "studyId" in modalParams ? modalParams?.imageFilename : null;
  const imageId = modalParams && "studyId" in modalParams ? modalParams?.imageId : null;

  useEffect(() => {
    if (studyId) {
      dispatch(casesFetch(studyId));
    }
  }, [studyId]);

  const closeDialog = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
  };

  const onSave = () => {
    if (
      selectedCaseId &&
      selectedCaseId.length > 0 &&
      reasonForChange &&
      reasonForChange.length > 0 &&
      imageId
    ) {
      dispatch(
        assignImageToCase({
          caseId: selectedCaseId,
          imageId: imageId,
          reasonForChange: reasonForChange
        })
      );
    }
  };

  const onSelectCaseId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCaseId(e.currentTarget.value);
  };

  const reasonForChangeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(e.currentTarget.value);
  };

  const title = "Assign to Case";
  const buttonLabel = "Confirm";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>
              The following image will be assigned:
              <br />
              {imageFilename}
            </Label>
          </Box>
          <Box style={{ width: "100%" }}>
            <Label>Select Case by Procedure ID:</Label>
            <Select onChange={onSelectCaseId} defaultValue={""}>
              <option key={""} value={""}>
                Select Case
              </option>
              {"resource" in cases &&
                cases.resource.map(c => {
                  return (
                    <option key={c.caseWithStatus.id} value={c.caseWithStatus.id}>
                      {c.caseWithStatus.procId}
                    </option>
                  );
                })}
            </Select>
          </Box>
          <Box style={{ width: "100%" }}>
            <Label>Reason for Change</Label>
            <TextInput
              placeholder={"Enter reason"}
              value={reasonForChange}
              onChange={reasonForChangeOnChange}
              style={{ paddingLeft: "1rem" }}
            />
          </Box>
        </Box>
        {modalMessage && (
          <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
        )}
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button intent="primary" appearance="prominent" onClick={onSave}>
            {buttonLabel}
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

// copy
const CopyImageControl = () => {
  const dispatch = useAppDispatch();

  const modalParams = useAppSelector(state => state.imageManagementImageDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementImageDialogParent.modalMessage);

  const [duplicateImageName, setDuplicateImageName] = useState("");

  const imageId = modalParams?.imageId || null;

  const closeDialog = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
    dispatch(clearModalMessaging());
  };

  const duplicateImageNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDuplicateImageName(e.currentTarget.value);
  };

  const onSave = () => {
    if (imageId) {
      dispatch(imageCopy({ imageId: imageId, duplicateImageName: duplicateImageName }));
    }
  };

  const title = "Copy Image";
  const buttonLabel = "Copy Image";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>File name for copied image</Label>
            <TextInput
              placeholder={"Enter reason"}
              value={duplicateImageName}
              onChange={duplicateImageNameOnChange}
              style={{ paddingLeft: "1rem" }}
            />
          </Box>
          {modalMessage && (
            <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
          )}
        </Box>
      </DialogBody>
      <DialogFooter>
        {!modalMessage && (
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onSave}>
              {buttonLabel}
            </Button>
          </Box>
        )}
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

// move
type StudyOrCase = "Study" | "Case";
const MoveImageControl = () => {
  const dispatch = useAppDispatch();

  const modalParams = useAppSelector(state => state.imageManagementImageDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementImageDialogParent.modalMessage);
  const studies = useAppSelector(state => state.imageManagementImageDialogParent.studies);
  const cases = useAppSelector(state => state.imageManagementImageDialogParent.cases);

  //console.log(`modal message=${JSON.stringify(modalMessage)}`)

  const imageFilename = modalParams && "studyId" in modalParams ? modalParams?.imageFilename : null;
  const studyId = modalParams && "studyId" in modalParams ? modalParams?.studyId : null;
  const imageId = modalParams?.imageId || "";

  const [confirmationMode, setConfirmationMode] = useState(false);
  const [studyOrCaseMode, setStudyOrCaseMode] = useState<StudyOrCase>("Study");
  const [selectedStudyId, setSelectedStudyId] = useState(studyId || "");
  const [selectedCaseId, setSelectedCaseId] = useState("");
  const [reasonForChange, setReasonForChange] = useState("");
  const [caseName, setCaseName] = useState("");
  //const [oldCaseName] = useState("old case name TODO");
  const [studyName] = useState("study name TODO");
  //const [oldStudyName] = useState("old study name TODO");
  //const [updatedDataPoints] = useState("update-data-points TODO");

  useEffect(() => {
    dispatch(studiesForUserFetch());
  }, []);

  // console.log("studyOrCaseMode = " + studyOrCaseMode);
  // console.log("selectedStudyId = " + selectedStudyId);
  // console.log("selectedCaseId = " + selectedCaseId);
  // console.log("reasonForChange = " + reasonForChange);

  /*
    - image file name
    - select case drop down
    - reason for change
    - confirm | cancel buttons

    -- [confirmation]
  */

  const closeDialog = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
  };

  const cancelConfirmation = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
    dispatch(clearModalMessaging());
  };

  const onMoveImageClick = () => {
    setConfirmationMode(true);
  };
  const onConfirmMoveImageClick = () => {
    if (studyOrCaseMode == "Study") {
      if (selectedStudyId && selectedStudyId.length > 0 && imageId) {
        dispatch(
          moveImageToStudy({
            studyId: selectedStudyId,
            imageId: imageId,
            reasonForChange: reasonForChange
          })
        );
      }
    } else {
      dispatch(
        moveImageToCase({
          caseId: selectedCaseId,
          imageId: imageId,
          reasonForChange: reasonForChange
        })
      );
    }
  };

  const onSelectMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newMode = e.currentTarget.value as StudyOrCase;
    setStudyOrCaseMode(newMode);
    if (newMode == "Case") {
      dispatch(casesFetch(selectedStudyId));
    }
  };

  const onSelectStudyId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStudyId(e.currentTarget.value);
    dispatch(casesFetch(e.currentTarget.value));
  };

  const onSelectCaseId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCaseId(e.currentTarget.value);
    "resource" in cases &&
      cases.resource.map(c => {
        setCaseName(`${c.caseWithStatus.procId} ${c.caseWithStatus.histoProcedureId}`);
      });
  };

  const reasonForChangeOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(e.currentTarget.value);
  };

  const title = "Move Image";
  const buttonLabel = "Move Image";
  const confirmButtonLabel = "Move Image to Case";

  if (confirmationMode) {
    return (
      <>
        <DialogHeader title={title} closeDialog={closeDialog} />
        <DialogBody>
          <Box display="block" justifyContent="space-between" mb={2}>
            <Box style={{ width: "100%" }}>
              <Label>Are you sure you want to make the following changes?</Label>
              {studyOrCaseMode == "Study" ? (
                <Label>
                  {" "}
                  {imageFilename} will move to {studyName} {/* from oldStudyName*/}.
                </Label>
              ) : (
                <Label>
                  {" "}
                  {imageFilename} will move to {caseName} {/* from oldCaseName*/}.
                </Label>
              )}
            </Box>
            <Box style={{ width: "100%" }}>
              <Label>Reason for Change</Label>
              {reasonForChange}
            </Box>
            <Box style={{ width: "100%" }}>
              <Label>
                Warning: The following data points will be updated to reflect the new{" "}
                <b>{studyOrCaseMode}</b> Case Status:
              </Label>
              {/* {updatedDataPoints} */}
            </Box>
            {modalMessage && (
              <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
            )}
          </Box>
        </DialogBody>
        <DialogFooter>
          {!modalMessage && (
            <Box>
              <Button intent="primary" appearance="prominent" onClick={onConfirmMoveImageClick}>
                {confirmButtonLabel}
              </Button>
            </Box>
          )}
          <Box>
            <Button onClick={cancelConfirmation}>Cancel</Button>
          </Box>
        </DialogFooter>
      </>
    );
  } else {
    return (
      <>
        <DialogHeader title={title} closeDialog={closeDialog} />
        <DialogBody>
          <Box display="block" justifyContent="space-between" mb={2}>
            <Box style={{ width: "100%" }}>
              <Label>Would you like to move Studies or Case?</Label>
              <Select onChange={onSelectMode} defaultValue={studyOrCaseMode}>
                <option key={"Study"} value={"Study"}>
                  Study
                </option>
                <option key={"Case"} value={"Case"}>
                  Case
                </option>
              </Select>
              <Label>
                What study would you like to move <b>{imageFilename}</b> to?
              </Label>
              <Select onChange={onSelectStudyId} value={selectedStudyId}>
                <option key={""} value={""}>
                  Select study...
                </option>
                {"resource" in studies &&
                  studies.resource.map(study => {
                    return (
                      <option key={study.id} value={study.id}>
                        {studyLabel(study)}
                      </option>
                    );
                  })}
              </Select>
              {selectedStudyId && selectedStudyId.length > 0 && studyOrCaseMode == "Case" && (
                <>
                  <Label>What case would you like to move {imageFilename}?</Label>
                  <Select onChange={onSelectCaseId} defaultValue={""}>
                    <option key={""} value={""}>
                      Select case...
                    </option>
                    {"resource" in cases &&
                      cases.resource.map(c => {
                        return (
                          <option key={c.caseWithStatus.id} value={c.caseWithStatus.id}>
                            {c.caseWithStatus.procId} {c.caseWithStatus.histoProcedureId}
                          </option>
                        );
                      })}
                  </Select>
                </>
              )}
            </Box>
            <Box style={{ width: "100%" }}>
              <Label>Reason for Change</Label>
              <TextInput
                placeholder={"Enter reason"}
                value={reasonForChange}
                onChange={reasonForChangeOnChange}
                style={{ paddingLeft: "1rem" }}
              />
            </Box>
          </Box>
        </DialogBody>
        <DialogFooter>
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onMoveImageClick}>
              {buttonLabel}
            </Button>
          </Box>
          <Box>
            <Button onClick={closeDialog}>Cancel</Button>
          </Box>
        </DialogFooter>
      </>
    );
  }
};

// download
const DownloadImageControl = () => {
  const dispatch = useAppDispatch();

  const modalParams = useAppSelector(state => state.imageManagementImageDialogParent.modalParams);
  const downloadImageUrl = useAppSelector(
    state => state.imageManagementImageDialogParent.downloadImageControl?.imageUrl
  );
  const imageId = modalParams?.imageId || null;

  useEffect(() => {
    if (downloadImageUrl) {
      window.open(downloadImageUrl, "_blank");
      dispatch(clearDownloadImageControl());
      closeDialog();
    }
  }, [downloadImageUrl]);

  const closeDialog = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
  };

  const onSave = () => {
    if (imageId) {
      dispatch(imageDownload(imageId));
    }
  };

  const title = "Download Image";
  const buttonLabel = "Download Image";

  const imageFilename = modalParams && "studyId" in modalParams ? modalParams?.imageFilename : null;

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>Are you sure you want to download &quot;{imageFilename}&quot;</Label>
          </Box>
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button intent="primary" appearance="prominent" onClick={onSave}>
            {buttonLabel}
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

// archive

const ArchiveImageControl = () => {
  const dispatch = useAppDispatch();

  const modalParams = useAppSelector(state => state.imageManagementImageDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementImageDialogParent.modalMessage);

  const [reasonForChange, setReasonForChange] = useState("");

  const imageFilename = modalParams && "studyId" in modalParams ? modalParams?.imageFilename : null;
  const imageId = modalParams?.imageId || null;

  const closeDialog = () => {
    dispatch(closeOpenImageManagementImageDialogParent());
  };

  const reasonForChangeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(event.currentTarget.value);
  };

  const onSave = () => {
    if (imageId && reasonForChange.length > 0) {
      dispatch(archiveImage({ imageId: imageId, reasonForChange: reasonForChange }));
    }
  };

  const title = "Are you sure you want to archive the following image";
  const buttonLabel = "Archive Image";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>{imageFilename}</Label>
          </Box>
          <Box style={{ width: "100%" }}>
            <Label>Reason for Change</Label>
            <TextInput
              placeholder={"Enter reason"}
              value={reasonForChange}
              onChange={reasonForChangeOnChange}
              style={{ paddingLeft: "1rem" }}
            />
          </Box>
        </Box>
        {modalMessage && (
          <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
        )}
      </DialogBody>
      <DialogFooter>
        {!modalMessage && (
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onSave}>
              {buttonLabel}
            </Button>
          </Box>
        )}
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};
