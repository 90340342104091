import React from "react";

import { formatDate, LOCALE } from "../utils";
import TooltipText from "./TooltipText";

interface Props {
  readonly date: Date | null;
}

const Timestamp = ({ date }: Props) => {
  const formattedDate = formatDate(date);
  return date === null || date === undefined ? (
    <span>{formattedDate}</span>
  ) : (
    <TooltipText text={date.toLocaleDateString(LOCALE) + " " + date.toLocaleTimeString(LOCALE)}>
      {formattedDate}
    </TooltipText>
  );
};

export default Timestamp;
