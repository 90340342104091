import { Box, Button, Callout, Dialog, Intent, Text } from "@blasterjs/core";
import React from "react";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import ReasonForChange from "../components/ReasonForChange";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  closeDeleteImageDialog,
  imageDelete,
  setDeleteImageForm
} from "../slices/deleteImageDialog";
import { imagesFetch } from "../slices/images";

const deleteImageDialog = () => {
  const dispatch = useAppDispatch();
  const deleteImageDialog = useAppSelector(state => state.deleteImageDialog);

  const closeDialog = () => {
    dispatch(closeDeleteImageDialog());
  };

  const onConfirm = () => {
    deleteImageDialog.isOpen &&
      dispatch(imageDelete()).then(() => {
        if (deleteImageDialog.image.data.image.studyId) {
          dispatch(imagesFetch(deleteImageDialog.image.data.image.studyId));
        }
      });
  };

  const onChangeReasonForChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    deleteImageDialog.isOpen &&
      dispatch(
        setDeleteImageForm({
          ...deleteImageDialog.image.data,
          reasonForChange: e.target.value
        })
      );
  };

  const errorText =
    deleteImageDialog.isOpen && "errorMessage" in deleteImageDialog.image ? (
      <Box mb="2">
        <Callout intent={Intent.DANGER}>
          <Text>{deleteImageDialog.image.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const confirmDisabled = !(
    deleteImageDialog.isOpen && deleteImageDialog.image.data.reasonForChange
  );
  return deleteImageDialog.isOpen ? (
    <Dialog isOpen={deleteImageDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader title="Archive Image" closeDialog={closeDialog} />
      <DialogBody>
        {errorText}
        <Box>
          Are you sure you want to archive <b>{deleteImageDialog.image.data.image.name}</b>?
        </Box>
        <Box style={{ marginTop: 12 }}>
          <ReasonForChange
            rkey="deleteImage_reason"
            value={(deleteImageDialog.isOpen && deleteImageDialog.image.data.reasonForChange) || ""}
            onChange={onChangeReasonForChange}
          />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            onClick={onConfirm}
            disabled={confirmDisabled}
            appearance="prominent"
            intent="danger"
          >
            Confirm
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </Dialog>
  ) : null;
};

export default deleteImageDialog;
