import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Image } from "../models";
import { WriteResource } from "../types";
import { duplicateImageName } from "../utils";
import { copyImage } from "../api";
import { imagesFetch } from "./images";
import { RootState } from "../store";

export interface CopyImageForm {
  readonly duplicateImageName: string | null;
}

export type CopyImageDialogState =
  | {
      readonly isOpen: true;
      readonly savedImage: Image;
      readonly image: WriteResource<CopyImageForm, Image>;
    }
  | {
      readonly isOpen: false;
    };

// thunks
export const imageCopy = createAsyncThunk(
  "copyImageDialog/imageCopy",
  async (_: void, thunkApi) => {
    const { getState, dispatch } = thunkApi;
    const state = getState() as RootState;
    if (state.copyImageDialog.isOpen && state.copyImageDialog.image.data.duplicateImageName) {
      const response = copyImage(
        state.copyImageDialog.savedImage.id,
        state.copyImageDialog.image.data.duplicateImageName
      );
      if (state.copyImageDialog.isOpen && state.copyImageDialog.savedImage.studyId) {
        const studyId = state.copyImageDialog.savedImage.studyId;
        dispatch(resetState());
        await dispatch(imagesFetch(studyId));
      }
      return response;
    }
  }
);

export const copyImageDialogSlice = createSlice({
  name: "copyImageDialog",
  initialState: { isOpen: false } as CopyImageDialogState,
  reducers: {
    resetState: state => {
      state.isOpen = false;
    },
    openCopyImageDialog: (state, action: PayloadAction<Image>) => {
      return {
        isOpen: true,
        savedImage: action.payload,
        image: {
          data: {
            duplicateImageName: duplicateImageName(action.payload.name)
          }
        }
      };
    },
    closeCopyImageDialog: state => {
      state.isOpen = false;
    },
    setCopyImageForm: (state, action: PayloadAction<CopyImageForm>) => {
      if (state.isOpen) {
        state.image.data = action.payload;
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(imageCopy.rejected, (state, action) => {
      if (state.isOpen) {
        state.image = {
          data: state.image.data,
          errorMessage: action.error.message
        };
      }
    });
  }
});

export const {
  resetState,
  openCopyImageDialog,
  closeCopyImageDialog,
  setCopyImageForm
} = copyImageDialogSlice.actions;
export default copyImageDialogSlice.reducer;
