import { createSlice } from "@reduxjs/toolkit";
import { Fragment, MetadataImageType } from "../models";

export interface AnnotationNestState {
  readonly isAnnotationNestPanelExpanded: boolean;
  readonly topLevelAnnotations: ReadonlyArray<Fragment>;
}

// params
export interface fetchMetadataImageParams {
  uri: string;
  metadataType: MetadataImageType;
}

export const initialState: AnnotationNestState = {
  isAnnotationNestPanelExpanded: true,
  topLevelAnnotations: []
};

export const annotationNestSlice = createSlice({
  name: "annotationNest",
  initialState: initialState,
  reducers: {
    toggleAnnotationNestExpanded: state => {
      state.isAnnotationNestPanelExpanded = !state.isAnnotationNestPanelExpanded;
    }
  }
});

export const { toggleAnnotationNestExpanded } = annotationNestSlice.actions;

export default annotationNestSlice.reducer;
