import { TextInput } from "@blasterjs/core";
import { debounce } from "lodash";
import React from "react";

interface DebouncedTextInputProps {
  readonly onValueChange: (arg: string) => void;
  readonly defaultValue: string;
  readonly placeholder?: string;
  readonly width?: string | number;
  readonly wait?: number;
}

const DEFAULT_WAIT = 500;

const DebouncedTextInput = <Props extends object>({
  onValueChange,
  wait,
  ...props
}: Props & DebouncedTextInputProps) => {
  const debouncedOnValueChange = debounce(onValueChange, wait || DEFAULT_WAIT);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist(); // See: https://reactjs.org/docs/events.html#event-pooling
    debouncedOnValueChange(e.target.value);
  };
  return <TextInput {...(props as Props)} onChange={onChange} />;
};

export default DebouncedTextInput;
