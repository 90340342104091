import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QueryRecordsData, QueryStats, Roles, UUID } from "../models";
import { fetchQueries, fetchQueryStats, fetchRoles } from "../api";
import { Resource } from "../types";
import { RootState } from "../store";
import { castDraft } from "immer";

export interface QueryFilter {
  readonly searchText: string | null;
  readonly studyFilter: UUID | null;
  readonly caseFilter: UUID | null;
  readonly statusFilter: string | null;
  readonly roleFilter: string | null;
  readonly includeClosedQueries: boolean | null;
}

export interface QueryState {
  readonly queryStats: Resource<QueryStats>;
  readonly queries: Resource<QueryRecordsData>;
  readonly queriesFilter: QueryFilter;
  readonly roles: Resource<Roles>;
}

export const initialState: QueryState = {
  queryStats: {
    isPending: false
  },
  queries: {
    isPending: false
  },
  queriesFilter: {
    searchText: "",
    studyFilter: null,
    caseFilter: null,
    statusFilter: null,
    roleFilter: null,
    includeClosedQueries: false
  },
  roles: {
    isPending: false
  }
};

// thunks
export const queriesFetch = createAsyncThunk("queries/queriesFetch", async (_: void, thunkApi) => {
  const { getState } = thunkApi;
  const state = getState() as RootState;
  const response = await fetchQueries(state.queries.queriesFilter);
  return response;
});

export const queryStatsFetch = createAsyncThunk("queries/queryStatsFetch", async () => {
  const response = await fetchQueryStats();
  return response;
});

export const rolesFetch = createAsyncThunk("queries/rolesFetch", async () => {
  const response = await fetchRoles();
  return response;
});

export const setQueriesFilterThunk = createAsyncThunk(
  "queries/setQueriesFilterThunk",
  async (filter: QueryFilter, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setQueriesFilter(filter));
    await dispatch(queriesFetch());
  }
);

export const queriesSlice = createSlice({
  name: "queries",
  initialState: initialState,
  reducers: {
    setQueriesFilter: (state, action: PayloadAction<QueryFilter>) => {
      state.queriesFilter = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(queriesFetch.pending, state => {
      state.queries = { isPending: true };
    });
    builder.addCase(queriesFetch.fulfilled, (state, action) => {
      state.queries = { resource: castDraft(action.payload) };
    });
    builder.addCase(queriesFetch.rejected, (state, action) => {
      state.queries = { errorMessage: action.error.message || "" };
    });

    builder.addCase(rolesFetch.pending, state => {
      state.roles = { isPending: true };
    });
    builder.addCase(rolesFetch.fulfilled, (state, action) => {
      state.roles = { resource: castDraft(action.payload) };
    });
    builder.addCase(rolesFetch.rejected, (state, action) => {
      state.roles = { errorMessage: action.error.message || "" };
    });

    builder.addCase(queryStatsFetch.pending, state => {
      state.queryStats = { isPending: true };
    });
    builder.addCase(queryStatsFetch.fulfilled, (state, action) => {
      state.queryStats = { resource: castDraft(action.payload) };
    });
    builder.addCase(queryStatsFetch.rejected, (state, action) => {
      state.queryStats = { errorMessage: action.error.message || "" };
    });
  }
});

export const { setQueriesFilter } = queriesSlice.actions;
export default queriesSlice.reducer;
