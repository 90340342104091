import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Roles, UUID } from "../models";
import { Resource } from "../types";
import { fetchAvailableUserRoles, updateWorkAsApi } from "../api";
import { castDraft } from "immer";

export enum DialogMode {
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

export interface WorkAsState {
  readonly mode: DialogMode;
  readonly availableRoles: Resource<Roles>;
  readonly selectedRole: UUID | null;
}

export const initialState: WorkAsState = {
  mode: DialogMode.Closed,
  availableRoles: { isPending: false },
  selectedRole: null
};

// thunks
export const openWorkAsDialog = createAsyncThunk(
  "workAsDialog/openWorkAsDialog",
  async (_: void, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setOpenWorkAsDialog());
    await dispatch(userFetchAvailableRoles());
  }
);

export const userFetchAvailableRoles = createAsyncThunk(
  "workAsDialog/userFetchAvailableRoles",
  async (_: void) => {
    const response = await fetchAvailableUserRoles();
    return response;
  }
);

export const updateWorkAsRole = createAsyncThunk(
  "workAsDialog/updateWorkAsRole",
  async (roleId: UUID) => {
    const response = await updateWorkAsApi(roleId);
    return response;
  }
);

export const workAsDialogSlice = createSlice({
  name: "workAsDialog",
  initialState: initialState,
  reducers: {
    setOpenWorkAsDialog: () => {
      return {
        ...initialState,
        mode: DialogMode.OpenForEdit
      };
    },
    closeWorkAsDialog: state => {
      state.mode = DialogMode.Closed;
    },
    setSelectedRole: (state, action: PayloadAction<UUID>) => {
      state.selectedRole = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(userFetchAvailableRoles.pending, state => {
      state.availableRoles = { isPending: true };
    });
    builder.addCase(userFetchAvailableRoles.fulfilled, (state, action) => {
      state.availableRoles = { resource: castDraft(action.payload) };
    });
    builder.addCase(userFetchAvailableRoles.rejected, (state, action) => {
      state.availableRoles = { errorMessage: action.error.message || "" };
    });
  }
});

export const {
  setOpenWorkAsDialog,
  closeWorkAsDialog,
  setSelectedRole
} = workAsDialogSlice.actions;

export default workAsDialogSlice.reducer;
