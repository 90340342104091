import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SampleDataImportResults, SampleDataUploadResponse, UUID } from "../models";
import { WriteResource } from "../types";
import { CancelTokenSource } from "axios";
import {
  createSampleDataSignedUrl,
  importSampleData,
  generateCancelTokenSource,
  uploadSampleDataFile
} from "../api";
import { RootState } from "../store";
import { castDraft } from "immer";

interface SampleDataUploadProgress {
  readonly file: File;
  readonly progress: number;
}

interface AllSampleDataUploadProgress {
  // NOTE: key is the filename
  readonly [key: string]: SampleDataUploadProgress;
}

interface InProgressSampleDataUpload {
  readonly uploadProgress: AllSampleDataUploadProgress;
  readonly cancelTokenSource: CancelTokenSource;
}

export interface UploadSampleDataDialogState {
  readonly isOpen: boolean;
  readonly acceptButtonDisabled: boolean;
  readonly uploadComplete: boolean;
  readonly sampleDataSignedURL: WriteResource<File | null, SampleDataUploadResponse>;
  readonly importResults: WriteResource<null, SampleDataImportResults>;
  readonly currentUpload: InProgressSampleDataUpload | null;
}

export const initialState: UploadSampleDataDialogState = {
  isOpen: false,
  acceptButtonDisabled: false,
  uploadComplete: false,
  importResults: {
    data: null,
    resource: {
      message: "",
      numRowsUpdated: 0,
      numFieldsUpdated: 0,
      numRowsFailed: 0,
      s3SampleDataResponse: ""
    }
  },
  sampleDataSignedURL: {
    data: null,
    resource: {
      putSignedUrl: "",
      sampleDataRequest: {
        s3SampleDataKey: "",
        fileName: "",
        resultFileKey: ""
      }
    }
  },
  currentUpload: null
};

export interface OpenUploadSampleDataDialogParams {
  readonly studyId: UUID;
}

export interface UploadSampleDataProgressParams {
  readonly file: File;
  readonly progress: number;
}

// thunks
export const signedSampleDataURLsCreate = createAsyncThunk(
  "uploadSampleDataDialog/signedURLsCreate",
  async (_: void, thunkApi) => {
    const { dispatch } = thunkApi;
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (
      "data" in state.uploadSampleDataDialog.sampleDataSignedURL &&
      state.uploadSampleDataDialog.sampleDataSignedURL.data !== null
    ) {
      const file = state.uploadSampleDataDialog.sampleDataSignedURL.data;
      const signedURLsCreateInnerResponse = await dispatch(signedSampleDataURLsCreateInner());
      if (signedURLsCreateInnerResponse) {
        await uploadSampleDataFile(
          file,
          signedURLsCreateInnerResponse.payload as SampleDataUploadResponse,
          generateCancelTokenSource()
        );
      }
    }
  }
);
export const signedSampleDataURLsCreateInner = createAsyncThunk(
  "uploadSampleDataDialog/signedURLsCreateInner",
  async (_: void, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    if (
      "data" in state.uploadSampleDataDialog.sampleDataSignedURL &&
      state.uploadSampleDataDialog.sampleDataSignedURL.data !== null
    ) {
      const createSignedUrlsResponse = await createSampleDataSignedUrl(
        state.uploadSampleDataDialog.sampleDataSignedURL.data
      );

      return createSignedUrlsResponse;
    }
  }
);

export const uploadSampleDataProcess = createAsyncThunk(
  "uploadSampleDataDialog/uploadSampleData",
  async (_: void, thunkApi) => {
    // eslint-disable-next-line
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.uploadSampleDataDialog;

    if (
      "data" in state.sampleDataSignedURL &&
      state.sampleDataSignedURL.data !== null &&
      "resource" in state.sampleDataSignedURL
    ) {
      const uploadFilesResponse = await importSampleData(
        state.sampleDataSignedURL.resource.sampleDataRequest
      );

      return uploadFilesResponse;
    }
  }
);

export const uploadSampleDataDialogSlice = createSlice({
  name: "uploadSampleDataDialog",
  initialState: initialState,
  reducers: {
    openUploadSampleDataDialog: (
      state
      //action: PayloadAction<OpenUploadSampleDataDialogParams>
    ) => {
      state.isOpen = true;
    },
    closeUploadSampleDataDialog: state => {
      state.isOpen = false;
      state.importResults = { data: null };
      state.uploadComplete = false;
    },
    changeFiles: (state, action: PayloadAction<FileList | null>) => {
      state.sampleDataSignedURL.data = action.payload?.item(0) || null;
    },
    // eslint-disable-next-line
    uploadSampleDataPending: (state, action) => {
      if (
        "data" in state.sampleDataSignedURL &&
        state.sampleDataSignedURL.data !== null &&
        "resource" in state.sampleDataSignedURL
      ) {
        state.sampleDataSignedURL = {
          data: state.sampleDataSignedURL.data,
          resource: state.sampleDataSignedURL.resource,
          isPending: true
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(signedSampleDataURLsCreateInner.pending, state => {
      state.sampleDataSignedURL = {
        data: state.sampleDataSignedURL.data,
        isPending: true
      };
    });
    builder.addCase(signedSampleDataURLsCreateInner.fulfilled, (state, action) => {
      if (action.payload && action.payload !== undefined) {
        state.sampleDataSignedURL = {
          data: state.sampleDataSignedURL.data,
          resource: castDraft(action.payload)
        };
        state.uploadComplete = true;
      }
    });
    builder.addCase(signedSampleDataURLsCreateInner.rejected, (state, action) => {
      state.sampleDataSignedURL = {
        data: state.sampleDataSignedURL.data,
        errorMessage: action.error.message
      };
    });

    // eslint-disable-next-line
    builder.addCase(uploadSampleDataProcess.fulfilled, (state, action) => {
      if (action.payload && action.payload !== undefined) {
        state.importResults = {
          data: state.importResults.data,
          resource: action.payload
        };
      }
    });

    // eslint-disable-next-line
    builder.addCase(uploadSampleDataProcess.rejected, (state, action) => {
      state.importResults = {
        data: state.importResults.data,
        errorMessage:
          "errorMessage" in state.importResults
            ? state.importResults.errorMessage
            : "Warning: failed to process the sample data"
      };
    });
  }
});

export const {
  openUploadSampleDataDialog,
  closeUploadSampleDataDialog,
  changeFiles,
  uploadSampleDataPending
} = uploadSampleDataDialogSlice.actions;

export default uploadSampleDataDialogSlice.reducer;
