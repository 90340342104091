import React, { useEffect } from "react";

import { Box, Callout, Icon, Intent, Text, Select, Label, Checkbox } from "@blasterjs/core";
import {
  DynamicQueryStatus,
  formatDynamicQueryStatus,
  formatQueryStatus,
  formatRoleName,
  QueryObjectType,
  QuerySearchRecord,
  QueryStatus,
  QueryWithRoles
} from "../models";
import { useNavigate } from "react-router-dom";
import Page, { PageHeader, PageHeading, PageTabs } from "../components/Page";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import Timestamp from "../components/Timestamp";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { queriesFetch, queryStatsFetch, rolesFetch, setQueriesFilter } from "../slices/queries";
import { studiesFetch } from "../slices/studies";
import { useParams } from "react-router";
import { Resource } from "../types";
import { LoggedInUser, RolePermissions } from "../permissions";

interface QueriesProps {
  readonly loggedInUser: Resource<LoggedInUser>;
}

const Queries = ({ loggedInUser }: QueriesProps) => {
  const dispatch = useAppDispatch();

  const params = useParams();
  const tab: string = params.tab || "images";
  const queryTab: QueryObjectType = tab == "images" ? QueryObjectType.Image : QueryObjectType.Case;

  const allQueries = useAppSelector(state => state.queries.queries);
  const queryStats = useAppSelector(state => state.queries.queryStats);
  const queriesFilter = useAppSelector(state => state.queries.queriesFilter);
  const studies = useAppSelector(state => state.studies.studies);
  const roles = useAppSelector(state => state.queries.roles);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(queriesFetch());
    dispatch(queryStatsFetch());
    dispatch(studiesFetch());
    dispatch(rolesFetch());
  }, [queriesFilter]);

  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);

  const userCanSeeCases =
    "resource" in loggedInUser && loggedInUser.resource.can([RolePermissions.Q_ViewQueriesCases]);
  const userCanSeeImages =
    "resource" in loggedInUser && loggedInUser.resource.can([RolePermissions.Q_ViewQueriesImages]);

  const error =
    "errorMessage" in allQueries ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{allQueries.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;

  const onRowClick = (query: QuerySearchRecord) => {
    if (tab == "cases") {
      navigate(`/studies/${query.studyId}/cases/${query.caseId}?queryId=${query.id}`);
    } else {
      navigate(`/studies/${query.studyId}/images/${query.objectId}?queryId=${query.id}`);
    }
  };

  function getStatusLabelColor(dynamicStatus: DynamicQueryStatus) {
    switch (dynamicStatus) {
      case DynamicQueryStatus.Answered:
        return "green";
      case DynamicQueryStatus.FollowUpRequired:
        return "red";
      default:
        return "";
    }
  }

  function truncate(str: String, n: number) {
    return str.length > n ? str.slice(0, n - 1) : str;
  }

  const columns: ReadonlyArray<object> = [
    {
      name: "Opened",
      selector: (query: QuerySearchRecord) => query.openedAt,
      sortable: true,
      width: "12rem",
      wrap: true,
      format: (query: QuerySearchRecord) => <Timestamp date={query.openedAt} />
    },
    {
      name: "Latest Activity",
      width: "12rem",
      wrap: true,
      selector: (query: QuerySearchRecord) => query.updatedAt,
      sortable: true,
      format: (query: QuerySearchRecord) => <Timestamp date={query.updatedAt} />
    },
    {
      name: "Query Status",
      selector: (query: QuerySearchRecord) => query.queryStatus,
      width: "18rem",
      sortable: true,
      format: (query: QuerySearchRecord) => {
        /*eslint-disable */
        let statusLabel = formatQueryStatus(query.queryStatus);
        let labelColor = "";
        /*eslint-enable */
        if (query.queryStatus == QueryStatus.Open) {
          if (query.dynamicStatus) {
            statusLabel = formatDynamicQueryStatus(query.dynamicStatus);
            labelColor = getStatusLabelColor(query.dynamicStatus);
          } else {
            statusLabel = formatQueryStatus(query.queryStatus);
          }
        } else {
          statusLabel = formatQueryStatus(query.queryStatus);
        }

        return <span style={{ color: labelColor }}>{statusLabel}</span>;
      }
    },
    {
      name: "Opened By",
      width: "15rem",
      selector: (query: QuerySearchRecord) => query.openedByUsername,
      sortable: true,
      format: (query: QuerySearchRecord) => query.openedByUsername
    },
    {
      name: "Sent To",
      width: "15rem",
      selector: (query: QuerySearchRecord) => query.queryWith,
      sortable: true,
      format: (query: QuerySearchRecord) => query.queryWith
    },
    {
      name: "Reason",
      width: "20rem",
      selector: (query: QuerySearchRecord) => query.queryReasonText,
      sortable: true,
      format: (query: QuerySearchRecord) => {
        return query.queryReasonText ? `${truncate(query.queryReasonText || "", 35)}...` : "";
      }
    },
    {
      name: "Study",
      wrap: true,
      selector: (query: QuerySearchRecord) => query.studyName,
      sortable: true
    },
    {
      name: "Endo Procedure ID",
      width: "25rem",
      wrap: true,
      selector: (query: QuerySearchRecord) => query.caseName,
      manualColumnResize: true,
      sortable: true
    },
    {
      name: "Histo Procedure Id",
      width: "25rem",
      wrap: true,
      selector: (query: QuerySearchRecord) => query.histoProcedureId,
      manualColumnResize: true,
      sortable: true
    },
    {
      name: "Image File Name",
      width: "25rem",
      wrap: true,
      selector: (query: QuerySearchRecord) => query.imageName,
      sortable: true
    },
    {
      name: "Follow Up Due On",
      selector: (query: QuerySearchRecord) => query.latestFollowUpOnDt,
      sortable: true,
      format: (query: QuerySearchRecord) => {
        const isRed = query.latestFollowUpOnDt && new Date() > query.latestFollowUpOnDt;
        /*eslint-disable */
        let output = (
          <span style={isRed ? { color: "red" } : undefined}>
            <Timestamp date={query.latestFollowUpOnDt} />
          </span>
        );
        /*eslint-enable */

        if (query.queryStatus == QueryStatus.Closed) {
          output = <></>;
        }

        return <>{output}</>;
      }
    }
  ];
  const onQuerySearchChange = (searchTerms: string) => {
    dispatch(
      setQueriesFilter({
        ...queriesFilter,
        searchText: searchTerms
      })
    );
  };

  const onStudySelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const studyFilter = e.target.value;

    dispatch(
      setQueriesFilter({
        ...queriesFilter,
        studyFilter: studyFilter.length == 0 ? null : studyFilter
      })
    );
  };

  const onStatusSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const statusFilter = e.target.value;
    dispatch(
      setQueriesFilter({
        ...queriesFilter,
        statusFilter: statusFilter.length == 0 ? null : statusFilter
      })
    );
  };

  const onQueryWithRoleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const roleFilter = e.target.value;

    dispatch(
      setQueriesFilter({
        ...queriesFilter,
        roleFilter: roleFilter.length == 0 ? null : roleFilter
      })
    );
  };

  const onIncludeClosedQueriesChange = () => {
    dispatch(
      setQueriesFilter({
        ...queriesFilter,
        includeClosedQueries: !queriesFilter.includeClosedQueries
      })
    );
  };

  const numOpen = "resource" in queryStats ? queryStats.resource.numOpen : 0;
  const numAnswered = "resource" in queryStats ? queryStats.resource.numAnswered : 0;
  const numClosed = "resource" in queryStats ? queryStats.resource.numClosed : 0;
  const numOverdue = "resource" in queryStats ? queryStats.resource.numOverdue : 0;

  const qcStudySelect =
    "resource" in studies ? (
      <Select
        onChange={onStudySelectChange}
        defaultValue={queriesFilter.studyFilter != null ? queriesFilter.studyFilter : ""}
      >
        <option key={""} value={""}>
          Any study
        </option>
        {studies.resource.map(study => {
          return (
            <option key={study.studyView.study.id} value={study.studyView.study.id}>
              {study.studyView.study.name}
            </option>
          );
        })}
      </Select>
    ) : null;

  const qcStatusSelect = (
    <Select onChange={onStatusSelectChange} defaultValue={""}>
      <option key="" value="">
        Any status
      </option>
      {Object.values(QueryStatus).map(status => {
        return (
          <option key={status} value={status}>
            {formatQueryStatus(status)}
          </option>
        );
      })}
      {Object.values(DynamicQueryStatus).map(status => {
        return (
          <option key={status} value={status}>
            {formatDynamicQueryStatus(status)}
          </option>
        );
      })}
    </Select>
  );

  const queriesOnTab =
    "resource" in allQueries ? allQueries.resource.filter(q => q.query.objectType == queryTab) : [];

  const qcTypeSelect = (
    <Select onChange={onQueryWithRoleSelectChange} value={queriesFilter.roleFilter || ""}>
      <option key="" value="">
        Query With
      </option>
      {"resource" in roles &&
        roles.resource
          .filter(role => QueryWithRoles.includes(role.name))
          .map(role => {
            const roleName = role.displayName;
            return (
              <option key={roleName} value={role.id}>
                {formatRoleName(role.name)}
              </option>
            );
          })}
    </Select>
  );

  const qcIncludeClosedQueries = (
    <Label>
      <Checkbox
        checked={queriesFilter.includeClosedQueries}
        onChange={onIncludeClosedQueriesChange}
        ml={1}
        mr={1}
      />
      <Text color="gray600" style={{ top: "2px", position: "relative", fontWeight: 400 }}>
        Include Closed Queries
      </Text>
    </Label>
  );

  const content = error ? (
    error
  ) : (
    <TableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="queries-search"
            width="auto"
            defaultValue={queriesFilter?.searchText || ""}
            placeholder={"Search by name"}
            onValueChange={onQuerySearchChange}
          />
          <Box paddingLeft="10px">{qcStudySelect}</Box>
          <Box paddingLeft="10px">{qcStatusSelect}</Box>
          <Box paddingLeft="10px">{qcTypeSelect}</Box>
          {queriesFilter.statusFilter != null ? null : (
            <Box paddingLeft="10px">{qcIncludeClosedQueries}</Box>
          )}
        </Box>
      </TableFilters>
      {"resource" in allQueries ? (
        <StyledDataTable
          columns={columns}
          data={queriesOnTab.map(qd => {
            return {
              id: qd.query.id,
              objectType: qd.query.objectType,
              objectId: qd.query.objectId,
              studyId: qd.query.studyId,
              caseId: qd.query.caseId,
              organizationId: qd.query.organizationId,
              queryStatus: qd.query.queryStatus,
              dynamicStatus: qd.query.dynamicStatus,
              queryWith: qd.queryWith,
              queryReasonText: qd.queryReasonText,
              resolution: qd.query.resolution,
              category: qd.query.category,
              categoryOtherText: qd.query.categoryOtherText,
              closeText: qd.query.closeText,
              resolutionText: qd.query.resolutionText,
              firstQueryReminderId: qd.query.firstQueryReminderId,
              lastQueryReminderId: qd.query.lastQueryReminderId,
              latestFollowUpOnDt: qd.query.latestFollowUpOnDt,
              openedAt: qd.query.openedAt,
              openedByUsername: qd.openedByUsername,
              closedAt: qd.query.closedAt,
              closedBy: qd.query.closedBy,
              studyName: qd.studyName,
              caseName: qd.caseName,
              imageName: qd.imageName,
              updatedAt: qd.query.updatedAt
            };
          })}
          highlightOnHover={false}
          pointerOnHover={true}
          onRowClicked={onRowClick}
          defaultSortField="openedAt"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
        />
      ) : null}
    </TableContainer>
  );
  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <PageHeading>Queries</PageHeading>
          <div
            style={{
              display: "flex"
            }}
          >
            <Box className="statBox">
              <div className="statNumber">{numOpen}</div>
              <div>Open</div>
            </Box>
            <div className="statBox">
              <div className="statNumber">{numAnswered}</div>
              <div>Answered</div>
            </div>
            <div className="statBox">
              <div className="statNumber">{numClosed}</div>
              <div>Closed</div>
            </div>
            <div className="statBox">
              <div className="statNumber">{numOverdue}</div>
              <div>Overdue</div>
            </div>
          </div>
        </PageHeader>
        <PageTabs
          links={[
            ...(userCanSeeCases
              ? [
                  {
                    to: `/queries/cases`,
                    label: "Cases"
                  }
                ]
              : []),
            ...(userCanSeeImages
              ? [
                  {
                    to: `/queries/images`,
                    label: "Images"
                  }
                ]
              : [])
          ]}
        />
        {content}
      </Box>
    </Page>
  );
};

export default Queries;
