import React, { useState, Dispatch, SetStateAction } from "react";
import { Button, Box, TextArea, Dialog, Label, Text } from "@blasterjs/core";
import { DialogHeader, DialogBody } from "./DialogLayout";
import { addQueryCommentThunk } from "../slices/queryDetails";
import { useAppDispatch } from "../hooks";
import { QueryDetailRecord, QueryStatus, QueryResolutionType } from "../models";

interface CommentQueryDialogProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  query: QueryDetailRecord;
  issuer: boolean;
}

const CommentQueryDialog: React.FC<CommentQueryDialogProps> = ({
  openDialog,
  setOpenDialog,
  query,
  issuer
}) => {
  const dispatch = useAppDispatch();

  const [comment, setComment] = useState("");

  const onCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newComment = e.target.value;
    setComment(newComment);
  };

  const onSubmitClick = () => {
    //send api request to save comment
    dispatch(addQueryCommentThunk({ queryId: query.id, text: comment }));
    setComment("");
    setOpenDialog(!openDialog);
  };

  return (
    <Dialog isOpen={openDialog} onRequestClose={() => setOpenDialog(false)}>
      <DialogHeader title="Add Comment to Query" closeDialog={() => setOpenDialog(false)} />

      <DialogBody>
        <Label>
          Comments
          <TextArea
            placeholder="Add context here..."
            className="query-comment-textarea"
            onChange={onCommentChange}
            defaultValue={comment}
          />
        </Label>
      </DialogBody>

      {query.queryStatus === QueryStatus.Open &&
      query.resolution !== QueryResolutionType.Resolved &&
      !issuer ? (
        <>
          <Box
            style={{
              backgroundColor: "rgba(227, 43, 36, 0.2)",
              borderRadius: "4px",
              padding: "8px",
              gap: "10px"
            }}
          >
            <Text style={{ color: "#111111" }}>
              <Text style={{ fontWeight: "bold" }}>Disclaimer:</Text> This will NOT progress the
              query to the next step.
            </Text>
          </Box>
          <br />
        </>
      ) : null}

      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button className="query-blue-button" ml="auto" width="25%" onClick={onSubmitClick}>
          {" "}
          Submit{" "}
        </Button>
        <Button
          className="query-white-button"
          ml="1"
          width="25%"
          onClick={() => setOpenDialog(false)}
        >
          {" "}
          Back{" "}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CommentQueryDialog;
