import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Config } from "../models";
import { fetchConfig } from "../api";
import { Resource } from "../types";

export interface ConfigState {
  readonly config: Resource<Config>;
  readonly embedded: boolean;
}

// thunks
export const configFetch = createAsyncThunk("config/configFetch", async () => {
  const response = await fetchConfig();
  return response;
});

export const configSlice = createSlice({
  name: "config",
  initialState: {
    config: {
      isPending: true
    },
    embedded: false
  } as ConfigState,
  reducers: {
    setEmbeddedMode: (state, action: PayloadAction<boolean>) => {
      state.embedded = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(configFetch.pending, state => {
      state.config = { isPending: true };
    });
    builder.addCase(configFetch.fulfilled, (state, action) => {
      state.config = { resource: action.payload };
    });
    builder.addCase(configFetch.rejected, (state, action) => {
      state.config = { errorMessage: action.error.message || "" };
    });
  }
});

export const { setEmbeddedMode } = configSlice.actions;
export default configSlice.reducer;
