import { Box, Button, Icon } from "@blasterjs/core";
import { debounce } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  readonly onChange: (brightness: number) => void;
  readonly onReset: () => void;
}

const BrightnessControls = styled(Box)`
  margin-top: auto;
  position: relative;
  background: #fff;
  padding: 0.2rem 1.2rem 0.2rem 0.2rem;
  display: flex;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.1), 0 0 0 rgba(16, 22, 26, 0),
    0 1px 1px rgba(16, 22, 26, 0.2);
  height: 3.6rem;
  margin-left: 3px;
  margin-right: 3px;
  position: relative;
`;

const ResetButton = styled(Button)`
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  font-size: ${props => props.theme.fontSizes[1]};
`;

const BrightnessAdjust = ({ onChange, onReset }: Props) => {
  const [sliderValue, setValue] = useState(0);
  const debouncedOnChange = debounce(onChange, 100);
  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = parseInt(e.target.value, 10);
    setValue(newValue);
    debouncedOnChange(newValue);
  };
  const onResetButtonClick = () => {
    setValue(0);
    onReset();
  };
  return (
    <BrightnessControls>
      <ResetButton
        title="Reset brightness"
        pl={1}
        pr={1}
        appearance="minimal"
        onClick={onResetButtonClick}
      >
        <Icon name="sun" color="#3d3e40" height="100%" width="16px" />
      </ResetButton>
      <input
        type="range"
        min={-100}
        max={100}
        step={1}
        onChange={onSliderChange}
        value={sliderValue}
        style={{
          position: "relative",
          width: "10rem"
        }}
      />
    </BrightnessControls>
  );
};

export default BrightnessAdjust;
