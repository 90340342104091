import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Roles, UUID } from "../models";
import { Resource } from "../types";

export enum DialogMode {
  OpenForEdit = "OPEN_FOR_EDIT",
  Closed = "CLOSED"
}

export interface ImageManagementBulkCaseState {
  readonly mode: DialogMode;
  readonly availableRoles: Resource<Roles>;
  readonly selectedRole: UUID | null;
}

export const initialState: ImageManagementBulkCaseState = {
  mode: DialogMode.Closed,
  availableRoles: { isPending: false },
  selectedRole: null
};

// thunks
export const openImageManagementBulkCaseDialog = createAsyncThunk(
  "imageManagementBulkCaseDialog/openImageManagementBulkCaseDialog",
  (_: void, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setOpenImageManagementBulkCaseDialog());
  }
);

export const imageManagementBulkCaseDialog = createSlice({
  name: "imageManagementBulkCaseDialog",
  initialState: initialState,
  reducers: {
    setOpenImageManagementBulkCaseDialog: () => {
      return {
        ...initialState,
        mode: DialogMode.OpenForEdit
      };
    },
    closeImageManagementBulkCaseDialog: state => {
      state.mode = DialogMode.Closed;
    },
    setSelectedRole: (state, action: PayloadAction<UUID>) => {
      state.selectedRole = action.payload;
    }
  }
});

export const {
  setOpenImageManagementBulkCaseDialog,
  closeImageManagementBulkCaseDialog,
  setSelectedRole
} = imageManagementBulkCaseDialog.actions;

export default imageManagementBulkCaseDialog.reducer;
