import React, { Dispatch, SetStateAction } from "react";
import { Button, Text, Box, Dialog } from "@blasterjs/core";
import { DialogHeader, DialogBody } from "./DialogLayout";
import { QueryDetailRecord, QueryDetailComment } from "../models";
import Timestamp from "./Timestamp";
import { useAppDispatch } from "../hooks";
import { closeQuery } from "../slices/queryDetails";
import DateYearMonthDay from "./DateYearMonthDay";

interface CancelQueryDialogProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  query: QueryDetailRecord;
  comments: Array<QueryDetailComment>;
  imageName: string | undefined;
}

const CancelQueryDialog: React.FC<CancelQueryDialogProps> = ({
  openDialog,
  setOpenDialog,
  query,
  comments,
  imageName
}) => {
  const dispatch = useAppDispatch();
  const dialogTitle = `Are you sure you want to cancel the following query${
    imageName ? ` for ${imageName}?` : "?"
  }`;

  const createCommentList = (comments: Array<QueryDetailComment>) => {
    const commentsList = comments.map(comment => {
      return (
        <>
          <Text style={{ fontWeight: 400, fontSize: 12 }}>
            {comment.username} - <DateYearMonthDay date={comment.createdAt} />
          </Text>
          <br />
          <Text className={"query-list-result"}>{comment.commentText}</Text>
          <br />
          <br />
        </>
      );
    });

    return commentsList;
  };

  const onConfirmCancelClick = () => {
    //send api request to cancel query
    dispatch(closeQuery({ queryId: query.id, detailedReasonText: "" }));

    setOpenDialog(!openDialog);
  };

  return (
    <Dialog isOpen={openDialog} onRequestClose={() => setOpenDialog(false)}>
      <DialogHeader title={dialogTitle} closeDialog={() => setOpenDialog(false)} />

      <DialogBody>
        <>
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Response
            </Text>
            <Text ml={"auto"} textAlign="right">
              Cancelled
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Response Comments
            </Text>
            <Text ml={"auto"} textAlign="right">
              N/A
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Query ID
            </Text>
            <Text ml={"auto"} textAlign="right">
              {query.id}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Query Type
            </Text>
            <Text ml={"auto"} textAlign="right">
              {query.objectType}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Reason
            </Text>
            <Text ml={"auto"} textAlign="right">
              {query.reasonText} {query.childReasonText ? " - " + query.childReasonText : ""}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Follow Up
            </Text>
            <Text ml={"auto"} textAlign="right">
              <Timestamp date={query.latestFollowUpOnDt} />
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Comments
            </Text>
            <Text ml={"auto"} textAlign="right">
              {createCommentList(comments)}
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Query Opened
            </Text>
            <Text ml={"auto"} textAlign="right">
              <Timestamp date={query.openedAt} />
            </Text>
          </Box>
          <hr />
          <Box display="flex" width="100%">
            <Text textAlign="left" className="query-info-listing-text">
              Query Opened By
            </Text>
            <Text ml={"auto"} textAlign="right">
              {query.createdBy}
            </Text>
          </Box>
        </>
      </DialogBody>

      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button className="query-blue-button" ml="auto" width="40%" onClick={onConfirmCancelClick}>
          {" "}
          Confirm Cancelation{" "}
        </Button>
        <Button
          className="query-white-button"
          ml="1"
          width="25%"
          onClick={() => setOpenDialog(false)}
        >
          {" "}
          Cancel{" "}
        </Button>
      </Box>
    </Dialog>
  );
};

export default CancelQueryDialog;
