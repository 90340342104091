import React from "react";

import {
  Box,
  Button,
  Callout,
  Dialog,
  FileInput,
  Intent,
  ProgressBar,
  Text
} from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "../components/DialogLayout";
import { useAppDispatch, useAppSelector } from "../hooks";
import { changeFiles, closeUploadDialog, signedURLsCreate } from "../slices/uploadDialog";

const UploadDialog = () => {
  const dispatch = useAppDispatch();
  const uploadDialog = useAppSelector(state => state.uploadDialog);

  const closeDialog = () => {
    dispatch(closeUploadDialog());
  };
  const cancelUpload = () => {
    uploadDialog.currentUpload && uploadDialog.currentUpload.cancelTokenSource.cancel();
  };
  const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeFiles(e.target.files));
  };
  const uploadFiles = () => {
    dispatch(signedURLsCreate());
  };
  const errorText = uploadDialog.errorMessage ? (
    <Box>
      <Callout intent={Intent.DANGER}>
        <Text>{uploadDialog.errorMessage}</Text>
      </Callout>
    </Box>
  ) : null;

  const isUploadInitiated =
    "isPending" in uploadDialog.fileSignedURLs && uploadDialog.fileSignedURLs.isPending;
  const isReplacement = uploadDialog.imageToReplace !== null;
  const otherFileInputArgs = isReplacement
    ? {} // Only allow user to select a single image when replacing
    : {
        multiple: "multiple"
      };

  return isUploadInitiated && uploadDialog.currentUpload !== null ? (
    <Dialog isOpen={uploadDialog.isOpen} appElementId="root">
      <DialogHeader title={isReplacement ? "Uploading Image..." : "Uploading Images..."} />
      <DialogBody>
        {Object.values(uploadDialog.currentUpload.uploadProgress).map((upload, idx) => {
          return (
            <Box key={idx}>
              <h3>{upload.file.name}</h3>
              <ProgressBar mt="2" value={upload.progress} max={upload.file.size} />
            </Box>
          );
        })}
      </DialogBody>
      <DialogFooter>
        <Button onClick={cancelUpload}>Stop</Button>
      </DialogFooter>
    </Dialog>
  ) : (
    <Dialog isOpen={uploadDialog.isOpen} onRequestClose={closeDialog} appElementId="root">
      <DialogHeader
        title={isReplacement ? "Select Replacement Image" : "Select Images"}
        closeDialog={closeDialog}
      />
      <DialogBody>
        {errorText}
        <Box>
          <FileInput onChange={onChangeFiles} accept=".tif,.tiff,.svs" {...otherFileInputArgs} />
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button
            disabled={uploadDialog.acceptButtonDisabled}
            onClick={uploadFiles}
            isLoading={isUploadInitiated}
            appearance="prominent"
            intent="primary"
          >
            Upload
          </Button>
        </Box>
      </DialogFooter>
    </Dialog>
  );
};

export default UploadDialog;
