import { Checkbox, Label } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../hooks";
import { toggleAnnotations } from "../slices/caseImageViewer";

const ToggleContainer = styled(Label)`
  border-radius: 0;
  border-bottom: 0;
  height: 3.6rem;
  padding: 6px 10px 0;
  margin-left: -1px;
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2), inset 0 -1px 0 rgba(16, 22, 26, 0.1);
  margin-right: 5px;
  background-color: #fff;
  color: ${props => props.theme.colors.gray900};
  line-height: 0;
  font-size: ${props => props.theme.fontSizes[1]};
`;

const ToggleText = styled.span`
  position: relative;
  top: 1px;
  margin-left: 4px;
`;

const ToggleAnnotations = () => {
  const dispatch = useAppDispatch();
  const hideAnnotations = useAppSelector(state => state.caseImageViewer.hideAnnotations);

  const toggle = () => dispatch(toggleAnnotations());

  return (
    <ToggleContainer>
      <Checkbox checked={!hideAnnotations} onChange={toggle} />
      <ToggleText>Annotations</ToggleText>
    </ToggleContainer>
  );
};

export default ToggleAnnotations;
