import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CasesAndCounts, CaseStatus } from "../models";
import { fetchCases } from "../api";
import { Resource } from "../types";
import { RootState } from "../store";
import { castDraft } from "immer";

export interface CaseFilters {
  readonly procId?: string;
  readonly status?: CaseStatus;
  readonly assignee?: string;
}

export interface CasesState {
  readonly cases: Resource<CasesAndCounts>;
  readonly filters: CaseFilters;
}

export const initialState: CasesState = {
  cases: {
    isPending: false
  },
  filters: {}
};

// thunks
export const casesFetch = createAsyncThunk(
  "cases/casesFetch",
  async (studyId: string, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const response = await fetchCases(
      studyId,
      state.cases.filters.procId,
      state.cases.filters.status,
      state.cases.filters.assignee
    );
    return response;
  }
);

export const setCaseFilters = createAsyncThunk(
  "cases/setCaseFilters",
  async (filters: { studyId: string; caseFilters: CaseFilters }, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setFilters(filters.caseFilters));
    await dispatch(casesFetch(filters.studyId));
  }
);

export const casesSlice = createSlice({
  name: "cases",
  initialState: initialState,
  reducers: {
    clearFilters: state => {
      state.filters = initialState.filters;
    },
    setFilters: (state, action: PayloadAction<CaseFilters>) => {
      state.filters = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(casesFetch.pending, state => {
      state.cases = { isPending: true };
    });
    builder.addCase(casesFetch.fulfilled, (state, action) => {
      state.cases = { resource: castDraft(action.payload) };
    });
    builder.addCase(casesFetch.rejected, (state, action) => {
      state.cases = { errorMessage: action.error.message || "" };
    });
  }
});

export const { clearFilters, setFilters } = casesSlice.actions;
export default casesSlice.reducer;
