import { Button, Icon } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";
import { ZoomDefinition } from "../models";

interface Props {
  readonly zoomDefinitions: ReadonlyArray<ZoomDefinition>;
  readonly zoomIn: () => void;
  readonly zoomOut: () => void;
  readonly zoom: number;
}

const ZoomButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: 3px;
  margin-left: 0;
`;

const ZoomButton = styled(Button)`
  width: 3.6rem;
  height: 3.6rem;
  position: relative;
  border-radius: 0;
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: ${props => props.theme.fontSizes[1]};
  line-height: 0;
  margin-left: -1px;
`;

// Workaround since upgrading to styled-components v5 causes unwanted style changes and isn't being
// purused for now. See https://github.com/microsoft/TypeScript/issues/37597
const ZoomValue = styled(ZoomButton as any)`
  width: 4.8rem;
  background-image: none;
  pointer-events: none;
  &.overzoomed {
    background-color: ${props => props.theme.colors.yellowTint};
  }
`;

const ImageZoom = ({ zoomIn, zoomOut, zoom, zoomDefinitions }: Props) => {
  const activeMag = zoomDefinitions.find(definition => definition.zoom === zoom);
  return (
    <ZoomButtons>
      <ZoomButton onClick={zoomOut}>
        <Icon name="minus" />
      </ZoomButton>
      <ZoomValue className={activeMag && activeMag.overzoomed ? "overzoomed" : ""}>
        <span style={{ position: "relative", top: 1 }}>{activeMag && activeMag.mag}</span>
      </ZoomValue>
      <ZoomButton onClick={zoomIn}>
        <Icon name="plus" />
      </ZoomButton>
    </ZoomButtons>
  );
};

export default ImageZoom;
