import React, { useState } from "react";

import { Box, Button, Dialog, Select, Label, TextInput } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "./DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  CaseRowOptions,
  DialogMode,
  archiveCase,
  closeOpenImageManagementCaseDialogParent,
  putCaseOnHold,
  revertCaseStatus,
  removeCaseHold
} from "../slices/ImageManagementCaseDialogParent";
import { CaseStatus, formatCaseStatus } from "../models";
import { UserFeedbackMessage } from "./ImageManagementImageDialogParent";

const ImageManagementCaseDialogParent = () => {
  const dispatch = useAppDispatch();

  const dialogMode = useAppSelector(state => state.imageManagementCaseDialogParent.mode);
  const modalParams = useAppSelector(state => state.imageManagementCaseDialogParent.modalParams);

  const closeDialog = () => {
    dispatch(closeOpenImageManagementCaseDialogParent());
  };

  const childControl = () => {
    switch (modalParams?.caseRowOption) {
      case CaseRowOptions.RevertStatus:
        return <RevertStatusControl />;
      case CaseRowOptions.PutOnHold:
        return <PutOnHoldControl />;
      case CaseRowOptions.RemoveHold:
        return <RemoveHoldControl />;
      case CaseRowOptions.Download:
        return <DownloadImages />;
      case CaseRowOptions.Archive:
        return <ArchiveControl />;
      default:
        return <div>-</div>;
    }
  };

  return (
    <Dialog
      isOpen={dialogMode === DialogMode.OpenForEdit}
      onRequestClose={closeDialog}
      appElementId="root"
      width="600px"
    >
      {childControl()}
    </Dialog>
  );
};

export default ImageManagementCaseDialogParent;

/*
  child controls that will conditionally render in the parent dialog
*/

const RevertStatusControl = () => {
  const dispatch = useAppDispatch();

  const [reasonForChange, setReasonForChange] = useState("");
  const [selectedCaseStatus, setSelectedCaseStatus] = useState<CaseStatus | null>(null);

  const modalParams = useAppSelector(state => state.imageManagementCaseDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementCaseDialogParent.modalMessage);

  const caseId = modalParams && "caseId" in modalParams ? modalParams.caseId : "";

  const closeDialog = () => {
    dispatch(closeOpenImageManagementCaseDialogParent());
  };

  const onSave = () => {
    if (caseId && selectedCaseStatus && reasonForChange.length > 0) {
      dispatch(
        revertCaseStatus({
          caseId: caseId,
          caseStatus: selectedCaseStatus,
          reasonForChange: reasonForChange
        })
      );
    }
  };

  const onSelectStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCaseStatus(event.currentTarget.value as CaseStatus);
  };

  const reasonForChangeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(event.currentTarget.value);
  };

  const title = "Revert Status";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>Case Statuses</Label>
            <Select onChange={onSelectStatus} defaultValue={""}>
              <option key="" value="">
                What status would you like to revert the following case to?
              </option>
              {Object.values(CaseStatus)
                .filter(status => status !== CaseStatus.Invalid)
                .map(status => {
                  return (
                    <option key={status} value={status}>
                      {formatCaseStatus(status)}
                    </option>
                  );
                })}
            </Select>
          </Box>
          <Box style={{ width: "100%" }}>
            <Label>Reason for Change</Label>
            <TextInput
              placeholder={"Enter reason"}
              value={reasonForChange}
              onChange={reasonForChangeOnChange}
              style={{ paddingLeft: "1rem" }}
            />
          </Box>
          {modalMessage && (
            <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
          )}
        </Box>
      </DialogBody>
      <DialogFooter>
        {!modalMessage && (
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onSave}>
              Revert Status
            </Button>
          </Box>
        )}
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

const PutOnHoldControl = () => {
  const dispatch = useAppDispatch();

  const [reasonForChange, setReasonForChange] = useState("");

  const modalParams = useAppSelector(state => state.imageManagementCaseDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementCaseDialogParent.modalMessage);
  const caseId = modalParams && "caseId" in modalParams ? modalParams.caseId : "";

  const closeDialog = () => {
    dispatch(closeOpenImageManagementCaseDialogParent());
  };

  const reasonForChangeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(event.currentTarget.value);
  };

  const onSave = () => {
    if (caseId && reasonForChange.length > 0) {
      dispatch(putCaseOnHold({ caseId: caseId, reasonForChange: reasonForChange }));
    }
  };

  const title = "Are you sure you want to put this case On Hold?";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>Reason for Change</Label>
            <TextInput
              placeholder={"Enter reason"}
              value={reasonForChange}
              onChange={reasonForChangeOnChange}
              style={{ paddingLeft: "1rem" }}
            />
          </Box>
          {modalMessage && (
            <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
          )}
        </Box>
      </DialogBody>
      <DialogFooter>
        {!modalMessage && (
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onSave}>
              Put On Hold
            </Button>
          </Box>
        )}
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

const RemoveHoldControl = () => {
  const dispatch = useAppDispatch();

  const [reasonForChange, setReasonForChange] = useState("");

  const modalParams = useAppSelector(state => state.imageManagementCaseDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementCaseDialogParent.modalMessage);
  const caseId = modalParams && "caseId" in modalParams ? modalParams.caseId : "";

  const closeDialog = () => {
    dispatch(closeOpenImageManagementCaseDialogParent());
  };

  const reasonForChangeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(event.currentTarget.value);
  };

  const onSave = () => {
    if (caseId && reasonForChange.length > 0) {
      dispatch(removeCaseHold({ caseId: caseId, reasonForChange: reasonForChange }));
    }
  };

  const title = "Are you sure you want to remove the hold for this case?";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>Reason for Change</Label>
            <TextInput
              placeholder={"Enter reason"}
              value={reasonForChange}
              onChange={reasonForChangeOnChange}
              style={{ paddingLeft: "1rem" }}
            />
          </Box>
          {modalMessage && (
            <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
          )}
        </Box>
      </DialogBody>
      <DialogFooter>
        {!modalMessage && (
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onSave}>
              Remove Hold
            </Button>
          </Box>
        )}
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

const DownloadImages = () => {
  const dispatch = useAppDispatch();

  const closeDialog = () => {
    dispatch(closeOpenImageManagementCaseDialogParent());
  };

  const onSave = () => {
    //console.log("saving...");
  };

  const title = "Download images in Case";

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box display="block" justifyContent="space-between" mb={2}>
          <Box style={{ width: "100%" }}>
            <Label>Are you sure you want to download the images in this case?</Label>
          </Box>
        </Box>
      </DialogBody>
      <DialogFooter>
        <Box>
          <Button intent="primary" appearance="prominent" onClick={onSave}>
            Download Case
          </Button>
        </Box>
        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};

const ArchiveControl = () => {
  const dispatch = useAppDispatch();

  const modalParams = useAppSelector(state => state.imageManagementCaseDialogParent.modalParams);
  const modalMessage = useAppSelector(state => state.imageManagementCaseDialogParent.modalMessage);

  const [reasonForChange, setReasonForChange] = useState("");

  const caseId = modalParams && "caseId" in modalParams ? modalParams.caseId : "";

  const closeDialog = () => {
    dispatch(closeOpenImageManagementCaseDialogParent());
  };

  const title = "Are you sure you want to archive";

  const reasonForChangeOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonForChange(event.currentTarget.value);
  };

  const onSave = () => {
    if (caseId && reasonForChange.length > 0) {
      dispatch(archiveCase({ caseId: caseId, reasonForChange: reasonForChange }));
    }
  };

  return (
    <>
      <DialogHeader title={title} closeDialog={closeDialog} />
      <DialogBody>
        <Box style={{ width: "100%" }}>
          <Label>Reason for Change</Label>
          <TextInput
            placeholder={"Enter reason"}
            value={reasonForChange}
            onChange={reasonForChangeOnChange}
            style={{ paddingLeft: "1rem" }}
          />
        </Box>
        {modalMessage && (
          <UserFeedbackMessage message={modalMessage.message} success={modalMessage.success} />
        )}
      </DialogBody>
      <DialogFooter>
        {!modalMessage && (
          <Box>
            <Button intent="primary" appearance="prominent" onClick={onSave}>
              Archive Case
            </Button>
          </Box>
        )}

        <Box>
          <Button onClick={closeDialog}>Cancel</Button>
        </Box>
      </DialogFooter>
    </>
  );
};
