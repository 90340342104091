import { Box, Button, Dialog } from "@blasterjs/core";
import React from "react";

import { DialogBody, DialogFooter, DialogHeader } from "./DialogLayout";

interface Props {
  readonly title: string;
  readonly message: string;
  readonly isOpen: boolean;
  readonly onConfirm: (...args: any) => void;
  readonly onCancel: (...args: any) => void;
}

const ConfirmationDialog = ({ title, message, isOpen, onConfirm, onCancel }: Props) => (
  <Dialog isOpen={isOpen} onRequestClose={onCancel} appElementId="root">
    <DialogHeader title={title} closeDialog={onCancel} />
    <DialogBody>{message}</DialogBody>
    <DialogFooter>
      <Box>
        <Button appearance="prominent" intent="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Box>
      <Box>
        <Button onClick={onCancel}>Cancel</Button>
      </Box>
    </DialogFooter>
  </Dialog>
);

export default ConfirmationDialog;
