import { A, Appearance, Button, Image } from "@blasterjs/core";
import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import Avatar from "./Avatar";
import { Nav, NavLink } from "./Nav";

import { Config } from "../models";
import { Resource } from "../types";
import TooltipFlyout, { TooltipLink } from "./Tooltip";
import { useAppDispatch, useAppSelector } from "../hooks";
import WorkAsDialog from "./WorkAsDialog";
import { openWorkAsDialog } from "../slices/workAsDialog";
import { AppDispatch } from "../store";
import { LoggedInUser, RolePermissions } from "../permissions";

const HeaderContainer = styled.div`
  display: flex;
  height: ${props => props.theme.heights.appHeader.height};
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
  z-index: 1;
  z-index: 10000;
`;

const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.4rem;
  margin-left: -1rem;
`;

const HeaderActions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoStyle = styled(Image)`
  height: 3.1rem;
  width: 8.5rem;
  max-width: none;
`;

interface StateProps {
  readonly loggedInUser: Resource<LoggedInUser>;
  readonly config: Resource<Config>;
  readonly dispatch: AppDispatch;
}

type UserButtonProps = StateProps;

const UserButton = ({ loggedInUser, config, dispatch }: UserButtonProps) => {
  return "resource" in loggedInUser ? (
    <Button
      data-tooltip={true}
      iconAfter="caretDown"
      isLoading={"isPending" in loggedInUser}
      appearance={Appearance.MINIMAL}
    >
      <Avatar user={loggedInUser.resource.user} />
      <TooltipFlyout placement={"bottom-end"}>
        <TooltipLink href={"#"} onClick={() => dispatch(openWorkAsDialog())}>
          Work As...
        </TooltipLink>
        <TooltipLink href={"resource" in config ? config.resource.ssoLoginUrl : "#"}>
          RCT Hub
        </TooltipLink>
        <TooltipLink href={"resource" in config ? config.resource.ssoLogoutUrl : "#"}>
          Logout
        </TooltipLink>
      </TooltipFlyout>
    </Button>
  ) : (
    <A
      href={"resource" in config ? config.resource.ssoLoginUrl : "#"}
      style={{ textDecoration: "none" }}
    >
      <Button isLoading={"isPending" in loggedInUser} appearance={Appearance.MINIMAL}>
        Log in
      </Button>
    </A>
  );
};

const Header = () => {
  const dispatch = useAppDispatch();
  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const config = useAppSelector(state => state.config.config);

  const location = useLocation();
  const usersLink = (
    <NavLink key={"users"} to="/users" path={location.pathname}>
      Users
    </NavLink>
  );
  const studiesLink = (
    <NavLink key={"studies"} to="/studies" path={location.pathname}>
      Studies
    </NavLink>
  );
  const reportsLink = (
    <NavLink key={"reports"} to="/reports" path={location.pathname}>
      Reports
    </NavLink>
  );
  const queriesLink = (
    <NavLink key={"queries"} to="/queries/images" path={location.pathname}>
      Queries
    </NavLink>
  );
  const imageManagementLink = (
    <NavLink key={"imageManagement"} to="/image-management/images" path={location.pathname}>
      Image Management
    </NavLink>
  );

  /* eslint-disable functional/immutable-data */
  const links: Array<JSX.Element> = [];

  if ("resource" in loggedInUser) {
    if (loggedInUser.resource.can([RolePermissions.S_Main_ViewStudyPage])) {
      links.push(studiesLink);
    }
    if (
      loggedInUser.resource.can([RolePermissions.IM_CaseListTab_ViewPage]) ||
      loggedInUser.resource.can([RolePermissions.IM_ImageListTab_ViewImageslistTab])
    ) {
      links.push(imageManagementLink);
    }
    if (loggedInUser.resource.can([RolePermissions.R_ViewReportsTab])) {
      links.push(reportsLink);
    }
    if (
      loggedInUser.resource.can([RolePermissions.Q_ViewQueriesCases]) ||
      loggedInUser.resource.can([RolePermissions.Q_ViewQueriesImages])
    ) {
      links.push(queriesLink);
    }
    if (loggedInUser.resource.can([RolePermissions.UM_ViewUsersPage])) {
      links.push(usersLink);
    }
  }

  return (
    <header>
      <HeaderContainer>
        <HeaderLogo>
          <NavLink logo={true} to="/" path={location.pathname} active={false}>
            <LogoStyle src="/images/lucidity-logo.png" alt="Lucidity" />
          </NavLink>
        </HeaderLogo>
        <Nav>{links}</Nav>
        <HeaderActions>
          <span style={{ fontSize: "1.4rem", paddingRight: "1em" }}>
            {"resource" in loggedInUser && loggedInUser.resource.user.workAsRole
              ? loggedInUser.resource.user.workAsRole.displayName
              : ""}
          </span>
          <WorkAsDialog />
          <UserButton config={config} loggedInUser={loggedInUser} dispatch={dispatch} />
        </HeaderActions>
      </HeaderContainer>
    </header>
  );
};

export default Header;
