import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StudyListViews, StudyView } from "../models";
import { fetchStudies, fetchStudy } from "../api";
import { Resource } from "../types";
import { RootState } from "../store";
import { castDraft } from "immer";

export interface StudiesState {
  readonly studies: Resource<StudyListViews>;
  readonly studiesFilter: string | null;
  readonly study: Resource<StudyView>;
}

export const initialState: StudiesState = {
  studies: {
    isPending: false
  },
  studiesFilter: null,
  study: {
    isPending: false
  }
};

// thunks
export const studiesFetch = createAsyncThunk("studies/studiesFetch", async (_: void, thunkApi) => {
  const { getState } = thunkApi;
  const state = getState() as RootState;
  const response = await fetchStudies(state.studies.studiesFilter ?? undefined);
  return response;
});

export const studyFetch = createAsyncThunk("studies/studyFetch", async (studyId: string) => {
  const response = await fetchStudy(studyId);
  return response;
});

export const setStudiesFilterThunk = createAsyncThunk(
  "studies/setStudiesFilterThunk",
  async (filter: string, thunkApi) => {
    const { dispatch } = thunkApi;
    dispatch(setStudiesFilter(filter));
    await dispatch(studiesFetch());
  }
);

export const studiesSlice = createSlice({
  name: "studies",
  initialState: initialState,
  reducers: {
    setStudiesFilter: (state, action: PayloadAction<string | null>) => {
      state.studiesFilter = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(studiesFetch.pending, state => {
      state.studies = { isPending: true };
    });
    builder.addCase(studiesFetch.fulfilled, (state, action) => {
      state.studies = { resource: castDraft(action.payload) };
    });
    builder.addCase(studiesFetch.rejected, (state, action) => {
      state.studies = { errorMessage: action.error.message || "" };
    });

    builder.addCase(studyFetch.pending, state => {
      state.study = { isPending: true };
    });
    builder.addCase(studyFetch.fulfilled, (state, action) => {
      state.study = { resource: castDraft(action.payload) };
    });
    builder.addCase(studyFetch.rejected, (state, action) => {
      state.study = { errorMessage: action.error.message || "" };
    });
  }
});

export const { setStudiesFilter } = studiesSlice.actions;
export default studiesSlice.reducer;
