import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ImageListViews, UUID } from "../models";
import { fetchImages } from "../api";
import { Resource } from "../types";
import { castDraft } from "immer";
import { RootState } from "../store";

export interface ImageFilters {
  readonly name?: string;
  readonly hasQueries?: boolean | null;
  readonly isUnassigned?: boolean | null;
}

export interface ImagesState {
  readonly images: Resource<ImageListViews>;
  readonly filters: ImageFilters;
}

export const initialState: ImagesState = {
  images: {
    isPending: false
  },
  filters: {}
};

export interface ImageFilterParams {
  readonly studyId: UUID;
  readonly filters: ImageFilters;
}

// thunks
export const imagesFetch = createAsyncThunk(
  "images/imagesFetch",
  async (studyId: UUID, thunkApi) => {
    const { getState } = thunkApi;
    const state = getState() as RootState;
    const filters = state.images.filters;
    const response = await fetchImages(
      studyId,
      filters.name || null,
      filters.hasQueries || null,
      filters.isUnassigned || null
    );
    return response;
  }
);

export const setImageFiltersAndFetch = createAsyncThunk(
  "images/imagesFetch",
  async (imageFilterParams: ImageFilterParams, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    dispatch(setImageFilters(imageFilterParams.filters));
    const state = getState() as RootState;
    const filters = state.images.filters;
    const response = await fetchImages(
      imageFilterParams.studyId,
      filters.name || null,
      filters.hasQueries || null,
      filters.isUnassigned || null
    );
    return response;
  }
);

export const imagesSlice = createSlice({
  name: "images",
  initialState: initialState,
  reducers: {
    clearFilters: state => {
      state.filters = initialState.filters;
    },
    setImageFilters: (state, action: PayloadAction<ImageFilters>) => {
      state.filters = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(imagesFetch.pending, state => {
      state.images = { isPending: true };
    });
    builder.addCase(imagesFetch.fulfilled, (state, action) => {
      state.images = { resource: castDraft(action.payload) };
    });
    builder.addCase(imagesFetch.rejected, (state, action) => {
      state.images = { errorMessage: action.error.message || "" };
    });
  }
});

export const { clearFilters, setImageFilters } = imagesSlice.actions;

export default imagesSlice.reducer;
