import React from "react";
import { Polyline, PolylineProps, Popup } from "react-leaflet";
import { MultilineAnnotationClass, MultilineAnnotationClassesWithCount } from "../models";
import { UUID, MultilineCategory } from "../models";
import MultilineAnnotationTooltip from "./MultilineAnnotationTooltip";
import { useEffect, useRef } from "react";
import { useAppSelector } from "../hooks";
import L, { LatLngExpression } from "leaflet";
import { setSelectedMultilineCategory, setSelectedMultilineClass } from "../slices/caseImageViewer";
import { AppDispatch } from "../store";

interface Props extends PolylineProps {
  readonly annotationId: UUID;
  readonly annotationClasses?: MultilineAnnotationClassesWithCount;
  readonly currentAnnotationClass: MultilineAnnotationClass;
  readonly color?: string;
  readonly multilineCategory: MultilineCategory;
  readonly mapElement: L.Map | undefined;
  readonly canDelete: boolean;
  dispatch: AppDispatch;
}

export const defaultColor = "#00FF00";

const MultilineAnnotation = ({
  positions,
  annotationId,
  annotationClasses,
  currentAnnotationClass,
  color,
  multilineCategory,
  mapElement,
  canDelete,
  dispatch
}: Props) => {
  const popupRef = useRef<L.Popup>(null);

  const selectedMultilineClass = useAppSelector(
    state => state.caseImageViewer.selectedMultilineClass
  );
  const selectedMultilineCategory = useAppSelector(
    state => state.caseImageViewer.selectedMultilineCategory
  );

  const celiacTableVisibility = useAppSelector(
    state => state.caseImageViewer.celiacTableVisibility
  );

  const hidden = !celiacTableVisibility;

  useEffect(() => {
    if (popupRef.current) {
      if (
        selectedMultilineCategory === multilineCategory &&
        selectedMultilineClass &&
        selectedMultilineClass.id === currentAnnotationClass.id
      ) {
        if (mapElement) {
          const latLng = positions.at(Math.floor((positions.length - 1) / 2)) as LatLngExpression;
          popupRef.current.setLatLng(latLng).openOn(mapElement);
        }
      }
    }
  }, [selectedMultilineClass, selectedMultilineCategory]);

  return hidden ? (
    <></>
  ) : (
    <Polyline
      positions={positions}
      color={color || defaultColor}
      weight={5}
      dashArray={multilineCategory === MultilineCategory.CryptDepth ? [10, 15] : undefined}
      eventHandlers={{
        click: () => {
          dispatch(setSelectedMultilineClass(currentAnnotationClass));
          dispatch(setSelectedMultilineCategory(multilineCategory));
        }
      }}
    >
      <Popup ref={popupRef} closeButton={false} className="annotation-popup">
        <MultilineAnnotationTooltip
          annotationClasses={annotationClasses}
          currentAnnotationClassId={currentAnnotationClass.id}
          multilineCategory={multilineCategory}
          annotationId={annotationId}
          canDelete={canDelete}
          dispatch={dispatch}
        ></MultilineAnnotationTooltip>
      </Popup>
    </Polyline>
  );
};

export default MultilineAnnotation;
