import { createSlice } from "@reduxjs/toolkit";

export interface LocatorMapState {
  readonly isLocatorMapPanelExpanded: boolean;
}

export const initialState: LocatorMapState = {
  isLocatorMapPanelExpanded: true
};

export const locationMapSlice = createSlice({
  name: "locationMap",
  initialState: initialState,
  reducers: {
    toggleLocatorMapPanelExpanded: state => {
      state.isLocatorMapPanelExpanded = !state.isLocatorMapPanelExpanded;
    }
  }
});

export const { toggleLocatorMapPanelExpanded } = locationMapSlice.actions;
export default locationMapSlice.reducer;
