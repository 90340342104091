import React from "react";
import { Box } from "@blasterjs/core";

const SuccessMessage = () => (
  <Box
    style={{
      color: "gray",
      height: "30rem",
      fontSize: "300%",
      margin: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
  >
    Image successfully marked as scored.
    <br />
    Please return to your Noto dashboard.
  </Box>
);

export default SuccessMessage;
