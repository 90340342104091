import styled from "styled-components";

const TopBar = styled.div`
  height: ${props => props.theme.heights.appHeader.height};
  padding: 0.8rem 0;
  background-color: #263340;
  display: flex;
  align-items: center;
  color: #fff;
`;

export default TopBar;
