import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminCase, CaseStatus } from "../models";
import { WriteResource } from "../types";
import { RootState } from "../store";
import { revertCaseStatusApi } from "../api";
import { casesFetch } from "./cases";
import { castDraft } from "immer";

export interface CaseStatusForm {
  readonly status: CaseStatus | null;
  readonly reasonForChange: string | null;
}

export type CaseStatusDialogState =
  | {
      readonly isOpen: true;
      readonly savedCase: AdminCase;
      readonly histoCase: WriteResource<CaseStatusForm, AdminCase>;
    }
  | {
      readonly isOpen: false;
    };

// thunks
export const revertCaseStatus = createAsyncThunk(
  "caseStatusDialog/revertCaseStatus",
  async (_: void, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const rootState = getState() as RootState;
    const state = rootState.caseStatusDialog;
    if (state.isOpen && state.histoCase.data.status) {
      const response = await revertCaseStatusApi(state.savedCase.id, {
        value: state.histoCase.data.status,
        reasonForChange: state.histoCase.data.reasonForChange || undefined
      });
      await dispatch(casesFetch(state.savedCase.studyId));
      dispatch(resetState());
      return response;
    }
  }
);

export const caseStatusDialogSlice = createSlice({
  name: "caseStatusDialog",
  initialState: { isOpen: false } as CaseStatusDialogState,
  reducers: {
    resetState: state => {
      state.isOpen = false;
    },
    openCaseStatusDialog: (state, action: PayloadAction<AdminCase>) => {
      state.isOpen = true;
      if (state.isOpen) {
        state.savedCase = castDraft(action.payload);
        state.histoCase = {
          data: {
            status: null,
            reasonForChange: null
          }
        };
      }
    },
    closeCaseStatusDialog: state => {
      state.isOpen = false;
    },
    setCaseStatusForm: (state, action: PayloadAction<CaseStatusForm>) => {
      if (state.isOpen) {
        state.histoCase = {
          data: action.payload
        };
      }
    }
  },
  extraReducers: builder => {
    builder.addCase(revertCaseStatus.rejected, (state, action) => {
      if (state.isOpen) {
        state.histoCase = {
          data: state.histoCase.data,
          errorMessage: action.error.message || ""
        };
      }
    });
  }
});

export const {
  resetState,
  openCaseStatusDialog,
  closeCaseStatusDialog,
  setCaseStatusForm
} = caseStatusDialogSlice.actions;
export default caseStatusDialogSlice.reducer;
