import { Box, Callout, Intent, Text } from "@blasterjs/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { MetadataImage, MetadataImageType, UUID } from "../models";
import { Resource } from "../types";
import Expandable, { CollapseIcon } from "./Expandable";
import { useAppDispatch, useAppSelector } from "../hooks";
import { fetchMetadataImage, toggleMetadataExpanded, toggleZoom } from "../slices/metadataImages";

interface Props {
  readonly imageId: UUID | null;
}

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const ZoomContainer = styled.figure`
  .active &:hover img {
    opacity: 0;
  }
`;

const ZoomImages = styled.div`
  &.active {
    cursor: zoom-out;
  }
  &.inactive {
    cursor: zoom-in;
  }
  @supports (-moz-appearance: none) {
    &.active {
      cursor: pointer;
    }
    &.inactive {
      cursor: pointer;
    }
  }
  > :last-child {
    border-top: 1px solid #ddd;
  }
`;

const CollapseIconContainer = styled.div`
  background: #fff;
  transform: scaleX(-1);
`;

interface ZoomImageProps {
  readonly width: number;
  readonly src: string;
  readonly alt: string;
}

/*
 * Image which zooms on mouse over.
 *
 * Based on: https://codepen.io/robertkirsz/pen/ZvorjB
 */
const ZoomImage = ({ width, src, alt }: ZoomImageProps) => {
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%");

  const handleMouseMove = (e: React.MouseEvent<HTMLElement>) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setBackgroundPosition(`${x}% ${y}%`);
  };

  return (
    <ZoomContainer
      onMouseMove={handleMouseMove}
      style={{
        width,
        backgroundImage: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition
      }}
    >
      <img
        src={src}
        alt={alt}
        style={{
          display: "block",
          width: "100%",
          pointerEvents: "none"
        }}
      />
    </ZoomContainer>
  );
};

const showImage = (metadataImage: Resource<MetadataImage<MetadataImageType>>) =>
  "resource" in metadataImage ? (
    <ZoomImage
      width={300}
      src={metadataImage.resource.imageData}
      alt={metadataImage.resource.metadataType === "label" ? "Label image" : "Macro image"}
    />
  ) : "errorMessage" in metadataImage ? (
    <Callout intent={Intent.DANGER}>
      <Text>{metadataImage.errorMessage}</Text>
    </Callout>
  ) : (
    <Box className="loader" />
  );

const MetadataDialog = ({ imageId }: Props) => {
  const dispatch = useAppDispatch();
  const label = useAppSelector(state => state.metadataImages.label);
  const macro = useAppSelector(state => state.metadataImages.macro);
  const zoomActive = useAppSelector(state => state.metadataImages.zoomActive);
  const isMetadataPanelExpanded = useAppSelector(
    state => state.metadataImages.isMetadataPanelExpanded
  );

  useEffect(() => {
    dispatch(fetchMetadataImage({ uri: `/api/images/${imageId}/label`, metadataType: "label" }));
    dispatch(fetchMetadataImage({ uri: `/api/images/${imageId}/macro`, metadataType: "macro" }));
  }, [imageId]);

  return (
    <ImagesContainer>
      <Expandable
        label={"Macro/Label"}
        isExpanded={isMetadataPanelExpanded}
        onToggle={() => dispatch(toggleMetadataExpanded())}
      >
        <CollapseIconContainer>
          <CollapseIcon
            onToggle={(e: React.MouseEvent) => {
              e.stopPropagation();
              dispatch(toggleMetadataExpanded());
            }}
          />
        </CollapseIconContainer>
        <ZoomImages
          className={zoomActive ? "active" : "inactive"}
          onClick={() => dispatch(toggleZoom())}
        >
          {showImage(macro)}
          {showImage(label)}
        </ZoomImages>
      </Expandable>
    </ImagesContainer>
  );
};

export default MetadataDialog;
