import React, { useState, Dispatch, SetStateAction } from "react";
import { Button, Box, Dialog, Label, Text } from "@blasterjs/core";
import { DialogHeader, DialogBody } from "./DialogLayout";
import FollowUpDatePicker from "./FollowUpDatePicker";
import { QueryRecord } from "../models";
import Timestamp from "./Timestamp";
import { followUpQuery } from "../slices/queryDetails";
import { useAppDispatch } from "../hooks";
import { getDaysFromDateSelected } from "../utils";

interface FollowUpQueryDialogProps {
  openDialog: boolean;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
  query: QueryRecord;
}

const FollowUpQueryDialog: React.FC<FollowUpQueryDialogProps> = ({
  openDialog,
  setOpenDialog,
  query
}) => {
  const dispatch = useAppDispatch();

  const [followUpDate, setfollowUpDate] = useState(
    query.latestFollowUpOnDt && query.latestFollowUpOnDt > new Date()
      ? query.latestFollowUpOnDt
      : new Date()
  );

  const onConfirmClick = () => {
    if (query.lastQueryReminderId) {
      dispatch(
        followUpQuery({
          queryId: query.id,
          queryReminderId: query.lastQueryReminderId,
          followUpInDays: getDaysFromDateSelected(followUpDate)
        })
      );
    }

    setOpenDialog(!openDialog);
  };

  return (
    <Dialog isOpen={openDialog} onRequestClose={() => setOpenDialog(false)}>
      <DialogHeader
        title="When do you want to send a follow up?"
        closeDialog={() => setOpenDialog(false)}
      />

      <DialogBody>
        <Text>
          Current follow up date:{" "}
          <Timestamp date={query.latestFollowUpOnDt ? query.latestFollowUpOnDt : new Date(0)} />
          <b></b>
        </Text>
        <br />
        <Box mt={2}>
          <Label>
            Flag for follow-up on
            <Box>
              <FollowUpDatePicker selectedDate={followUpDate} setSelectedDate={setfollowUpDate} />
            </Box>
          </Label>
        </Box>
      </DialogBody>

      <Box display="flex" justifyContent="flex-end" width="100%">
        <Button className="query-blue-button" ml="auto" width="25%" onClick={onConfirmClick}>
          {" "}
          Confirm{" "}
        </Button>
        <Button
          className="query-white-button"
          ml="1"
          width="25%"
          onClick={() => setOpenDialog(false)}
        >
          {" "}
          Cancel{" "}
        </Button>
      </Box>
    </Dialog>
  );
};

export default FollowUpQueryDialog;
