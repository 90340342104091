import React from "react";
import { Box, Callout } from "@blasterjs/core";
import Page, { PageHeader, PageHeading } from "../components/Page";

const AccessDenied = () => (
  <Page>
    <PageHeader>
      <PageHeading>Access Denied</PageHeading>
    </PageHeader>
    <Box style={{ padding: "0 2rem 4rem" }}>
      <Callout>
        Please contact your administrator or switch to a role that has the appriopriate permissions
        to access this page.
      </Callout>
    </Box>
  </Page>
);

export default AccessDenied;
