import styled from "styled-components";

const InfoSidebar = styled.div`
  width: ${props => props.theme.widths.sidebar.width};
  background-color: #fff;
  padding: 1.5rem 1.3rem;
  overflow-y: auto;
`;

export default InfoSidebar;
