import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Organizations } from "../models";
import { fetchOrganizations } from "../api";
import { Resource } from "../types";
import { castDraft } from "immer";

export interface OrganizationState {
  readonly organizations: Resource<Organizations>;
}

export const initialState: OrganizationState = {
  organizations: {
    isPending: false
  }
};

// thunks
export const organizationsFetch = createAsyncThunk(
  "organizations/organizationsFetch",
  async (_: void) => {
    const response = await fetchOrganizations();
    return response;
  }
);

export const organizationsSlice = createSlice({
  name: "organizations",
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(organizationsFetch.pending, state => {
      state.organizations = { isPending: true };
    });
    builder.addCase(organizationsFetch.fulfilled, (state, action) => {
      state.organizations = { resource: castDraft(action.payload) };
    });
    builder.addCase(organizationsFetch.rejected, (state, action) => {
      state.organizations = { errorMessage: action.error.message || "" };
    });
  }
});

//export const {  } = organizationsSlice.actions;
export default organizationsSlice.reducer;
