import React, { useState } from "react";

import { Box, Dialog } from "@blasterjs/core";

import { DialogBody, DialogFooter, DialogHeader } from "./DialogLayout";

import { useAppDispatch, useAppSelector } from "../hooks";
import {
  clearSearchFilter,
  closeCaseFilterDialog,
  setCaseStatusFilter
} from "../slices/imageManagement";
import { CaseStatus, formatCaseStatus } from "../models";
import {
  SearchFilterName,
  SearchFilterNavLink,
  SearchFilterNavLinkDiv,
  StudyFilterControl,
  UploadDateControl
} from "./ImageManagementSearchFilters";

interface ImageManagementSearchFiltersProps {
  isOpen: boolean;
}

const CaseStatusControl = () => {
  const dispatch = useAppDispatch();

  const caseStatusFilter = useAppSelector(
    state => state.imageManagement.caseResultFilters.filters.caseStatusFilter
  );

  const filterOnClick = (filter: CaseStatus | null) => {
    const statuses = caseStatusFilter ? [...caseStatusFilter] : [];
    if (filter) {
      if (statuses.indexOf(filter) == -1) {
        dispatch(setCaseStatusFilter([...statuses, filter]));
      }
    } else {
      dispatch(setCaseStatusFilter(null));
    }
  };

  return (
    <div>
      <ol>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={CaseStatus.PendingQC}
          onClick={() => filterOnClick(CaseStatus.PendingQC)}
        >
          Pending QC
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={CaseStatus.PendingLabQC}
          onClick={() => filterOnClick(CaseStatus.PendingLabQC)}
        >
          Pending Lab QC
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={CaseStatus.Processed}
          onClick={() => filterOnClick(CaseStatus.Processed)}
        >
          Processed
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={CaseStatus.OnHold}
          onClick={() => filterOnClick(CaseStatus.OnHold)}
        >
          On Hold
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={CaseStatus.Completed}
          onClick={() => filterOnClick(CaseStatus.Completed)}
        >
          Completed
        </SearchFilterNavLink>
        <SearchFilterNavLink
          style={{ cursor: "pointer" }}
          key={CaseStatus.Archived}
          onClick={() => filterOnClick(CaseStatus.Archived)}
        >
          Archived
        </SearchFilterNavLink>
      </ol>
      {caseStatusFilter && (
        <>
          <hr />
          {caseStatusFilter.map(filter => (
            <div key={filter}>{formatCaseStatus(filter)}</div>
          ))}
          <SearchFilterNavLinkDiv style={{ cursor: "pointer" }} onClick={() => filterOnClick(null)}>
            clear X
          </SearchFilterNavLinkDiv>
        </>
      )}
    </div>
  );
};

const CaseSearchFilters = ({ isOpen }: ImageManagementSearchFiltersProps) => {
  const dispatch = useAppDispatch();

  const [selectedFilter, setSelectedFilter] = useState<SearchFilterName | null>(null);

  const closeDialog = () => {
    dispatch(closeCaseFilterDialog());
  };

  const childControl = () => {
    switch (selectedFilter) {
      case SearchFilterName.Study:
        return <StudyFilterControl imageOrCase={"Case"} />;
      case SearchFilterName.UploadDate:
        return <UploadDateControl imageOrCase={"Case"} />;
      case SearchFilterName.Status:
        return <CaseStatusControl />;
      default:
        return <></>;
    }
  };

  const filterOnClick = (name: SearchFilterName | null) => {
    setSelectedFilter(name);
  };

  return (
    <Dialog isOpen={isOpen} onRequestClose={closeDialog} appElementId="root" width="600px">
      <DialogHeader title={"Search Filters"} closeDialog={closeDialog} />
      <DialogBody>
        <Box>
          {selectedFilter == null && (
            <div>
              <ol>
                <SearchFilterNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(SearchFilterName.Study)}
                >
                  Study Name
                </SearchFilterNavLink>
                <SearchFilterNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(SearchFilterName.UploadDate)}
                >
                  Upload Date
                </SearchFilterNavLink>
                <SearchFilterNavLink
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(SearchFilterName.Status)}
                >
                  Case Status
                </SearchFilterNavLink>
              </ol>
            </div>
          )}
          {childControl()}
        </Box>
        <DialogFooter>
          <Box display="flex" width="100%" justifyContent="space-between" mb={2}>
            <Box>
              {selectedFilter !== null && (
                <SearchFilterNavLinkDiv
                  style={{ cursor: "pointer" }}
                  onClick={() => filterOnClick(null)}
                >
                  back
                </SearchFilterNavLinkDiv>
              )}
            </Box>
            <Box>
              <SearchFilterNavLinkDiv
                style={{ cursor: "pointer" }}
                onClick={() => dispatch(clearSearchFilter())}
              >
                clear
              </SearchFilterNavLinkDiv>
            </Box>
          </Box>
        </DialogFooter>
      </DialogBody>
    </Dialog>
  );
};

export default CaseSearchFilters;
