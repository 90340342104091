import React from "react";

import { formatIndication, Indication } from "../models";
import TooltipText from "./TooltipText";

interface Props {
  readonly indication: Indication;
}

const IndicationText = ({ indication }: Props) => {
  const text = formatIndication(indication);
  return <TooltipText text={text}>{text}</TooltipText>;
};

export default IndicationText;
