import { Box } from "@blasterjs/core";
import { debounce } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";

interface Props {
  readonly onChange: (index: number) => void;
  readonly zStackSize: number;
}

const ZStackControl = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30px;
  height: 180px;
  padding: 0;
  background-color: white;
`;

const ZStackSlider = ({ onChange, zStackSize }: Props) => {
  const [sliderValue, setValue] = useState(0);
  const debouncedOnChange = debounce(onChange, 100);
  const onSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: number = parseInt(e.target.value, 10);
    setValue(newValue);
    debouncedOnChange(newValue);
  };
  return (
    <ZStackControl>
      <span style={{ margin: "0 auto" }}>Z</span>
      <input
        type="range"
        min={0}
        max={zStackSize - 1}
        step={1}
        onChange={onSliderChange}
        value={sliderValue}
        style={{
          width: 150,
          height: 20,
          marginLeft: 5,
          transformOrigin: "75px 75px",
          transform: "rotate(-90deg)"
        }}
      />
    </ZStackControl>
  );
};

export default ZStackSlider;
