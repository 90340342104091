import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./pages/App";
import { store } from "./store";

import "@fontsource/inter/variable-full.css";
import "./css/index.css";
import { configFetch } from "./slices/config";
import { injectStoreDispatchInApi } from "./api";
import { injectStoreDispatchInAnnotationDrawings } from "./components/annotationDrawing";

injectStoreDispatchInApi(store.dispatch);
injectStoreDispatchInAnnotationDrawings(store.dispatch);

void store.dispatch(configFetch());

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
