/*
 * WARNING: This file makes use of:
 * - classes
 * - hooking into prototype methods
 * - lots of mutable state
 * - general hackery
 *
 * This is necessitated by the way Leaflet plugins work -- many layers of class
 * inheritence and mixing in functionality on top of existing classes -- and
 * the general poor code quality of the `leaflet-ellipse` and
 * `leaflet.draw-ellipse` libraries (eg. hooking into `L.Edit.Ellipse`
 * prototype methods because the typical edit events are never emitted from
 * `leaflet.draw-ellipse`).
 *
 * Given that this can't be helped (without dropping Leaflet altogether and
 * finding new libraries or writing our own), the intent is to keep the mess
 * contained within this file.
 */
import L, { LatLngLiteral } from "leaflet";
import { debounce } from "lodash";

import { Indication } from "../models";
import { AppDispatch } from "../store";
import { assertNever } from "../types";
import { getHpfRadiusInMeters } from "../utils";
import { defaultProps as defaultPointProps } from "./PointAnnotation";
import {
  AnnotationType,
  cancelImageAnnotation,
  createFreehandImageAnnotation,
  createLineImageAnnotation,
  createMultilineImageAnnotation,
  createPointImageAnnotation,
  setImageAnnotationForEllipseType,
  setImageAnnotationForHPFType,
  setImageAnnotationForTextType
} from "../slices/caseImageViewer";

interface LineLayer extends L.Polyline {
  readonly _latlngs: ReadonlyArray<LatLngLiteral>;
}

interface EllipseLayer extends L.Polyline {
  readonly _center: LatLngLiteral;
  readonly _semiMajor: number;
  readonly _semiMinor: number;
  readonly _bearing: number;
  readonly _latlngs: ReadonlyArray<LatLngLiteral>;
}

interface TextLayer extends L.Polyline {
  readonly _latlng: LatLngLiteral;
}

interface HPFLayer extends L.Polyline {
  readonly _mRadius: number;
  readonly _latlng: LatLngLiteral;
}

interface FreehandLayer extends L.Polygon {
  readonly _latlngs: ReadonlyArray<ReadonlyArray<LatLngLiteral>>;
}

/* eslint-disable */
/*
 * A "fake" handler which partially implements the L.Draw.Feature interface.
 *
 * It serves as a convenient way for us to bind and unbind click events for drawing points.
 */
class PointDrawHandler {
  private readonly map: L.DrawMap;
  private readonly onClick: L.LeafletEventHandlerFn;

  constructor(map: L.DrawMap, onClick: L.LeafletEventHandlerFn) {
    this.map = map;
    this.onClick = onClick;
  }

  enable() {
    this.map.on("click", this.onClick);
  }

  disable() {
    this.map.off("click", this.onClick);
  }
}
/* eslint-enable */

// This is used by manually overriding styles and so setting it to an empty string allows the normal
// map styles to take effect.
const DEFAULT_MAP_CURSOR = "";
const ANNOTATING_CURSOR = "crosshair";

type ActiveLayer = EditableLayer | UneditableLayer;
type EditableLayer = EllipseLayer | TextLayer | HPFLayer;
type UneditableLayer = LineLayer | FreehandLayer;

/* eslint-disable functional/no-let */
let lineDrawHandler: L.Draw.Polyline | undefined;

let multilineDrawHandler: L.Draw.Polyline | undefined;

let ellipseDrawHandler: L.Draw.Ellipse | undefined;

let textDrawHandler: L.Draw.Marker | undefined;

let hpfDrawHandler: L.Draw.Circle | undefined;

let freehandDrawHandler: L.FreeHandShapes | undefined;

let pointDrawHandler: PointDrawHandler | undefined;

let activeLayer: ActiveLayer | undefined;
/* eslint-enable functional/no-let */

const TextAnnotationIcon = L.Icon.extend({
  options: {
    iconUrl: "/images/icon-text.svg",
    iconSize: [25, 25],
    iconAnchor: [12, 12],
    popupAnchor: [0, -4],
    className: ANNOTATING_CURSOR
  }
});

// TODO: MIGRATION - confirm dispatched actions are getting fired correctly
const updateAnnotation = (layer: EditableLayer, dispatch: AppDispatch) => {
  "_center" in layer
    ? dispatch(
        setImageAnnotationForEllipseType({
          point: layer._center,
          radiusX: layer._semiMajor,
          radiusY: layer._semiMinor,
          tilt: layer._bearing
        })
      )
    : "_mRadius" in layer
    ? dispatch(setImageAnnotationForHPFType({ point: layer._latlng, radius: layer._mRadius }))
    : "_latlng" in layer && dispatch(setImageAnnotationForTextType(layer._latlng));
};
const debouncedUpdateAnnotation = debounce(updateAnnotation, 100);

/* eslint-disable */
// Extend L.Edit.Ellipse to hook into moving/resizing/rotating functions
const ellipseMove = L.Edit.Ellipse.prototype._move;
const ellipseResize = L.Edit.Ellipse.prototype._resize;
const ellipseRotate = L.Edit.Ellipse.prototype._rotate;

let dispatch: AppDispatch;

export const injectStoreDispatchInAnnotationDrawings = (_dispatch: AppDispatch) => {
  dispatch = _dispatch;
};

L.Edit.Ellipse = L.Edit.Ellipse.extend({
  _move: function (latLng: L.LatLngLiteral) {
    debouncedUpdateAnnotation(this._shape, dispatch);
    ellipseMove.call(this, latLng);
  },
  _resize: function (latLng: L.LatLngLiteral) {
    debouncedUpdateAnnotation(this._shape, dispatch);
    ellipseResize.call(this, latLng);
  },
  _rotate: function (latLng: L.LatLngLiteral) {
    debouncedUpdateAnnotation(this._shape, dispatch);
    ellipseRotate.call(this, latLng);
  }
});
const ellipseFireCreatedEvent = L.Draw.Ellipse.prototype._fireCreatedEvent;
L.Draw.Ellipse = L.Draw.Ellipse.extend({
  _fireCreatedEvent: function (e: L.LeafletMouseEvent) {
    if (this._shape) {
      ellipseFireCreatedEvent.call(this, e);
    }
  }
});
const leafletFreehandShapesStopDraw = L.FreeHandShapes.prototype.stopDraw;
L.FreeHandShapes = L.FreeHandShapes.extend({
  stopDraw: function () {
    try {
      leafletFreehandShapesStopDraw.call(this);
    } catch {
      // `leaflet-freehandshapes` interferes with the other annotation drawers since it wants to do
      // things to the map that also affect the drawing of other annotations (doesn't play nice with
      // others). The workaround for that misbehavior is to remove the handler from the map when a
      // different annotation type is selected or when a different freehand annotation class is
      // selected (in order to change color). However, in addition to not playing nice with others
      // it also doesn't clean up after itself nicely as it assumes the map will always be there and
      // thus can throw runtime errors triggered by mouse events if it has been removed from the
      // map.
    }
  }
});
/* eslint-enable */

interface Layer {
  readonly _icon: HTMLElement;
  readonly _index: number | undefined;
  readonly _latlng: LatLngLiteral;
}

/*
 * Set HPF (High-powered field) layer to the correct size and disallow resizing.
 */
const setHPFLayerSize = (
  activeLayer: HPFLayer,
  pixelSize: number,
  resolutionInMeters: number,
  indications: ReadonlyArray<Indication> | null
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  activeLayer.editing._shape.setRadius(
    getHpfRadiusInMeters(pixelSize, resolutionInMeters, indications)
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const layers = Object.values(activeLayer.editing._markerGroup._layers) as ReadonlyArray<Layer>;
  layers
    .map((layer: Layer) => layer._icon)
    .forEach(
      // Remove resize icon to ensure HPF stays same size
      (icon: HTMLElement) => icon.classList.contains("leaflet-edit-resize") && icon.remove()
    );
};

/*
 * Clean up any in-progress annotations
 */
export const cleanup = (map: L.DrawMap | undefined) => {
  removeActiveLayer();
  disableDrawHandlers();
  if (map) {
    // Reset to default map cursor since draw handlers modify this (and we also manually override it
    // to make key shortcuts work appropriately)
    // eslint-disable-next-line functional/immutable-data
    map.getContainer().style.cursor = DEFAULT_MAP_CURSOR;
  }
};

export const removeActiveLayer = () => activeLayer && activeLayer.remove();

export const disableDrawHandlers = () => {
  lineDrawHandler && lineDrawHandler.disable();
  multilineDrawHandler && multilineDrawHandler.disable();
  textDrawHandler && textDrawHandler.disable();
  ellipseDrawHandler && ellipseDrawHandler.disable();
  hpfDrawHandler && hpfDrawHandler.disable();
  freehandDrawHandler && freehandDrawHandler.setMode("view");
  pointDrawHandler && pointDrawHandler.disable();
};

export const reinitializeAnnotationDrawing = (
  dispatch: AppDispatch,
  map: L.DrawMap,
  annotationType: AnnotationType,
  freehandAnnotationColor: string,
  pointAnnotationColor: string,
  multilineAnnotationColor: string,
  pixelSize: number,
  resolutionInMeters: number,
  scaleFactor: number,
  indications: ReadonlyArray<Indication> | null,
  hpfRadius: number,
  spacebarDown: boolean,
  setSpacebarDown: (x: boolean) => void
) => {
  // Unbind any already-bound events related to annotation drawing
  map.off([L.Draw.Event.CREATED, L.Draw.Event.EDITMOVE, "keydown", "keyup"].join(" "));
  if (freehandDrawHandler) {
    freehandDrawHandler.off("layeradd");
  }

  const editFeatureGroup = new L.FeatureGroup();
  map.addLayer(editFeatureGroup);

  // NOTE: Hack to enable programmatic editing without edit toolbar.
  //       See https://github.com/Leaflet/Leaflet.draw/issues/129
  const editToolbar = new L.EditToolbar({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    featureGroup: editFeatureGroup
  });
  const editHandler = editToolbar.getModeHandlers(map)[0]?.handler;

  // Set up all handlers. Some of these respond to the same events so they're all set up at once and
  // then handlers are enabled/disabled as appropriate.
  lineDrawHandler = new L.Draw.Polyline(map, {
    maxPoints: 2,
    repeatMode: true,
    shapeOptions: { color: "#0f0" },
    touchIcon: new L.DivIcon({ iconSize: new L.Point(4, 4) }),
    icon: new L.DivIcon({ iconSize: new L.Point(4, 4) })
  });
  multilineDrawHandler = new L.Draw.Polyline(map, {
    maxPoints: 0,
    repeatMode: true,
    shapeOptions: { color: multilineAnnotationColor },
    touchIcon: new L.DivIcon({ iconSize: new L.Point(4, 4) }),
    icon: new L.DivIcon({ iconSize: new L.Point(4, 4) })
  });
  ellipseDrawHandler = new L.Draw.Ellipse(map);
  textDrawHandler = new L.Draw.Marker(map, { icon: new TextAnnotationIcon() });
  hpfDrawHandler = new L.Draw.Circle(map);

  freehandDrawHandler && freehandDrawHandler.removeFrom(map);
  freehandDrawHandler = new L.FreeHandShapes({
    polygon: {
      smoothFactor: 0,
      fillOpacity: 0,
      color: freehandAnnotationColor,
      className: ANNOTATING_CURSOR
    },
    polyline: {
      color: freehandAnnotationColor,
      className: ANNOTATING_CURSOR
    },
    /* eslint-disable camelcase */
    simplify_tolerance: 0,
    merge_polygons: false,
    concave_polygons: false
    /* eslint-enable camelcase */
  });
  freehandDrawHandler.on("layeradd", ((e: L.LayerEvent) => {
    activeLayer = e.layer as FreehandLayer;
    activeLayer._latlngs[0] &&
      dispatch(
        createFreehandImageAnnotation({
          positions: activeLayer._latlngs[0],
          cleanupCallback: removeActiveLayer
        })
      );
  }) as L.LeafletEventHandlerFn);
  freehandDrawHandler.addTo(map);
  pointDrawHandler = new PointDrawHandler(map, ((e: L.LeafletMouseEvent) => {
    if (!spacebarDown) {
      // Since point annotations don't have a real draw handler or active layer, we manually add a
      // circle to the map that can be removed once it has been saved server-side.
      const position = e.latlng;
      const point = L.circle(position, {
        ...defaultPointProps,
        fillColor: pointAnnotationColor,
        radius: defaultPointProps.radius * scaleFactor
      }).addTo(map);
      const removePointCallback = () => point.removeFrom(map);
      void dispatch(
        createPointImageAnnotation({
          point: e.latlng,
          hpfRadius: hpfRadius,
          cleanupCallback: removePointCallback
        })
      );
    }
  }) as L.LeafletEventHandlerFn);

  map.on(L.Draw.Event.CREATED, ((e: L.DrawEvents.Created) => {
    // NOTE: Freehand layers do not use Leaflet Draw so this event won't return a freehand layer
    activeLayer = e.layer as Exclude<ActiveLayer, FreehandLayer>;

    // If line annotation, create instantly and don't edit
    if (
      !("_center" in activeLayer) && // rule out ellipse
      "_latlngs" in activeLayer
    ) {
      switch (annotationType) {
        case AnnotationType.Multiline:
          void dispatch(
            createMultilineImageAnnotation({
              points: activeLayer._latlngs,
              cleanupCallback: removeActiveLayer
            })
          );
          break;
        default:
          void dispatch(
            createLineImageAnnotation({
              points: activeLayer._latlngs,
              cleanupCallback: removeActiveLayer
            })
          );
      }
    } else {
      // Enter into editing mode
      activeLayer && editFeatureGroup.addLayer(activeLayer);
      editHandler && editHandler.enable();
      "_mRadius" in activeLayer &&
        pixelSize !== null &&
        setHPFLayerSize(activeLayer, pixelSize, resolutionInMeters, indications);

      activeLayer && updateAnnotation(activeLayer, dispatch);
    }
  }) as L.LeafletEventHandlerFn);
  map.on(L.Draw.Event.EDITMOVE, ((e: L.DrawEvents.EditMove) => {
    updateAnnotation(e.layer as EditableLayer, dispatch);
  }) as L.LeafletEventHandlerFn);

  const reinitializeDrawHandlers = () => {
    disableDrawHandlers();

    // Enable one draw handler based on annotation type
    annotationType === AnnotationType.Line
      ? lineDrawHandler && lineDrawHandler.enable()
      : annotationType === AnnotationType.Multiline
      ? multilineDrawHandler && multilineDrawHandler.enable()
      : annotationType === AnnotationType.Ellipse
      ? ellipseDrawHandler && ellipseDrawHandler.enable()
      : annotationType === AnnotationType.Text
      ? textDrawHandler && textDrawHandler.enable()
      : annotationType === AnnotationType.HPF
      ? hpfDrawHandler && hpfDrawHandler.enable()
      : annotationType === AnnotationType.Freehand
      ? freehandDrawHandler && freehandDrawHandler.setMode("add")
      : annotationType === AnnotationType.Point
      ? pointDrawHandler && pointDrawHandler.enable()
      : assertNever(annotationType);
  };

  reinitializeDrawHandlers();

  // NOTE: The `leaflet-freehandshapes` library messes with the map's dragging when added,
  // interfering with the functioning of other annotations' draw handlers when added to the map
  // without being used. Thus, we explicitly set map draggability here depending on annotation
  // type based on whether or not the annotation type requires dragging for drawing (in which
  // case we don't want the map to drag).
  annotationType === AnnotationType.HPF ||
  annotationType === AnnotationType.Ellipse ||
  annotationType === AnnotationType.Freehand
    ? map.dragging.disable()
    : map.dragging.enable();

  // Focus map to ensure key events are captured by the map. Without this the annotation buttons
  // themselves can remain focused, resulting in confusing behavior.
  map.getContainer().focus();

  const updateCursor = (isPaused: boolean) => {
    // eslint-disable-next-line functional/immutable-data
    map.getContainer().style.cursor =
      isPaused || annotationType === null ? DEFAULT_MAP_CURSOR : ANNOTATING_CURSOR;
  };
  updateCursor(false);

  // Set up ESC to cancel annotation and SPACE to "pause" annotation drawing
  map.on("keydown", ((e: L.LeafletKeyboardEvent) => {
    if (e.originalEvent.key === " ") {
      updateCursor(true);
      if (annotationType !== AnnotationType.Point) {
        disableDrawHandlers();
      }
      if (annotationType !== AnnotationType.Freehand) {
        setSpacebarDown(true);
      }
      e.originalEvent.preventDefault();
    }
  }) as L.LeafletEventHandlerFn);
  map.on("keyup", ((e: L.LeafletKeyboardEvent) => {
    if (e.originalEvent.key === "Escape") {
      cleanup(map);
      dispatch(cancelImageAnnotation());
    }
    if (e.originalEvent.key === " ") {
      updateCursor(false);
      if (activeLayer && !map.hasLayer(activeLayer)) {
        // Only reinitialize draw handlers if the layer has not already been placed on the map.
        // Otherwise the user will be able to draw a new layer after they let go of spacebar.
        reinitializeDrawHandlers();
      }
      if (annotationType !== AnnotationType.Freehand) {
        setSpacebarDown(false);
      }
      e.originalEvent.preventDefault();
    }
  }) as L.LeafletEventHandlerFn);
};
