import { createSlice } from "@reduxjs/toolkit";

export interface MapState {
  readonly zoom: number;
  readonly selectedImage: number;
  readonly imageMenuOverflow: boolean;
}

export const initialState: MapState = {
  zoom: 0,
  selectedImage: 0,
  imageMenuOverflow: false
};

export const mapSlice = createSlice({
  name: "map",
  initialState: initialState,
  reducers: {
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    setSelectedImage: (state, action) => {
      state.selectedImage = action.payload;
    },
    setImageMenuOverflow: (state, action) => {
      state.imageMenuOverflow = action.payload;
    }
  }
});

export const { setZoom, setSelectedImage, setImageMenuOverflow } = mapSlice.actions;
export default mapSlice.reducer;
