import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MetadataImage, MetadataImageType } from "../models";
import { Resource } from "../types";
import { fetchMetadataImageApi } from "../api";

export interface MetadataImagesState {
  readonly label: Resource<MetadataImage<"label">>;
  readonly macro: Resource<MetadataImage<"macro">>;
  readonly zoomActive: boolean;
  readonly isMetadataPanelExpanded: boolean;
}

export const initialState: MetadataImagesState = {
  label: {
    isPending: false
  },
  macro: {
    isPending: false
  },
  zoomActive: false,
  isMetadataPanelExpanded: true
};

// params
export interface fetchMetadataImageParams {
  uri: string;
  metadataType: MetadataImageType;
}

// thunks
export const fetchMetadataImage = createAsyncThunk(
  "metadataImages/fetchMetadataImage",
  async (params: fetchMetadataImageParams) => {
    const response = await fetchMetadataImageApi(params.uri, params.metadataType);
    return response;
  }
);

export const metadataImagesSlice = createSlice({
  name: "metadataImages",
  initialState: initialState,
  reducers: {
    toggleZoom: state => {
      state.zoomActive = !state.zoomActive;
    },
    toggleMetadataExpanded: state => {
      state.isMetadataPanelExpanded = !state.isMetadataPanelExpanded;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchMetadataImage.pending, (state, action) => {
      if (action.meta.arg.metadataType == "label") {
        state.label = {
          isPending: true
        };
      } else {
        state.macro = {
          isPending: true
        };
      }
    });
    builder.addCase(fetchMetadataImage.fulfilled, (state, action) => {
      if (action.meta.arg.metadataType == "label") {
        state.label = { resource: action.payload } as Resource<MetadataImage<"label">>;
      } else {
        state.macro = { resource: action.payload } as Resource<MetadataImage<"macro">>;
      }
    });
    builder.addCase(fetchMetadataImage.rejected, (state, action) => {
      if (action.meta.arg.metadataType == "label") {
        state.label = { errorMessage: action.error.message } as Resource<MetadataImage<"label">>;
      } else {
        state.macro = { errorMessage: action.error.message } as Resource<MetadataImage<"macro">>;
      }
    });
  }
});

export const { toggleZoom, toggleMetadataExpanded } = metadataImagesSlice.actions;
export default metadataImagesSlice.reducer;
