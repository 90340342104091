import React, { useEffect, useState } from "react";
import { Box, Button, Icon, Select, Label, TextInput } from "@blasterjs/core";
import { Role, roleLabel, Study, StudyAccess, studyLabel, UUID } from "../models";

import Page, { PageBody, PageHeader, PageHeading, PageTabs } from "../components/Page";

import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import { dataTableTheme } from "../theme";
import { useAppDispatch, useAppSelector } from "../hooks";
import { userSummariesFetch } from "../slices/users";
import { useParams } from "react-router-dom";
import { organizationsFetch } from "../slices/organizations";
import {
  addStudyAccessRequest,
  changeUserForm,
  setNewStudyAccess,
  studiesForUserFetch,
  userFetch,
  UserForm,
  userStudyAccessFetch,
  userUpdate
} from "../slices/UserProfile";
import { rolesFetch } from "../slices/permissions";
import { redirectAction } from "../slices/auth";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { removeStudyAccessRequest } from "../slices/studyConfiguration";
import { RolePermissions } from "../permissions";

const EditUser = () => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const usersFilter = useAppSelector(state => state.users.usersFilter);

  useEffect(() => {
    dispatch(userSummariesFetch());
  }, [usersFilter]);

  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>User Profile</PageHeading>
        </PageHeader>
        {"resource" in loggedInUser &&
          loggedInUser.resource.can([RolePermissions.UM_ViewUsersPage]) && <UserProfile />}
      </Box>
    </Page>
  );
};

interface FormValidations {
  readonly firstNameRequired: string | null;
  readonly lastNameRequired: string | null;
  readonly emailRequired: string | null;
  readonly errorsExist: boolean;
}

function validateForm(userForm: UserForm): FormValidations {
  const firstNameMissing = userForm.firstName ? false : true;
  const lastNameMissing = userForm.lastName ? false : true;
  const emailMissing = userForm.email ? false : true;

  return {
    firstNameRequired: firstNameMissing ? "First Name Required" : "",
    lastNameRequired: lastNameMissing ? "Last Name Required" : "",
    emailRequired: emailMissing ? "Email Required" : "",
    errorsExist: firstNameMissing || lastNameMissing || emailMissing
  };
}

export default EditUser;

interface UserSettingsProps {
  readonly userId: string;
}
const UserSettings = ({ userId }: UserSettingsProps) => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const userForm = useAppSelector(state => state.userProfile.userForm);
  const organizations = useAppSelector(state => state.organizations.organizations);
  const formValidations = validateForm(userForm);

  useEffect(() => {
    dispatch(userFetch(userId));
    dispatch(organizationsFetch());
  }, []);

  const firstNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeUserForm({
        ...userForm,
        firstName: e.currentTarget.value
      })
    );
  };

  const lastNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeUserForm({
        ...userForm,
        lastName: e.currentTarget.value
      })
    );
  };

  const emailOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeUserForm({
        ...userForm,
        email: e.currentTarget.value
      })
    );
  };

  const onOrganizationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      changeUserForm({
        ...userForm,
        organizationId: e.currentTarget.value
      })
    );
  };

  const saveOnClick = async () => {
    await dispatch(userUpdate());
    dispatch(redirectAction(`/users`));
  };
  const cancelOnClick = () => {
    dispatch(redirectAction(`/users`));
  };

  return (
    <div>
      <Box>
        <Label>
          First Name
          <Box
            style={{
              display: formValidations.errorsExist ? "block" : "none",
              color: "red"
            }}
          >
            {formValidations.firstNameRequired}
          </Box>
          <TextInput
            placeholder="First Name"
            mb={2}
            value={userForm.firstName || ""}
            onChange={firstNameOnChange}
          />
        </Label>
      </Box>
      <Box>
        <Label>
          Last Name
          <Box
            style={{
              display: formValidations.errorsExist ? "block" : "none",
              color: "red"
            }}
          >
            {formValidations.lastNameRequired}
          </Box>
          <TextInput
            placeholder="Last Name"
            mb={2}
            value={userForm.lastName || ""}
            onChange={lastNameOnChange}
          />
        </Label>
      </Box>
      <Box>
        <Label>
          Email Address
          <Box
            style={{
              display: formValidations.errorsExist ? "block" : "none",
              color: "red"
            }}
          >
            {formValidations.emailRequired}
          </Box>
          <TextInput
            placeholder="Email Address"
            mb={2}
            value={userForm.email || ""}
            onChange={emailOnChange}
          />
        </Label>
      </Box>
      <Box>
        <Label>
          Organization
          <Select onChange={onOrganizationChange} value={userForm.organizationId || ""}>
            <option key="" value="" />
            {"resource" in organizations &&
              organizations.resource.map(organization => {
                return (
                  <option key={organization.id} value={organization.id}>
                    {organization.name}
                  </option>
                );
              })}
          </Select>
        </Label>
      </Box>
      {"resource" in loggedInUser && loggedInUser.resource.can([RolePermissions.UM_RegisterUser]) && (
        <Box
          style={{
            margin: "1.6rem -1.2rem 0",
            padding: "1.6rem 1.2rem 0",
            borderTop: "1px solid rgb(221, 224, 230)"
          }}
        >
          <Box>
            <Button
              isLoading={false}
              intent="primary"
              appearance="prominent"
              disabled={false}
              onClick={saveOnClick}
            >
              Save
            </Button>
            <Button
              style={{
                marginLeft: "15px"
              }}
              isLoading={false}
              onClick={cancelOnClick}
            >
              Discard Changes
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

const UserProfile = () => {
  const params = useParams();
  const userId: string | null = params.id || null;
  const tab: string = params.tab || "settings";

  return (
    <PageBody>
      <PageTabs
        links={[
          {
            to: `/users/${userId}/settings`,
            label: "User Settings"
          },
          {
            to: `/users/${userId}/studyAccess`,
            label: "Study Access"
          }
        ]}
      />

      {userId !== null ? (
        tab == "settings" ? (
          <UserSettings userId={userId || ""} />
        ) : (
          <StudyAccessTable userId={userId || ""} />
        )
      ) : null}
    </PageBody>
  );
};

interface StudyAccessTablesProps {
  readonly userId: string;
}
interface RemoveUserFromStudy {
  readonly studyId: UUID;
  readonly userId: UUID;
  readonly roleId: UUID;
  readonly studyName: string;
  readonly roleName: string;
}
const initialRemoveUserFromStudy: RemoveUserFromStudy | null = null;

const StudyAccessTable = ({ userId }: StudyAccessTablesProps) => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);

  useEffect(() => {
    dispatch(userFetch(userId));
    dispatch(userStudyAccessFetch(userId));
    dispatch(studiesForUserFetch());
    dispatch(rolesFetch());
  }, []);

  const [isAddUserConfirmationDialogOpen, setAddUserConfirmationDialogOpen] = useState(false);
  const [isRemoveAccessConfirmationDialogOpen, setRemoveAccessConfirmationDialogOpen] = useState(
    false
  );
  const [removeUserFromStudy, setRemoveUserFromStudy] = useState<RemoveUserFromStudy | null>(
    initialRemoveUserFromStudy
  );

  const editUser = useAppSelector(state => state.userProfile.editUser);
  const studiesForUser = useAppSelector(state => state.userProfile.studiesForUser);
  const studiesAccess = useAppSelector(state => state.userProfile.studiesAccess);
  const newStudyAccess = useAppSelector(state => state.userProfile.newStudyAccess);
  const roles = useAppSelector(state => state.permissions.roles);

  const columns: ReadonlyArray<object> = [
    {
      name: "Study Name",
      selector: (sa: StudyAccess) => sa.studyName,
      sortable: true
    },
    {
      name: "Role",
      selector: (sa: StudyAccess) => sa.roleName,
      sortable: true
    },
    {
      right: true,
      grow: 0,
      cell: (sa: StudyAccess) =>
        "resource" in loggedInUser &&
        loggedInUser.resource.can([RolePermissions.UM_RevokeStudyAccess]) ? (
          <Button
            isLoading={false}
            intent="primary"
            appearance="prominent"
            disabled={false}
            onClick={removeStudyAccessClick(
              userId,
              sa.studyId,
              sa.roleId,
              sa.studyName,
              sa.roleName
            )}
          >
            Remove Access
          </Button>
        ) : (
          <></>
        )
    }
  ];

  const onStudyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const studyId = e.currentTarget.value;
    const study: Study | undefined =
      "resource" in studiesForUser ? studiesForUser.resource.find(r => r.id == studyId) : undefined;
    dispatch(
      setNewStudyAccess({
        studyId: studyId,
        roleId: newStudyAccess.roleId,
        studyName: study?.name || null,
        roleName: newStudyAccess.roleName
      })
    );
  };

  const onRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const roleId = e.currentTarget.value;
    const role: Role | undefined =
      "resource" in roles ? roles.resource.find(r => r.id == roleId) : undefined;

    dispatch(
      setNewStudyAccess({
        studyId: newStudyAccess.studyId,
        roleId: roleId,
        studyName: newStudyAccess.studyName,
        roleName: role?.name || null
      })
    );
  };

  const saveStudyAccessClick = () => {
    setAddUserConfirmationDialogOpen(true);
  };

  const addUserToStudyConfirmationClick = () => {
    setAddUserConfirmationDialogOpen(false);
    if (newStudyAccess.roleId && newStudyAccess.studyId) {
      dispatch(addStudyAccessRequest(userId)).then(() => dispatch(userStudyAccessFetch(userId)));
      dispatch(setNewStudyAccess({ studyId: null, roleId: null, studyName: null, roleName: null }));
    }
  };

  const onCancelConfirmationDialog = () => {
    setAddUserConfirmationDialogOpen(false);
  };

  const addUserToStudyConfirmTransitionDialog = (
    <ConfirmationDialog
      title="Confirm Study Access"
      message={`${"resource" in editUser ? editUser.resource.username : ""} will be assigned as a ${
        newStudyAccess.roleName
      } to ${newStudyAccess.studyName}`}
      isOpen={isAddUserConfirmationDialogOpen}
      onConfirm={addUserToStudyConfirmationClick}
      onCancel={onCancelConfirmationDialog}
    />
  );

  const removeStudyAccessClick = (
    userId: string,
    studyId: string,
    roleId: string,
    studyName: string,
    roleName: string
  ) => () => {
    const params: RemoveUserFromStudy | null = {
      studyId: studyId,
      userId: userId,
      roleId: roleId,
      studyName: studyName,
      roleName: roleName
    };
    setRemoveUserFromStudy(params);
    setRemoveAccessConfirmationDialogOpen(true);
  };

  const removeStudyAccessConfirmationClick = () => {
    setRemoveAccessConfirmationDialogOpen(false);
    if (removeUserFromStudy) {
      dispatch(
        removeStudyAccessRequest({
          userId: removeUserFromStudy.userId,
          studyId: removeUserFromStudy.studyId
        })
      ).then(() => dispatch(userStudyAccessFetch(userId)));
      dispatch(setNewStudyAccess({ studyId: null, roleId: null, studyName: null, roleName: null }));
    }
  };

  const onCancelRemoveStudyAccessConfirmationDialog = () => {
    setRemoveAccessConfirmationDialogOpen(false);
  };

  const removeStudyAccessConfirmTransitionDialog = (
    <ConfirmationDialog
      title="Are you sure you want to remove study access?"
      message={`${
        "resource" in editUser ? editUser.resource.username : ""
      } will no longer have access to ${removeUserFromStudy?.studyName}`}
      isOpen={isRemoveAccessConfirmationDialogOpen}
      onConfirm={removeStudyAccessConfirmationClick}
      onCancel={onCancelRemoveStudyAccessConfirmationDialog}
    />
  );

  return (
    <TableContainer>
      {"resource" in loggedInUser &&
        loggedInUser.resource.can([RolePermissions.UM_GrantStudyAccess]) && (
          <TableFilters>
            <Box display="flex" width="100%">
              <Box style={{ width: "33%" }}>
                <Select
                  disabled={false}
                  onChange={onStudyChange}
                  value={newStudyAccess.studyId || ""}
                >
                  <option key="" value="">
                    Select Study...
                  </option>
                  {"resource" in studiesForUser &&
                    studiesForUser.resource.map(study => {
                      return (
                        <option key={study.id} value={study.id}>
                          {studyLabel(study)}
                        </option>
                      );
                    })}
                </Select>
              </Box>
              <Box style={{ width: "33%", paddingLeft: "20px" }}>
                <Select
                  disabled={false}
                  onChange={onRoleChange}
                  value={newStudyAccess.roleId || ""}
                >
                  <option key="" value="">
                    Select Role...
                  </option>
                  {"resource" in roles &&
                    roles.resource.map(role => {
                      return (
                        <option key={role.id} value={role.id}>
                          {roleLabel(role)}
                        </option>
                      );
                    })}
                </Select>
              </Box>
              <Box style={{ width: "33%", paddingLeft: "20px" }}>
                <Button
                  isLoading={false}
                  intent="primary"
                  appearance="prominent"
                  disabled={!(newStudyAccess.roleId && newStudyAccess.studyId)}
                  onClick={saveStudyAccessClick}
                >
                  + Add
                </Button>
              </Box>
            </Box>
            {addUserToStudyConfirmTransitionDialog}
          </TableFilters>
        )}
      {"resource" in studiesAccess ? (
        <StyledDataTable
          columns={columns}
          data={studiesAccess.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="studyName"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={false}
          customTheme={dataTableTheme}
        />
      ) : null}
      {removeStudyAccessConfirmTransitionDialog}
    </TableContainer>
  );
};
