import React, { Dispatch, SetStateAction } from "react";

interface FollowUpDatePickerProps {
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
}

const FollowUpDatePicker = ({ selectedDate, setSelectedDate }: FollowUpDatePickerProps) => {
  function formatDate(date: Date) {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  const onFollowUpDateChange = (e: React.ChangeEvent<HTMLDataElement>) => {
    const newFollowUpDateParts = e.target.value.split("-");
    const year = parseInt(newFollowUpDateParts[0] ? newFollowUpDateParts[0] : "");
    const month = parseInt(newFollowUpDateParts[1] ? newFollowUpDateParts[1] : "") - 1;
    const day = parseInt(newFollowUpDateParts[2] ? newFollowUpDateParts[2] : "");

    const selectedDate = new Date(year, month, day);
    const today = new Date();

    // Ensure a date is picked in the future
    if (
      selectedDate < today ||
      (selectedDate.getDate() === today.getDate() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getFullYear() === today.getFullYear())
    ) {
      setSelectedDate(today);
    } else {
      setSelectedDate(selectedDate);
    }
  };

  return (
    <>
      <input
        type="date"
        style={{
          borderRadius: "6px",
          borderColor: "#D1D5DB",
          width: "98%",
          paddingLeft: "5px",
          paddingRight: "5px",
          marginBottom: "8px"
        }}
        onChange={onFollowUpDateChange}
        value={formatDate(selectedDate)}
      />
    </>
  );
};

export default FollowUpDatePicker;
