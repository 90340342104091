import React, { useEffect } from "react";
import { Appearance, Box, Button, Callout, Icon, Intent, Text } from "@blasterjs/core";
import { User, UserSummary } from "../models";

import Page, { PageHeader, PageHeading } from "../components/Page";

import DebouncedTextInput from "../components/DebouncedTextInput";
import { StyledDataTable, TableContainer, TableFilters } from "../components/Table";
import Timestamp from "../components/Timestamp";
import TooltipText from "../components/TooltipText";
import { dataTableTheme } from "../theme";
import { useLocalIntStorage } from "../storage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setUsersFilter, userSummariesFetch } from "../slices/users";
import { redirectAction } from "../slices/auth";
import { RolePermissions } from "../permissions";

const Users = () => {
  const dispatch = useAppDispatch();

  const loggedInUser = useAppSelector(state => state.auth.loggedInUser);
  const users = useAppSelector(state => state.users.userSummaries);
  const usersFilter = useAppSelector(state => state.users.usersFilter);

  useEffect(() => {
    dispatch(userSummariesFetch());
  }, [usersFilter]);
  const [userRowsPerPage, setUserRowsPerPage] = useLocalIntStorage("userRowsPerPage", 20);
  const editButtonOnClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const userId = e.currentTarget.value;
    dispatch(redirectAction(`/users/${userId}/settings`));
  };
  const error =
    "errorMessage" in users ? (
      <Box>
        <Callout intent={Intent.DANGER}>
          <Text>{users.errorMessage}</Text>
        </Callout>
      </Box>
    ) : null;
  const columns: ReadonlyArray<object> = [
    {
      name: "Username",
      selector: (user: UserSummary) => user.username,
      sortable: true
    },
    {
      name: "First Name",
      selector: (user: UserSummary) => user.firstName,
      sortable: true
    },
    {
      name: "Last Name",
      selector: (user: UserSummary) => user.lastName,
      sortable: true
    },
    {
      name: "Last Login",
      selector: (user: UserSummary) => user.lastLoginDt,
      sortable: true,
      format: (user: UserSummary) => <Timestamp date={user.lastLoginDt} />
    },
    {
      name: "Organization",
      selector: (user: UserSummary) => user.organizationName,
      sortable: true
    },
    {
      name: "Studies",
      selector: (user: UserSummary) => user.studyCount,
      sortable: true
    },
    {
      name: "Registered",
      selector: (user: UserSummary) => user.registered,
      sortable: true,
      grow: 0,
      cell: (user: User) => (
        <TooltipText text={user.registered ? "Registered" : "Not Registered"}>
          {user.registered ? (
            <Icon name="check" color="green" />
          ) : (
            <Icon name="cross" color="red" />
          )}
        </TooltipText>
      )
    },
    {
      right: true,
      grow: 0,
      cell: (user: User) =>
        "resource" in loggedInUser &&
        loggedInUser.resource.can([RolePermissions.UM_ViewUsersPage]) ? (
          <Button value={user.id} onClick={editButtonOnClick} appearance={Appearance.MINIMAL}>
            <Icon name="edit" />
          </Button>
        ) : (
          <></>
        )
    }
  ];

  const content = error ? (
    error
  ) : (
    <TableContainer>
      <TableFilters>
        <Box display="flex" width="100%">
          <DebouncedTextInput
            key="studies-search"
            width="auto"
            defaultValue={usersFilter || ""}
            placeholder={"Search by name"}
            onValueChange={name => dispatch(setUsersFilter(name))}
          />
        </Box>
      </TableFilters>
      {"resource" in users ? (
        <StyledDataTable
          columns={columns}
          data={users.resource}
          highlightOnHover={false}
          pointerOnHover={false}
          defaultSortField="username"
          sortIcon={<Icon name="caretUp" />}
          className="data-table"
          noHeader={true}
          pagination={true}
          paginationRowsPerPageOptions={[10, 20, 50, 100]}
          paginationPerPage={userRowsPerPage}
          onChangeRowsPerPage={setUserRowsPerPage}
          customTheme={dataTableTheme}
        />
      ) : null}
    </TableContainer>
  );
  return (
    <Page>
      <Box style={{ padding: "0 2rem 4rem" }}>
        <PageHeader>
          <PageHeading>Users</PageHeading>
        </PageHeader>
        {content}
      </Box>
    </Page>
  );
};

export default Users;
