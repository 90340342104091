import { A, Tooltip } from "@blasterjs/core";
import React from "react";
import styled from "styled-components";

const TooltipLinkStyled = styled(A)`
  color: ${props => props.theme.colors.gray700};
  font-size: ${props => props.theme.fontSizes[2]};
  text-decoration: none;
  display: block;
  text-align: left;
  padding: 4px 10px;
  &:hover {
    background-color: ${props => props.theme.colors.gray100};
  }
`;

interface TooltipLinkProps {
  readonly href?: string;
}

export const TooltipLink = ({ href, ...rest }: TooltipLinkProps & AnyProps) => (
  <TooltipLinkStyled href={href || "#"} {...rest} />
);

export const TooltipLinkDanger = styled(TooltipLink)`
  color: ${props => props.theme.colors.red};
`;

interface Props {
  readonly placement?: string;
  readonly children?: React.ReactNode;
}

interface AnyProps {
  readonly [key: string]: any;
}

const TooltipFlyout = ({ placement, children, ...rest }: Props & AnyProps) => (
  <Tooltip bg="white" trigger="click" placement={placement} closeOnClickOutside={true} {...rest}>
    {children}
  </Tooltip>
);

export default TooltipFlyout;
