/* Pure functions to help with e.g. data conversion */
import { Feature, LineString, Point, Polygon as GeoJSONPolygon } from "geojson";
import L from "leaflet";
import { latLng, latLngBounds, LatLngBounds, LatLngLiteral } from "leaflet";
import { difference } from "lodash";
import {
  HasId,
  Indication,
  MultilineCell,
  Polygon,
  UpdateValueWithMaybeReasonForChange,
  UUID,
  ZoomDefinition,
  MultilineCategory,
  MultilineAnnotation,
  ImageWithAnnotations,
  Fragment,
  HpfAnno,
  Annotation,
  HPFAnnotation,
  PointAnno,
  PointAnnotation
} from "./models";

import { isThisYear, isToday } from "date-fns";
import format from "date-fns/format";
import { extname } from "path";
import { AnnotationType } from "./slices/caseImageViewer";
import { AnnotationClassForm } from "./slices/studyConfiguration";
import { RootState } from "./store";

export const LOCALE = "en-CA";

export function pointToGeoJSON(latLng: LatLngLiteral): Feature<Point> {
  return {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [latLng.lat, latLng.lng]
    },
    properties: {}
  };
}

export function lineToGeoJSON(latLngs: ReadonlyArray<LatLngLiteral>): Feature<LineString> {
  return {
    type: "Feature",
    geometry: {
      type: "LineString",
      coordinates: latLngs.map(l => [l.lat, l.lng])
    },
    properties: {}
  };
}

export function polygonToGeoJSON(latLngs: ReadonlyArray<LatLngLiteral>): Feature<GeoJSONPolygon> {
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [latLngs.map(l => [l.lat, l.lng])]
    },
    properties: {}
  };
}

export function toLatLngBounds(poly: Polygon): LatLngBounds | undefined {
  const coords = poly.coordinates[0]?.map(arr => latLng(arr[1], arr[0]));
  return coords && latLngBounds(coords);
}

export function getMaxZoom(zooms: ReadonlyArray<ZoomDefinition>): number {
  return zooms.map(obj => obj.zoom).reduce((x, y) => Math.max(x, y), 0);
}

export function getMinZoom(zooms: ReadonlyArray<ZoomDefinition>): number {
  return zooms.map(obj => obj.zoom).reduce((x, y) => Math.min(x, y), 30);
}

export function formatDate(date: Date | null): string {
  return date instanceof Date
    ? isToday(date)
      ? format(date, "h:mma")
      : isThisYear(date)
      ? format(date, "MMM d")
      : format(date, "MMM d yyyy")
    : "—";
}

export function formateDateToYearMonthDay(date: Date | null): string {
  return date instanceof Date ? format(date, "yyyy-MM-dd") : "-";
}

export function formateDateToYearMonthDayTime(date: Date | null): string {
  return date instanceof Date ? format(date, "yyyy-MM-dd") + "T" + format(date, "HH:mm:ss") : "-";
}

export function getDaysFromDateSelected(date: Date): number {
  const today = new Date();
  const differenceMs = date.getTime() - today.getTime();
  const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return differenceDays;
}

function distanceToMeters(distance: number, pixelSize: number, resolutionInMeters: number): number {
  return (distance * resolutionInMeters) / pixelSize;
}

export function metersToMicrometers(meters: number): number {
  return meters * 10 ** 6;
}

export function toMicrometers(
  distance: number,
  pixelSize: number,
  resolutionInMeters: number
): number {
  const meters = distanceToMeters(distance, pixelSize, resolutionInMeters);
  return metersToMicrometers(meters);
}

export function formatMicrometers(micrometers: number): string {
  return `${Math.floor(micrometers)} μm`;
}

/**
 * Calculate the number of micrometers (μm) corresponding to a given distance on current map.
 */
export function mapDistanceToMicrometers(
  map: L.Map,
  distance: number,
  pixelSize: number,
  resolutionInMeters: number
): number {
  // NOTE: Borrowed logic from built in Leaflet scale control
  // See: https://github.com/Leaflet/Leaflet/blob/37d2fd15ad6518c254fae3e033177e96c48b5012/src/control/Control.Scale.js#L68-L73
  const y = map.getSize().y / 2;
  const width = map.distance(
    map.containerPointToLatLng([0, y]),
    map.containerPointToLatLng([distance, y])
  );
  return metersToMicrometers(distanceToMeters(width, pixelSize, resolutionInMeters));
}

export function getHpfRadiusInMeters(
  pixelSize: number,
  resolutionInMeters: number,
  indications: ReadonlyArray<Indication> | null
): number {
  // Most studies use a standard zoom of 40x which is 0.238mm² known as 1 HPF
  // NASH studies use a 20x zoom
  const hpfFactor =
    indications && indications.includes(Indication.NonalcoholicSteatohepatitis) ? 2 : 1;
  const hpfAreaInSquareMeters = 0.238 * 10 ** -6;
  const hpfRadiusInMeters = Math.sqrt(hpfAreaInSquareMeters / Math.PI); // radius = sqrt(Area / PI)
  return (hpfFactor * (hpfRadiusInMeters * pixelSize)) / resolutionInMeters;
}

/*
 * Test whether sets of ids are different to detect changes in form input.
 */
export function areDifferent<T extends string | number>(
  a: ReadonlyArray<T>,
  b: ReadonlyArray<T>
): boolean {
  return a.length !== b.length
    ? true
    : difference(Array.from(a).sort(), Array.from(b).sort()).length > 0;
}

/*
 * Creates a name for a duplicate image
 */
export function duplicateImageName(imageName: string, suffix: string = "_d"): string {
  return imageName.replace(extname(imageName), `${suffix}${extname(imageName)}`);
}

export function idsOnly(haveId: readonly HasId[]): UUID[] {
  return haveId.map(({ id }) => id);
}

export function updateWithIdsOnly({
  value,
  reasonForChange
}: UpdateValueWithMaybeReasonForChange<readonly HasId[]>): UpdateValueWithMaybeReasonForChange<
  UUID[]
> {
  return {
    value: idsOnly(value),
    ...(reasonForChange ? { reasonForChange } : {})
  };
}

export function getNextUnusedMultilineCell(
  imageWithAnnotations: ImageWithAnnotations,
  exclude?: MultilineCell,
  start?: MultilineCell
): MultilineCell | null {
  const annotations = imageWithAnnotations.annotations;

  const multilineAnnotations = annotations.filter(
    annotation => annotation.annotationType == AnnotationType.Multiline
  ) as MultilineAnnotation[];

  const multilineAnnotationClassesWithCount = imageWithAnnotations.multilineAnnotationClassesWithCount.filter(
    awc => awc.annotationClass.enabled
  );

  if (multilineAnnotationClassesWithCount.length === 0) {
    return null;
  }

  const categories = [MultilineCategory.VillusHeight, MultilineCategory.CryptDepth];

  const hasStart = start && start.class !== null && start.category !== null;

  const startIndexClass = hasStart
    ? multilineAnnotationClassesWithCount.findIndex(
        annotationClass => annotationClass.annotationClass.id === start.class.id
      )
    : 0;

  const startIndexCategory = hasStart
    ? categories.findIndex(category => category === start.category)
    : 0;

  const result = multilineAnnotationClassesWithCount
    .slice(startIndexClass)
    .flatMap(multilineAnnotationClass =>
      categories
        .slice(
          multilineAnnotationClass === multilineAnnotationClassesWithCount[startIndexClass]
            ? startIndexCategory
            : 0
        )
        .map(multilineCategory => ({
          class: multilineAnnotationClass.annotationClass,
          category: multilineCategory
        }))
    )
    .find(
      cell =>
        !(exclude && exclude.class === cell.class && exclude.category === cell.category) &&
        !multilineAnnotations.some(
          annotation =>
            annotation.annotationClassId === cell.class.id &&
            annotation.multilineCategory === cell.category
        )
    );

  return result || null;
}

const ORPHAN = "Orphan";

function getOrphanPoints(
  pcls: readonly AnnotationClassForm<"POINT">[],
  annotations: ReadonlyArray<Annotation>
): ReadonlyArray<PointAnno> {
  const myPoints = annotations.filter(
    a => a.annotationType === "POINT" && (a as PointAnnotation).parent === null
  );
  const myPointsNoClass = annotations.filter(
    a =>
      a.annotationType === "POINT" &&
      (a as PointAnnotation).parent === null &&
      !(a as PointAnnotation).color
  );

  const opts = pcls
    .map(cls => {
      const pts = myPoints.filter(p => (p as PointAnnotation).color == cls.color);
      return {
        id: cls.id || "no-id",
        name: cls.name,
        color: cls.color,
        count: pts.length,
        pointIds: pts.map(a => a.id)
      };
    })
    .filter(panno => panno.count > 0);
  return myPointsNoClass.length === 0
    ? opts
    : [
        {
          id: "no-p-cl-id",
          name: "Default",
          color: "#00FF00",
          count: myPointsNoClass.length,
          pointIds: myPointsNoClass.map(a => a.id)
        },
        ...opts
      ];
}

function getDefaultPoints(
  hpf: HPFAnnotation,
  annotations: ReadonlyArray<Annotation>
): ReadonlyArray<PointAnno> {
  const myPoints = annotations.filter(
    a =>
      a.annotationType === "POINT" &&
      (a as PointAnnotation).parent === hpf.id &&
      !(a as PointAnnotation).color
  );
  if (myPoints.length > 0) {
    return [
      {
        id: "no-id",
        name: "Default",
        color: "#FFFF00",
        count: myPoints.length,
        pointIds: myPoints.map(a => a.id)
      }
    ];
  } else {
    return [];
  }
}

function getPoints(
  hpf: HPFAnnotation,
  pcls: readonly AnnotationClassForm<"POINT">[],
  annotations: ReadonlyArray<Annotation>
): ReadonlyArray<PointAnno> {
  const myPoints = annotations.filter(
    a => a.annotationType === "POINT" && (a as PointAnnotation).parent === hpf.id
  );

  return pcls
    .map(cls => {
      const pts = myPoints.filter(p => (p as PointAnnotation).color == cls.color);
      return {
        id: cls.id || "no-id",
        name: cls.name,
        color: cls.color,
        count: pts.length,
        pointIds: pts.map(a => a.id)
      };
    })
    .filter(panno => panno.count > 0);
}

function getHpfsClass(
  cls: AnnotationClassForm<"HPF">,
  pcls: readonly AnnotationClassForm<"POINT">[],
  annotations: ReadonlyArray<Annotation>
): ReadonlyArray<HpfAnno> {
  return annotations
    .filter(a => a.annotationType === "HPF" && (a as HPFAnnotation).color === cls.color)
    .flatMap(hpf => {
      const pts = getPoints(hpf as HPFAnnotation, pcls, annotations);
      const defaultPts = getDefaultPoints(hpf as HPFAnnotation, annotations);
      if (defaultPts.length === 0) {
        return [
          {
            id: hpf.id || "no-id",
            name: "",
            count: pts.reduce((s, c) => s + c.count, 0),
            color: cls.color,
            isOpen: false,
            points: pts
          }
        ];
      } else {
        const combinedPoints = [...defaultPts, ...pts];
        return [
          {
            id: hpf.id || "no-id",
            name: "",
            count: combinedPoints.reduce((s, c) => s + c.count, 0),
            color: cls.color,
            isOpen: false,
            points: combinedPoints
          }
        ];
      }
    });
}

function getDefaultHpfs(
  pcls: readonly AnnotationClassForm<"POINT">[],
  annotations: ReadonlyArray<Annotation>
): ReadonlyArray<HpfAnno> {
  return annotations
    .filter(a => a.annotationType === "HPF" && !(a as HPFAnnotation).color)
    .map(hpf => {
      const pts = getPoints(hpf as HPFAnnotation, pcls, annotations);
      const defaultPoints = getDefaultPoints(hpf as HPFAnnotation, annotations);
      const allPts = [...pts, ...defaultPoints];
      return {
        id: hpf.id || "no-id",
        name: "",
        count: allPts.reduce((s, c) => s + c.count, 0),
        color: "#00FF00",
        isOpen: false,
        points: allPts
      };
    });
}

export function mapStateToFrags(
  annotations: ReadonlyArray<Annotation>,
  fcls: readonly AnnotationClassForm<"HPF">[],
  pcls: readonly AnnotationClassForm<"POINT">[]
): ReadonlyArray<Fragment> {
  const defaultHpfs = getDefaultHpfs(pcls, annotations);
  const pts = getOrphanPoints(pcls, annotations);
  const frags =
    fcls && fcls.length > 0
      ? fcls
          .map((fc, _k) => {
            const hpfs = getHpfsClass(fc, pcls, annotations);
            return {
              id: fc.id,
              name: fc.name,
              count: hpfs.reduce((s, c) => s + c.count, 0),
              color: fc.color,
              isOpen: true,
              hpfs: hpfs
            };
          })
          .filter(f => f.hpfs.length > 0)
      : [];
  if (defaultHpfs.length === 0) {
    if (pts.length === 0) {
      return frags;
    } else {
      return [
        {
          id: "no-id",
          name: "Other",
          count: 0,
          color: "black",
          isOpen: true,
          hpfs: [
            {
              id: "no-id2",
              name: ORPHAN,
              count: pts.reduce((s, c) => s + c.count, 0),
              color: "black",
              isOpen: false,
              points: pts
            }
          ]
        },
        ...frags
      ];
    }
  } else {
    if (pts.length === 0) {
      return [
        {
          id: "no-id",
          name: "Other",
          count: 0,
          color: "black",
          isOpen: true,
          hpfs: defaultHpfs
        },
        ...frags
      ];
    } else {
      return [
        {
          id: "no-id",
          name: "Other",
          count: 0,
          color: "black",
          isOpen: true,
          hpfs: [
            {
              id: "no-id2",
              name: ORPHAN,
              count: pts.reduce((s, c) => s + c.count, 0),
              color: "black",
              isOpen: false,
              points: pts
            },
            ...defaultHpfs
          ]
        },
        ...frags
      ];
    }
  }
}

export function mapStateToFragments(state: RootState): ReadonlyArray<Fragment> {
  const fragClasses = state.studyConfiguration.study.data.hpfAnnotationClasses.value;
  const pointClasses = state.studyConfiguration.study.data.pointAnnotationClasses.value;
  return state.caseImageViewer.imageWithAnnotations &&
    "resource" in state.caseImageViewer.imageWithAnnotations
    ? mapStateToFrags(
        state.caseImageViewer.imageWithAnnotations.resource.annotations,
        fragClasses,
        pointClasses
      )
    : [];
}

export function convertCoordinatesToLatLngLiteral(coordinates: number[]): LatLngLiteral {
  return {
    lat: coordinates[0] || 0,
    lng: coordinates[1] || 0
  };
}
