import React, { useEffect, useState } from "react";
import { formatMicrometers, mapDistanceToMicrometers } from "../utils";

import { Box } from "@blasterjs/core";
import L from "leaflet";
import styled from "styled-components";

const ScaleContainer = styled(Box)`
  margin-top: auto;
  position: relative;
  background: transparent;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
`;

const SCALE_BAR_WIDTH_IN_PX = 100;

const Bar = styled.span`
  position: relative;
  background: rgba(255, 255, 255, 0.7);
  height: 2rem;
  border: 2px solid #000;
  border-top-width: 0;
  width: ${SCALE_BAR_WIDTH_IN_PX}px;
`;

const Length = styled.span`
  position: absolute;
  bottom: 2px;
  left: 5px;
  font-size: 12px;
`;

interface Props {
  readonly pixelSize: number;
  readonly resolutionInMeters: number;
  readonly leafletMapElement: L.Map;
}

const ScaleBar = ({ leafletMapElement, pixelSize, resolutionInMeters }: Props) => {
  useEffect(() => {
    const initializeScaleBar = (map: L.Map) => {
      const setUm = () =>
        setMicrometers(
          mapDistanceToMicrometers(map, SCALE_BAR_WIDTH_IN_PX, pixelSize, resolutionInMeters)
        );
      map && map.on("moveend", setUm);
    };
    leafletMapElement && initializeScaleBar(leafletMapElement);
  }, [pixelSize, resolutionInMeters]);
  const [micrometers, setMicrometers] = useState<number>(0);
  return micrometers ? (
    <ScaleContainer>
      <Bar />
      <Length>{formatMicrometers(micrometers)}</Length>
    </ScaleContainer>
  ) : null;
};

export default ScaleBar;
